





















import Vue from 'vue';

import api from '@/services/api';

import { ReportConfig } from '@/types/api';
import { HttpMetadataDefaults } from '@/types/http';

import CreateEditReportConfig from './components/CreateEditReportConfig.vue';
import FetchableData from '@/app/components/ui/FetchableData.vue';

export default Vue.extend({
  name: 'ReportConfigEdit',
  components: {
    CreateEditReportConfig,
    FetchableData,
  },
  props: {
    reportConfigId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    reportConfig: {
      data: null as ReportConfig | null,
      meta: { ...HttpMetadataDefaults },
    },
  }),
  async created() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      this.reportConfig.meta = {
        ...this.reportConfig.meta,
        isLoading: true,
        error: null,
      };
      try {
        const apiRes = await api.reportConfig.getById(this.reportConfigId);
        this.reportConfig.data = apiRes.data;
        this.reportConfig.meta.lastFetch = new Date();
      } catch (err: any) {
        this.reportConfig.meta.error = err.message || this.$t('global.errors.generic');
      }
      this.reportConfig.meta.isLoading  = false;
    },
    onSaved(newReportConfig: ReportConfig) {
      this.$router.push({
        name: 'reportConfigView',
        params: {
          id: `${newReportConfig.id}`,
        }
      });
    },
    onError() {
      // TODO error toast ?
    },
  }
});
