import store from '@/services/store';

import Node from 'models/topology/node';
import Floor from 'models/topology/floor';
import Energy from 'models/topology/energy';
import Device from 'models/device';

const energyNodeTypes = ['water', 'alarm', 'electricity', 'states', 'air', 'qrad'];

export default class Building extends Node {
  address = {};
  metadata = {};
  constructor(rawData = {}, parent = null, options = null) {
    super(rawData, parent);
    this.address = rawData.address;
    this.metadata = rawData.metadata;
    this.users = rawData.users;
    if (rawData.next)
      this.next = rawData.next.reduce((acc, f) => {
        if (f.nodeType === 'floor' && options && options.showFloorWithNoSVG) acc.push(new Floor(f, this));
        else if (f.nodeType === 'floor' && f.metadata && (f.metadata.svg || f.metadata.svgId)) acc.push(new Floor(f, this));
        else if (energyNodeTypes.includes(f.nodeType)) acc.push(new Energy(f, this));
        return acc;
      }, []);
    if (rawData.devices) this.devices = rawData.devices.map(d => new Device(d));
  }

  static getSpaces(uuid) {
    const buildings = store.getters['building/collection'];
    const currentBuilding = buildings.find(b => b.uuid === uuid);
    if (!currentBuilding) return;
    return currentBuilding.next.reduce((acc, f) => {
      f.next.map(s => acc.push(s));
      return acc;
    }, []);
  }
}
