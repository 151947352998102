import Node from 'models/topology/node';
import Plug from 'models/topology/plug';

export default class Energy extends Node {
  type;
  metadata = {};
  constructor(rawData = {}, parent = null) {
    super(rawData, parent);
    this.description = rawData.description;
    this.type = rawData.type;
    if (rawData.next) {
      this.next = rawData.next.map(node => {
        if (node.nodeType === 'plug') return new Plug(node, this);
        else return new Energy(node, this);
      });
    }
  }
}
