import Vue from 'vue';
import api from 'api';
const getDefaultState = () => ({
  collection: [],
  fetching: false,
  selectedProperty: null,
  colors: {
    access: { private: '#414B40', public: '#407B66' },
    type: {
      bedroom: '#546de5',
      bathroom: '#847C5F',
      kitchen: '#CC3A54',
      office: '#E3D471',
      'living room': '#E3874C',
      wc: '#F68161',
      corridor: '#E5554B',
      terrace: '#E2B565',
      balcony: '#BD3B6A',
      cellar: '#9D377A',
      PMI: '#574b90',
      hall: '#6DCB8A',
      reception: '#e66767',
      'waiting room': '#3dc1d3',
      'meeting room': '#f19066',
    },
  },
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    selectionPropertiesSpaceList: (state, getters, rootState) => property => {
      if (!rootState.building.collection.length === 0) return [];
      const type = Object.keys(property)[0];
      const prop = property[type];
      const spaces = [];
      // later the count will be made with selected buildings only
      rootState.building.collection.forEach(b => {
        b.next.forEach(f => {
          f.next.forEach(s => {
            if ('properties' in s && s.properties[type] && s.properties[type] === prop) {
              spaces.push(s);
            }
          });
        });
      });
      return spaces;
    },
    spaceListByProperty: (state, getters, rootState) => property => {
      if (!rootState.building.selectedBuilding) return [];
      const type = Object.keys(property)[0];
      const prop = property[type];
      const spaces = [];
      rootState.building.selectedBuilding.next.forEach(f => {
        f.next.forEach(s => {
          if ('properties' in s && s.properties[type] && s.properties[type] === prop) {
            spaces.push(s);
          }
        });
      });
      return spaces;
    },
    spaceListByCurrentPropertyType: (state, getters, rootState) => {
      if (!rootState.building.selectedBuilding || !state.selectedProperty) return [];
      const type = Object.keys(state.selectedProperty)[0];
      const spaces = [];
      rootState.building.selectedBuilding.next.forEach(f => {
        f.next.forEach(s => {
          if ('properties' in s && s.properties[type]) {
            spaces.push(s);
          }
        });
      });
      return spaces;
    },
  },
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    setCollection: (state, properties) => Vue.set(state, 'collection', properties),
    setFetchingState: (state, fetchState) => (state.fetching = fetchState),
    selectProperty: (state, property) => (state.selectedProperty = property),
    update: (state, { space, property }) => {
      const type = Object.keys(property)[0];
      const prop = property[type];
      if (space.properties[type] && space.properties[type] === prop) Vue.set(space.properties, type, '');
      else Vue.set(space.properties, type, prop);
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    fetch: async ({ commit }) => {
      try {
        commit('setFetchingState', true);
        const { data: properties } = await api.node.global.fetchProperties();
        commit('setCollection', properties);
      } catch (error) {
        throw error;
      }
      commit('setFetchingState', false);
    },
    update: async ({ commit }, { space, property }) => {
      try {
        commit('update', { space, property }); //update space properties before sending
        await api.node.update(space.uuid, { properties: space.properties });
        // if request ok, do nothing
      } catch (error) {
        commit('update', { space, property }); // if request error undo space properties update
        throw error;
      }
    },
  },
};
