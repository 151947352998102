import _ from 'lodash';
import baseObject from './nodesGroup-locale-object.json';
const nodesGroupFR = _.cloneDeep(baseObject);

// buttons
nodesGroupFR.buttons.createNewNodesGroup = 'Créer un nouveau groupe de données';
nodesGroupFR.buttons.return = 'retour';
nodesGroupFR.buttons.save = 'sauvegarder';

// toasts
nodesGroupFR.toasts.saved = 'Groupe de noeuds sauvegardé';

// errors
nodesGroupFR.errors.noGroups = 'Aucun groupe de données';
nodesGroupFR.errors.unknowError = 'Groupe de données inconnu';

// labels
nodesGroupFR.labels.name = 'Nom';
nodesGroupFR.labels.description = 'Description';
nodesGroupFR.labels.nodes = 'Liste de noeuds';
nodesGroupFR.labels.profile = 'profile de données';

export default nodesGroupFR;