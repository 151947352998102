<template>
<q-page-container class="details">
<div class="content">
  <div class="title item"> {{ alert.alertRule.title }} </div>
  <div class="item">
    <div class="label">
        Règle de l'alerte :
      </div>
      <div class="value">
        {{ alert.alertRule.description || '-' }}
      </div>
    </div>
    <div class="item">
      <div class="label">
        Message:
      </div>
      <div class="value">
        {{ message }}
      </div>
    </div>
    <div class="item">
      <div class="label">
        Statut:
      </div>
      <div class="value">
        {{ $t('pages.alert.active') }} {{ $t('components.duration.since') }} {{ activeSince }}
      </div>
      <div v-if="resolvedSince"
          class="value">
        {{ $t('pages.alert.resolved') }} {{ $t('components.duration.since') }} {{ resolvedSince }}
      </div>
    </div>
    <div class="item">
      <div class="label">
        Localisation:
      </div>
      <div class="value">
        {{ location || 'n/a' }}
      </div>
    </div>
    <div class="item">
      <div class="label">
        Urgence:
      </div>
      <div class="value">
        <q-chip
          :class="alert.urgence">
          {{$t(`pages.alert.priorities.${alert.urgence}`)}}
        </q-chip>
      </div>
    </div>
    <div class="item">
        <div class="label">
          {{ $t('pages.alert.layout.history.title') }}
        </div>
        <template v-if="isFirstActivation">
          <div class="value">
            {{ $t('pages.alert.layout.history.firstActivation') }}
          </div>
        </template>
        <template v-else>
          <div class="value">
              {{ $t('pages.alert.layout.history.nbActiveSince', { triggerCount: historyNbTriggersSinceTreshold, since: historyTresholdForPreviousActivationsSinceString }) }}
          </div>
          <div class="value">
              {{ $t('pages.alert.layout.history.lastActive') }}: {{ lastActivationDateString }}
          </div>
        </template>
    </div>
    <div v-if="user.featuresRights.alertRules.READ"
          class="item">
      <router-link class="go-alert-rule o-button o-button--squared o-button--hover o-button--shadows o-button--primary-gradient"
              :to="{name: 'alertRuleDetails',params:{alertRuleId: alert.alertRule.id}}"
              exact>
        {{$t('pages.alert.layout.alertRule')}}
      </router-link>
    </div>
    <div  v-if="user.featuresRights.alertRules.WRITE && deviceIgnorable"
          class="item">
      <button class="ignore-device o-button o-button--squared o-button--hover o-button--shadows o-button--primary-gradient"
              @click="ignoreDevice">
        {{$t('pages.alert.layout.ignoreDevice')}}
      </button>
    </div>
  </div>
</q-page-container>
</template>

<script>
import moment from 'moment';
import api from 'api';

export default {
  name: 'AlertCenterItem',
  props: {
    alert: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      // the value of moment(), updated every second by a timer
      momentNow: moment(),
      // timerId for momentNow setInterval update, needed to stop it when destroying the component
      momentNowUpdateTimerId: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    // human readable form of how long this alert has been on
    activeSince() {
      return `${moment(this.alert.createdAt).from(this.momentNow, true)}`;
    },
    // human readable form of how long this alert has been resolved
    resolvedSince() {
      if (this.alert.dateResolved) {
        return `${moment(this.alert.dateResolved).from(this.momentNow, true)}`;
      }
      return null;
    },
    deviceIgnorable() {
      const deviceUuid = this.alert.details.deviceUuid;
      if (!deviceUuid) {
        return false;
      }
      const ignoredDevices = this.alert.alertRule.config.ignoredDevices;
      if (!ignoredDevices) return true;
      if (ignoredDevices.includes(deviceUuid)) return false;
      return true;
    },
    // array of the alerts of the same kind as this one, sorted from newest to oldest
    filteredAlerts() {
      const filteredAlert = this.$store.state.alert.collection
        .filter(fa => fa.alertRuleId === this.alert.alertRuleId && fa.message === this.alert.message)
        .sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));
      return filteredAlert;
    },
    // Is it the first alert of this kind ?
    isFirstActivation() {
      return this.filteredAlerts.length < 2;
    },
    // string of the date of the previous activation
    lastActivationDateString() {
      if (this.filteredAlerts.length === 0) {
        return this.$('pages.alert.history.never');
      }
      return `${moment(this.filteredAlerts[0].createdAt).calendar()}`;
    },
    // A period in which the last previous activation occured, between 1 week, 1 month, 3 months, 1 year, or more.
    // This is used to provide a human-understandable information instead of random period like "4 activations in the last 47 days"
    historyTresholdForPreviousActivations() {
      if (this.filteredAlerts.length < 2) {
        return null;
      }
      const previousActivationDate = new Date(this.filteredAlerts[1].createdAt);
      for (const [ nbUnits, unitOfTime ] of [ [1, 'day'], [7, 'days'], [1, 'month'], [3, 'months'], [1, 'year']]) {
        const treshold = this.momentNow.subtract(nbUnits, unitOfTime);
        if (previousActivationDate > treshold) {
          return treshold;
        }
      }
      // Fallback if alert has been activated more than 1 year ago
      return moment(previousActivationDate);
    },
    // A string representing the treshold for previous activations
    historyTresholdForPreviousActivationsSinceString() {
      if (!this.historyTresholdForPreviousActivations) {
        return null;
      }
      return this.historyTresholdForPreviousActivations.fromNow(true);
    },
    // The number of times this alert has been created since the treshold chosen for previous activations, including the current one
    historyNbTriggersSinceTreshold() {
      if (!this.historyTresholdForPreviousActivations) {
        return null;
      }
      return this.filteredAlerts.filter(a => new Date(a.createdAt) >= this.historyTresholdForPreviousActivations).length;
    },
    message() {
      if (this.alert.alertRule.type === 2 && this.alert.deviceUuid && this.alert.profile?.name) { // hardcoded type deviceInactive
        return `Device '${this.alert.deviceUuid}' inactive for profile '${this.alert.profile?.name}'`;
      }
      return this.alert.message;
    },
    location() {
      return `${this.alert.buildingName} > ${this.alert.floorName} > ${this.alert.roomName}`;
    },
  },
  created() {
    // momentNow has to be updated every second so that activeSince is updated as well
    this.momentNowUpdateTimerId = setInterval(() => {
      this.momentNow = moment();
    }, 1000);
  },
  destroyed() {
    if (this.momentNowUpdateTimerId !== null) {
      clearInterval(this.momentNowUpdateTimerId);
      this.momentNowUpdateTimerId = null;
    }
  },
  methods: {
    async ignoreDevice() {
      try {
        if (!this.alert.details.deviceUuid)
          throw new Error('no device to ignore');
        await api.alert.ignoreDevice(this.alert.id);
        if (!this.alert.dateResolved) {
          this.alert.dateResolved = moment.utc();
        }
        if (!this.alert.alertRule.config) {
          this.alert.alertRule.config = { ignoredDevices: [] };
        } else if (!this.alert.alertRule.config.ignoredDevices) {
          this.alert.alertRule.config.ignoredDevices = [];
        }
        this.alert.alertRule.config.ignoredDevices.push(this.alert.details.deviceUuid);
      } catch (e) {
        this.$q.notify({
          message: e.message || this.$t('pages.alert.errors.failedToIgnoreDevice'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~variables'


.details
  display flex
  justify-content center
  .content
    text-align left
    display flex
    background-color $light
    flex-direction column
    gap 1px
    .item
      background-color white
      min-width 200px
      text-align left
      justify-content left
      padding 15px
      .label
        color #acadaf
        font-size 14px
        margin-bottom 10px
      .high
        background-color #FF3D00
        color white
      .normal
        background-color #FFB800
        color white
      .low
        background-color #8DCC26
        color white
    .title
      border-top none
      text-align center
      font-size $fs-h1
  .history
    text-align center
    padding 50px
    .history-button
      color white
      background #074EE8
      position static
      left 12px
      top 7px
    .trigger
      padding 40px

@media (max-width: 1000px)
  .content
    display flex
    flex-direction row
    flex-wrap wrap


</style>