import Widget from './widget';
export default class DashboardWidget {
  id;
  x;
  y;
  w;
  h;
  i;
  config;
  widget;
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.x = rawData.x;
    this.y = rawData.y;
    this.w = rawData.w;
    this.h = rawData.h;
    this.config = rawData.config;
    this.i = rawData.i;
    this.widget = new Widget(rawData.widget);
  }
}
