<template>
  <q-page>
    <div class="actions-container">
      <div
        class="pointer"
        @click="$router.back()"
      >
        <q-icon name="fas fa-arrow-left notification-icon"
                size="15px"/>
        <span>{{$t('pages.alertRule.buttons.return') }}</span>
      </div>
    </div>

    <div class="content-container">
      <div v-if="alertRuleMeta.isLoading"
          class="message">
          LOADING...
      </div>
      <div v-else-if="alertRuleMeta.error"
          class="message">
        {{ alertRuleMeta.error }}
      </div>
      <div v-else
          class="flex-container">
        <div class="alert-rule-main flex-item">
          <AlertRuleDetailsEditor :alertRuleId="alertRuleId"
                                  @saved="saved" />
        </div>
        <div v-if="alertRuleId && alertsRights.READ"
             class='alerts flex-item'>
            <AlertsOfRule :alertRuleId="alertRuleId"/>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import AlertRuleDetailsEditor from '@/app/pages/alert-rule/components/alert-rule-details-editor.vue';
import AlertsOfRule from '@/app/pages/alert-rule/components/alerts-of-rule.vue';

export default {
  name: 'AlertRuleDetailLayout',
  components: { AlertRuleDetailsEditor, AlertsOfRule },
  props: {
    alertRuleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      alertRuleMeta: {
        isLoading: false,
        error: null,
      },
    };
  },
  computed: {
    alertsRights() {
      return this.$store.state.user.user.featuresRights.alerts;
    },
  },
  methods: {
    saved () {
      // show toast
      this.$q.notify({
        message: this.$t('pages.alertRule.toasts.saved'),
        type: 'positive',
        position: 'bottom-left',
      });
      // redirect to alertRuleCenter
      this.$router.push({ name: 'AlertRulesCenter' });
    }
  }
};

</script>

<style lang="stylus" scoped>
@import '~variables'

.flex-container
  display flex
  flex-direction row
  gap 15px;
  @media (max-width: 1000px)
    flex-direction column

.flex-item
  margin 5px auto 20px auto
  align-items center
  justify-content center

.alert-rule-main
  min-width 400px
  max-width 800px
  margin-bottom 50px

.alerts
  padding 20px
  min-width 450px
  max-width 800px
  max-height 1200px
  border-radius $border-radius-smooth
  background-color white
  @media (max-width: 500px)
    min-width 400px
    max-height 800px

.actions-container
  display flex
  text-transform uppercase
  cursor pointer
  margin-bottom 5px
  .pointer>*
    margin-right 3px
</style>