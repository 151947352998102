import axios from 'axios';
import config from '@/../config/config';

const axiosNoRedirect = axios.create({
  baseURL: config.api.baseURL,
  withCredentials: true,
});

const user = {
  endpoint: '/sso',
  login({ email, password }) {
    return axiosNoRedirect.post(`${this.endpoint}/login`, { email, password });
  },
  isLogged() {
    return axiosNoRedirect.get(`${this.endpoint}/login`);
  },
  logout() {
    return axiosNoRedirect.post(`${this.endpoint}/logout`);
  },
};

export default user;
