import securedAxios from '../securedAxios';

const Dashboard = {
  endpoint: '/api/dashboard',
  fetch() {
    return securedAxios.get(`${this.endpoint}`);
  },
  create({ name }) {
    return securedAxios.post(`${this.endpoint}`, { name });
  },
  update(dashboardId, { name, dashboardWidgets }) {
    return securedAxios.patch(`${this.endpoint}/${dashboardId}`, { name, dashboardWidgets });
  },
  delete(dashboardId) {
    return securedAxios.delete(`${this.endpoint}/${dashboardId}`);
  },
};

export default Dashboard;
