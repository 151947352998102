import _ from 'lodash';
import components from './components-locale-object.json';
const componentsEN = _.cloneDeep(components);
// Commons
componentsEN.commons.noData = 'no data';
// TimeControle
componentsEN.timeControle.year = 'year';
componentsEN.timeControle.month = 'month';
componentsEN.timeControle.week = 'week';
componentsEN.timeControle.day = 'day';
componentsEN.timeControle.live = 'live';
// TimePicker
componentsEN.timePicker.hours = 'hours';
componentsEN.timePicker.minutes = 'minutes';
// BuildingSelection
componentsEN.buildingSelection.filterPlaceholder = 'filter by {item}';
// FloorSelection
componentsEN.floorSelection.floors = 'floors';
componentsEN.floorSelection.all = 'ALL';
// FilterSelection
componentsEN.filterSelection.add = 'ADD';
componentsEN.filterSelection.noFilters = 'No filters';
componentsEN.propertiesSelection.select = 'Select';
componentsEN.propertiesSelection.subText = 'properties to filter your selection';
componentsEN.propertiesSelection.type = 'type';
componentsEN.propertiesSelection.access = 'access';
// ZoneSelection
componentsEN.zoneSelection.searchPlaceholder = 'search by name or floor';
// SelectionInfo
componentsEN.selectionInfo.buildings = 'building | building | buildings';
componentsEN.selectionInfo.floors = 'floor | floor | floor';
componentsEN.selectionInfo.spaces = 'space | space | spaces';
componentsEN.selectionInfo.sensors = 'sensor | sensor | sensors';
// Timeline
componentsEN.timeline.barText.selectBuilding = 'select a building';
componentsEN.timeline.barText.noDevice = 'no device in selection';
componentsEN.timeline.barText.noData = 'no data';
// Shortcuts
componentsEN.shortcuts.name = 'shortcuts';
componentsEN.shortcuts.commons.or = 'or';
componentsEN.shortcuts.keys.ctrl = 'ctrl';
componentsEN.shortcuts.keys.wheel = 'wheel';
componentsEN.shortcuts.keys.arrows = 'arrows';
componentsEN.shortcuts.keys.mouseRight = 'right';
componentsEN.shortcuts.keys.mouseLeft = 'left';
componentsEN.shortcuts.keys.pageUp = 'page up';
componentsEN.shortcuts.keys.pageDown = 'page down';
componentsEN.shortcuts.navigation.name = 'navigation';
componentsEN.shortcuts.navigation.zoomCurrentFloor = 'zoom on the current level';
componentsEN.shortcuts.navigation.moveCurrentFloor = 'move on the current level';
componentsEN.shortcuts.navigation.nextFloorUpDown = 'next floor up/down';
componentsEN.shortcuts.selection.name = 'selection';
componentsEN.shortcuts.selection.selectSpace = 'select a space';
componentsEN.shortcuts.selection.selectMultiSpace = 'select multiple spaces';
componentsEN.shortcuts.selection.spaceDetails = 'space details';
componentsEN.shortcuts.edition.name = 'edition';
componentsEN.shortcuts.edition.addDeviceInSpace = 'add device in current space';
componentsEN.shortcuts.edition.leaveEditionMode = 'leave edition mode';
componentsEN.shortcuts.edition.inSpace = 'inside space';
componentsEN.shortcuts.edition.outSpace = 'outside space';
// SpaceInfo
componentsEN.spaceInfo.editDevice = 'edit devices';
componentsEN.spaceInfo.presence.occupied = 'occupied';
componentsEN.spaceInfo.presence.free = 'free';
// DeviceInfo
componentsEN.deviceInfo.editDevice = 'edit device';
componentsEN.deviceInfo.save = 'save';
componentsEN.deviceInfo.move = 'move';
componentsEN.deviceInfo.labels.deviceUuid = 'device uuid';
componentsEN.deviceInfo.labels.type = 'type';
componentsEN.deviceInfo.labels.description = 'description';
// ColorPicker
componentsEN.colorPicker.selectColor = 'select a color';
// AccountMenu
componentsEN.accountMenu.addBuilding = 'Add new building';
componentsEN.accountMenu.store = 'store';
componentsEN.accountMenu.signOut = 'sign out';
// ColorRangeHelper
componentsEN.colorRangeHelper.max = 'Max';
componentsEN.colorRangeHelper.noData = 'No data';
componentsEN.colorRangeHelper.good = 'Good';
componentsEN.colorRangeHelper.bad = 'Bad';
componentsEN.colorRangeHelper.oapps.presence.occupied = 'occupied';
componentsEN.colorRangeHelper.oapps.presence.free = 'free';
componentsEN.colorRangeHelper.oapps.presence.mid = 'partially occupied';
// PeriodSelector
componentsEN.periodSelector.labels['1D'] = '1D';
componentsEN.periodSelector.labels['1W'] = '1W';
componentsEN.periodSelector.labels['1M'] = '1M';
componentsEN.periodSelector.labels['1Y'] = '1Y';
componentsEN.periodSelector.longNames['last 24 hours'] = 'last 24 hours';
componentsEN.periodSelector.longNames['last week'] = 'last week';
componentsEN.periodSelector.longNames['last month'] = 'last month';
componentsEN.periodSelector.longNames['last year'] = 'last year';
// Duration
componentsEN.duration.since = 'since';
componentsEN.duration.second = 'second';
componentsEN.duration.minute = 'minute';
componentsEN.duration.hour = 'hour';
componentsEN.duration.day = 'day';
componentsEN.duration.now = 'Just now';
componentsEN.duration.today = 'Today';
componentsEN.duration.week = 'This week';
componentsEN.duration.previous = 'Previous Notifications';
export default componentsEN;
