export default class Device {
  uuid;
  name;
  space;
  plug;
  x;
  y;
  constructor(rawData = {}) {
    this.uuid = rawData.device;
    this.name = rawData.name;
    this.space = rawData.idIFC;
    this.plug = rawData.plug;
    this.x = rawData.x;
    this.y = rawData.y;
    this.deviceType = rawData.deviceType;
    this.nodeType = rawData.nodeType;
  }
}
