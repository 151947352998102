<template>
  <div>
    <Field
            :hint="hint"
            required>
      <treeselect v-model="selectedItems"
                  :multiple="true"
                  :options="availableItems"
                  openDirection="top"
                  class="selector"
                  @input="updated" />
    </Field>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Field from '@/app/components/inputs/field.vue';

export default {
  name: 'TreeSelector',
  components: {
    Treeselect,
    Field,
  },
  props: {
    initialSelection: {
      type: Array,
      default: () => ([]),
    },
    availableItems: {
      type: Array,
      default: () => ([]),
    },
    hint: {
      type: String,
      default: () => (''),
    }
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  created() {
    this.selectedItems = this.initialSelection.map(item => item.id);
    this.$emit('input', this.selectedItems);
  },
  methods: {
    updated() {
      this.$emit('input', this.selectedItems);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.selector
  font-size $fs-h2
  & >>> .vue-treeselect__control
    border-color $black

</style>