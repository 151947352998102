const getDefaultState = () => ({
  devMode: process.env.NODE_ENV === 'development',
});

const state = getDefaultState();

const mutations = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  SET_DEV_MODE(state, { devMode }) {
    state.devMode = devMode;
  },
};
const actions = {
  resetState({ commit }) {
    commit('resetState');
  }
};


export default {
  namespaced: true,
  state,
  mutations,
  actions
};
