import api from 'api';
import Vue from 'vue';

const getDefaultState = () => ({
  collection: [],
  types: [],
  status: [],
  priorities: [],
  currentTicket: undefined,
  fetching: false,
});

const state = getDefaultState();

const mutations = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  setFetchState: (state, fetchState) => (state.fetching = fetchState),
  setCollection(state, tickets) {
    Vue.set(state, 'collection', tickets);
  },
  setTypes(state, types) {
    Vue.set(state, 'types', types);
  },
  setStatus(state, status) {
    Vue.set(state, 'status', status);
  },
  setPriorities(state, priorities) {
    Vue.set(state, 'priorities', priorities);
  },
  setCurrentTicket(state, ticketShortId) {
    state.currentTicket = state.collection.find(ticket => ticket.shortId === ticketShortId);
  },
  create(state, ticket) {
    state.collection.push(ticket);
  },
  delete(state, ticketId) {
    const ticketIndex = state.collection.findIndex(t => t.shortId === ticketId);
    if (ticketIndex < 0) return;
    Vue.delete(state.collection, ticketIndex);
  },
  update(state, { ticketId, updatedData }) {
    const ticket = state.collection.find(t => t.shortId === ticketId);
    if (!ticket) return;
    Object.keys(updatedData).forEach(k => {
      Vue.set(ticket, k, updatedData[k]);
    });
  },
};

const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  async fetch({ commit }) {
    commit('setFetchState', true);
    commit('setCollection', (await api.tickets.private.fetch()).data);
    commit('setTypes', (await api.tickets.private.global.getTypes()).data);
    commit('setStatus', (await api.tickets.private.global.getStatus()).data);
    commit('setPriorities', (await api.tickets.private.global.getPriorities()).data);
    commit('setFetchState', false);
  },
  async fetchPublic({ commit }) {
    commit('setFetchState', true);
    commit('setTypes', (await api.tickets.public.getTypes()).data);
    commit('setPriorities', (await api.tickets.public.getPriorities()).data);
    commit('setFetchState', false);
  },
  async create({ commit }, { ticket: newTicket, files }) {
    const { data: ticket } = await api.tickets.private.create(newTicket);
    commit('create', { ticket });
    if (files) {
      await api.tickets.public.file.create(ticket.shortId, files);
    }
  },
  async delete({ commit }, ticketId) {
    await api.tickets.private.delete(ticketId);
    commit('delete', ticketId);
  },
  async updatePrivate({ commit, state }, ticket) {
    const oldTicket = state.collection.find(t => t.shortId === ticket.shortId);
    const updatedData = {};
    Object.keys(ticket).forEach(k => {
      if (ticket[k] !== oldTicket[k]) updatedData[k] = ticket[k];
    });

    await api.tickets.private.update(ticket.shortId, updatedData);
    commit('update', { ticketId: ticket.shortId, updatedData });
  },
  async updatePublic({ commit, state }, ticket) {
    const oldTicket = state.collection.find(t => t.shortId === ticket.shortId);
    const updatedData = {};
    Object.keys(ticket).forEach(k => {
      if (ticket[k] !== oldTicket[k]) updatedData[k] = ticket[k];
    });

    await api.tickets.public.update(ticket.shortId, updatedData);
    commit('update', { ticketId: ticket.shortId, updatedData });
  },
  async readPublic({ commit, state }, ticketId) {
    const response = await api.tickets.public.read(ticketId);
    const ticket = state.collection.find(ticket => ticket.shortId === ticketId);
    if (ticket) {
      commit('update', { ticketId, updatedData: response.data });
    } else {
      commit('create', response.data);
    }
  },
  async readPrivate({ commit }, ticketId) {
    const response = await api.tickets.private.read(ticketId);
    const commentResponse = await api.tickets.public.comments.fetch(ticketId);
    const updatedData = {
      ...response.data,
      comments: commentResponse.data,
    };
    commit('update', { ticketId, updatedData });
  },
};

const moduleFiles = {
  namespaced: true,
  actions: {
    async create({ commit, rootState }, { ticketId, files }) {
      const response = await api.tickets.public.file.create(ticketId, files);
      const ticket = rootState.tickets.collection.find(ticket => ticket.shortId === ticketId);
      commit('tickets/update', { ticketId, updatedData: { documents: ticket.documents.concat([response.data]) } }, { root: true });
    },
    async read({ commit }, { ticketId, fileId }) {
      await api.tickets.public.file.read(ticketId, fileId);
    },
    async delete({ commit, rootState }, { ticketId, docId }) {
      await api.tickets.private.file.delete(ticketId, docId);
      const ticket = rootState.tickets.collection.find(ticket => ticket.shortId === ticketId);
      commit(
        'tickets/update',
        { ticketId, updatedData: { documents: ticket.documents.filter(doc => docId !== doc.shortId) } },
        { root: true }
      );
    },
  },
};

const moduleComment = {
  namespaced: true,
  actions: {
    async create({ commit, rootState }, { ticketId, comment }) {
      const response = await api.tickets.public.comments.create(ticketId, comment);
      const ticket = rootState.tickets.collection.find(ticket => ticket.shortId === ticketId);
      commit('tickets/update', { ticketId, updatedData: { comments: ticket.comments.concat([response.data]) } }, { root: true });
    },
    async fetch({ commit }, ticketId) {
      const response = await api.tickets.public.comments.fetch(ticketId);
      commit('tickets/update', { ticketId, updatedData: { comments: response.data } }, { root: true });
    },
  },
};

export default {
  namespaced: true,
  state,
  modules: {
    file: moduleFiles,
    comment: moduleComment,
  },
  mutations,
  actions,
};
