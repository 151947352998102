
























































































import Vue, { PropType } from 'vue';
import _clone from 'lodash/clone';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';

import Button from '@/app/components/inputs/button.vue';
import Field from '@/app/components/inputs/field.vue';
import TextField from '@/app/components/inputs/text-field.vue';

import NodesGroupSelector from '../../nodes-group/components/nodes-group-selector.vue';

import api from '@/services/api';

import { NodesGroup, ReportConfig, ReportConfigCreatePayload, ReportConfigUpdatePayload, ReportRecipient } from '@/types/api';
import { HttpMetadata, HttpMetadataDefaults } from '@/types/http';

// if we're creating, we have no id or authorUuid
type ReportConfigCreateEditData =
  Omit<ReportConfig, 'id' | 'authorUuid' | 'nodesGroups' | 'reportRecipients' | 'createdAt' | 'updatedAt'>
  & { id?: number, authorUuid?: string, nodesGroupsIds: number[], reportRecipientsUuids: string[] };

export default Vue.extend({
  name: 'CreateEditReportConfig',
  components: {
    Button,
    Field,
    TextField,
    NodesGroupSelector,
  },
  props: {
    reportConfigToEdit: {
      type: Object as PropType<ReportConfig | null>,
      default: null,
    },
  },
  data: (): {
    data: ReportConfigCreateEditData,
    meta: HttpMetadata,
  } => ({
    data: {
      title: '',
      description: '',
      enabled: true,
      daily: false,
      weekly: false,
      monthly: true,
      yearly: false,
      nodesGroupsIds: [],
      reportRecipientsUuids: [],
    },
    meta: {
      ...HttpMetadataDefaults,
    },
  }),
  computed: {
    isUpdate(): boolean {
      return !!this.reportConfigToEdit;
    },
    isCreate(): boolean {
      return !this.isUpdate;
    },
    buttonText(): string {
      if (this.isUpdate) {
        return 'Sauver les modifications';
      } else {
        return 'Créer un nouveau rapport';
      }
    }
  },
  created() {
    if (this.reportConfigToEdit !== null) {
      const nodesGroups = this.reportConfigToEdit.nodesGroups as NodesGroup[];
      const reportRecipients = this.reportConfigToEdit.reportRecipients as ReportRecipient[];
      this.data = {
        ..._pick(this.reportConfigToEdit, ['title', 'description', 'enabled', 'daily', 'weekly', 'monthly', 'yearly']),
        nodesGroupsIds: nodesGroups.map(n => n.id as number),
        reportRecipientsUuids: reportRecipients.map(r => r.recipientUuid),
      };
    }
    // hack until we have a recipient selector
    this.data.reportRecipientsUuids = [ this.$store.state.user.user.uuid ];
  },
  methods: {
    async save() {
      // TODO add validation checks
      this.meta.isLoading = true;

      try {
        if (this.isCreate) {
          const createPayload: ReportConfigCreatePayload = {
            ..._omit(this.data, ['id']),
          };

          const res = await api.reportConfig.create(createPayload);

          // update id
          this.data.id = res.data.id;
        } else {
          const updatePayload: ReportConfigUpdatePayload = {
            ..._omit(this.data, ['id']),
          };

          const res = await api.reportConfig.update(this.reportConfigToEdit!.id, updatePayload);
        }
        this.meta.lastFetch = new Date();
        this.$emit('saved', this.data);
      }
      catch (err: any) {
        // FIXME
        this.meta.error = err.message || this.$t('global.errors.generic');
        this.$emit('error', err);
      }
      this.meta.isLoading = false;
    },
    // FIXME the argument to NodesGroupsSelector should be a v-model !
    updateNodesGroups(val: number[]) {
      this.data.nodesGroupsIds = val;
    }
  }
});
