<template>
  <q-page>
    <div class="title">
      Créer un nouveau groupe de données
    </div>
    <div class="actions-container">
      <router-link class="o-button o-button--squared o-button--hover o-button--shadows o-button--primary-gradient"
              :to="{name:'NodesGroupsCenter'}"
              exact>
        {{$t('pages.nodesGroup.buttons.return')}}
      </router-link>
    </div>

  <div class="content-container">
    <div v-if="nodesGroupMeta.isLoading"
         class="message">
        LOADING...
    </div>
    <div v-else-if="nodesGroupMeta.error"
         class="message">
      {{ nodesGroupMeta.error }}
    </div>
    <div v-else
         class="nodes-group-main">
      <NodesGroupDetailsEditor :nodesGroupId="nodesGroupId"
                              @saved="saved" />
    </div>
  </div>
  </q-page>
</template>

<script>
import NodesGroupDetailsEditor from '@/app/pages/nodes-group/components/nodes-group-details-editor.vue';

export default {
  name: 'NodesGroupDetailsLayout',
  components: { NodesGroupDetailsEditor },
  props: {
    nodesGroupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      nodesGroupMeta: {
        isLoading: false,
        error: null,
      },
    };
  },
  methods: {
    saved () {
      // show toast
      this.$q.notify({
        message: this.$t('pages.nodesGroup.toasts.saved'),
        type: 'positive',
        position: 'bottom-left',
      });
      // redirect to nodesGroupCenter
      this.$router.push({ name: 'NodesGroupsCenter' });
    }
  }
};

</script>

<style lang="stylus" scoped>
@import '~variables'

.title
  font-weight 400
  font-size 20px
  margin 20px 0 20px 0
.actions-container
  display flex
  flex-direction row
  gap 10px
  margin 20px 0 20px 0

.content-container
  background-color white
  height fit-content
  border-radius 3px


</style>