/**
 * Works with the /api/devices endpoints, but api.devices is already used by qpark devices.
 */

import { ApiPaginatedResponse, ApiDevice, ApiDevicePropertyPatchPayload, ApiSuccesResponse, ApiErrorReponse } from '@/types/api';
import securedAxios from '../securedAxios';

const OasisDevices = {
  endpoint: '/api/devices',
  getList({ filter }: { filter?: { deviceUuids?: string[]}} = {}) {
    // TODO add pagination
    const searchParams = new URLSearchParams();
    if (filter?.deviceUuids?.length) {
      searchParams.set('deviceUuid', filter.deviceUuids.join(','));
    }
    return securedAxios.get<ApiPaginatedResponse<ApiDevice>>(`${this.endpoint}?${searchParams}`);
  },
  getDeviceByUuid(deviceUuid: string) {
    return securedAxios.get<ApiDevice>(`${this.endpoint}/${deviceUuid}`);
  },
  setDeviceProperty(deviceUuid: string, dataProfileId: number, value: number | boolean) {
    return securedAxios.patch<ApiDevicePropertyPatchPayload, ApiSuccesResponse | ApiErrorReponse>(`${this.endpoint}/${deviceUuid}/properties/${dataProfileId}`, { value });
  },
};

export default OasisDevices;
