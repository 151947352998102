import _ from 'lodash';
import admin from './admin-locale.json';
const adminFR = _.cloneDeep(admin);

adminFR.input = 'Chercher des utilisateurs';
adminFR.info.name = 'Nom complet';
adminFR.info.group.title = 'Groupe';
adminFR.info.group.no = 'Aucun groupe';
adminFR.info.node.title = 'Noeud';
adminFR.info.node.no = 'Aucun noeud';
adminFR.info.node.clipboard = 'Copier';
adminFR.info.node.installed = 'Installé le';
adminFR.info.feature.read = 'Lecture';
adminFR.info.feature.write = 'Ecriture';
adminFR.info.feature.submit = 'Valider';
adminFR.info.feature.edit = 'Modifier';
adminFR.info.feature.cancel = 'Annuler';
adminFR.info.feature.add = 'Ajouter';
adminFR.info.feature.modal = 'Veuillez saisir un nom de feature';
adminFR.info.feature.modalConfirmation = 'Êtes-vous certain de vouloir supprimer la feature';
adminFR.info.feature.form = 'Entrez un nom jamais utilisé svp';
adminFR.info.feature.name = 'Nom';
adminFR.info.oapp.name = 'Nom';
adminFR.info.oapp.installed = 'Installée';
adminFR.info.oapp.available = 'Disponible';
adminFR.info.dashboard.noDashboard = 'Aucun dashboard pour cet utilisateur';
adminFR.info.dashboard.position = 'Position';
adminFR.info.dashboard.noPosition = 'Aucune';
adminFR.info.dashboard.creation = 'Date de création';
adminFR.info.dashboard.widget.noWidget = 'Aucun widget pour ce dashboard';
adminFR.info.dashboard.widget.name = 'Nom';
adminFR.info.dashboard.widget.notAttributed = 'Non attribué';
adminFR.info.dashboard.widget.name_widget = 'Nom du widget';
adminFR.info.dashboard.widget.name_oapp = "Nom de l'oapp";
adminFR.info.dashboard.widget.position = 'Position';
adminFR.info.dashboard.widget.weight = 'Longueur';
adminFR.info.dashboard.widget.height = 'Hauteur';
adminFR.info.dashboard.widget.button = 'Voir le JSON';
adminFR.info.dashboard.modalCancel = 'Fermer';
adminFR.info.dashboard.buttonCopy = 'Copier ce dashboard';
adminFR.info.dashboard.copy.sentence = 'Choisir un utilisateur à qui copier ce dashboard svp';
adminFR.info.dashboard.copy.list = 'Liste des utilisateurs';
adminFR.info.dashboard.copy.error = "Cet utilisateur n'a pas accès à la feature 'dashboard'";
adminFR.info.dashboard.copy.cancel = 'Fermer';
adminFR.info.dashboard.copy.submit = 'Copier';
adminFR.info.dashboard.buttonShare = 'Partager ce dashboard';
adminFR.info.dashboard.share.sentence = 'Choisir un utilisateur à qui partager ce dashboard svp';
adminFR.info.dashboard.share.list = 'Liste des utilisateurs';
adminFR.info.dashboard.share.error = "Cet utilisateur n'a pas accès à la feature 'dashboard'";
adminFR.info.dashboard.share.cancel = 'Fermer';
adminFR.info.dashboard.share.submit = 'Partager';

export default adminFR;
