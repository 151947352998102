






















































import Vue from 'vue';
import { NodesGroup } from '@/types/api';
import { QTableColumn } from '@/types/quasar';

interface NodesGroupData {
  name: string;
  description?: string;
  nodes: string;
  id: number;
}

export default Vue.extend({
  name: 'NodesGroupsCenter',
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    nodesGroups(): NodesGroup[] {
      return this.$store.state.nodesGroup.collection.slice().reverse();
    },
    tableColumns(): QTableColumn[]{
      return [
        {
          name: 'name',
          field: 'name',
          required: true,
          label: 'Name',
          align: 'left',
        },
        {
          name: 'description',
          field: 'description',
          required: true,
          label: 'Description',
          align: 'center',
        },
        {
          name: 'nodes',
          field: 'nodes',
          required: true,
          label: 'Liste de noeuds',
          align: 'center',
        },
        {
          name: 'edit',
          field: 'edit',
          required: true,
          label: 'Modifier',
          align: 'center',
        },
      ];
    },
    nodesGroupData(): NodesGroupData[] {
      if (!this.nodesGroups) return [];
      const data = (this.nodesGroups).map(nodesGroup => {
        return {
          name: nodesGroup.name,
          description: nodesGroup.description,
          nodes: nodesGroup.nodes.join(', ') || 'n/a',
          id: nodesGroup.id as number,
        };
      });
      return data;
    },
  },
  created() {
    this.$store.dispatch('nodesGroup/fetch');
  },
  methods: {
    navigate(nodesGroupId: string) {
      this.$router.push({ name: 'nodesGroupDetails', params: { nodesGroupId: nodesGroupId } });
    },
  }
});
