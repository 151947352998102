<template>
<q-page-container class="details">
<q-tabs v-if="ticketsRight.READ || mapsRight.READ || deviceDataRight.READ"
        animated
        inverted
        class="tabs-container"
        color="secondary"
        align="justify">
  <q-tab v-if="deviceDataRight.READ"
         slot="title"
         default
         name="tab-data"
         :label="$t('pages.alert.labels.data')" />
  <q-tab v-if="mapsRight.READ"
         slot="title"
         name="tab-plan"
         :label="$t('pages.alert.labels.localisation')" />
  <q-tab v-if="ticketsRight.READ"
         slot="title"
         name="tab-ticket"
         :label="createOrEditTitle | capitalize" />
  <q-tab-pane v-if="deviceDataRight.READ"
              name="tab-data">
    <div class="data-container">
      <PeriodSelector v-model="intervalTime"
                      :showDateTime="true"
                      :inAlert="true"
                      @input="updateDuration" />
      <div class="date-info">
        {{`${displayDuration.from} — ${displayDuration.to}`}}
      </div>
      <GraphDevice id="graphChart"
                  :data="graphData"
                  :unit="currentProfilUnit"
                  :height="chartHeight"
                  :width="chartWidth" />
    </div>
  </q-tab-pane>
  <q-tab-pane v-if="mapsRight.READ"
              name="tab-plan">
    <div class="plan-container">
      <div v-if="!alert.floorUuid">
        {{ $t('pages.alert.errors.noLocation') }}
      </div>
      <div v-else
          class="map-container">
        <Floor2DMap :floorUuid="alert.floorUuid"
                    :highlightRooms="floor2dMapHighlightRooms"
                    :highlightPoints="floor2dMapHighlightPoints"
                    color="red" />
      </div>
    </div>
  </q-tab-pane>
  <q-tab-pane v-if="ticketsRight.READ"
              name="tab-ticket"
              class="no-padding">
    <div class="ticket-container">
        <TicketView v-if="ticket && !edition"
          :ticket="ticket"
          @startEdition="startEdition"
        />
        <TicketCreateEditForm v-else-if="ticketsRight.WRITE"
          :currentTicket="ticket"
          :ticketPosition="ticketPosition"
          :alertId="alert.id"
          :showCancelButton="!!ticket"
          @exitEdition="exitEdition"
        />
    </div>
  </q-tab-pane>
</q-tabs>
</q-page-container>
</template>

<script>
import api from 'api';
import TicketCreateEditForm from '@/app/pages/ticket/components/ticket-create-edit-form.vue';
import TicketView from '@/app/pages/ticket/views/ticket-view.vue';
import Floor2DMap from '@/app/components/floors/Floor2DMap.vue';
import GraphDevice from '@/app/components/charts/graph-device.vue';
import PeriodSelector from '@/oapps/widgets/period-selector.vue';
import moment from 'moment';

/**
 * emits:
 *  - alertUpdated: when the alert has been updated. It should trigger an update from the component that passes the prop 'alert'
 */
export default {
  name: 'AlertDetails',
  components: {
    TicketCreateEditForm,
    TicketView,
    Floor2DMap,
    GraphDevice,
    PeriodSelector,
  },
  props: {
    alert: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      // if there is an existing ticket, are we editing it ?
      edition: false,
      // the data to display in the 'data' tab
      profileData: null,
      duration: { number: 24, string: 'hours' },
      displayFormat: 'D MMM YYYY, HH[h]mm',
      intervalTime: '24hours',
      chartWidth:700,
      chartHeight:500,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    deviceDataRight() {
      return this.user.featuresRights.deviceData;
    },
    mapsRight() {
      return this.user.featuresRights.maps;
    },
    ticketsRight() {
      return this.user.featuresRights.ticket;
    },
    createOrEditTitle() {
      if (this.ticket === null) {
        return this.$t('pages.ticket.sidebarOut.buttons.createTicket');
      } else {
        return this.$t('pages.ticket.creationEdition.editTitle');
      }
    },
    ticket() {
      if (!this.alert.ticketId) {
        return null;
      }
      return this.$store.state.tickets.collection.find(t => t.id === this.alert.ticketId) || null;
    },
    ticketPosition() {
      return {
        nodeUuid: this.alert.nodeUuid,
        buildingUuid: this.alert.buildingUuid,
        floorUuid: this.alert.floorUuid,
        roomUuid: this.alert.roomUuid,
      };
    },
    floor2dMapHighlightRooms() {
      if (!this.alert) {
        return null;
      }
      return [this.alert.roomUuid];
    },
    floor2dMapHighlightPoints() {
      if (!this.alert || !this.alert.position) {
        return null;
      }
      return [this.alert.position];
    },
    graphData() {
      if (!this.profileData) return { labels: [], values: [] };
      else return this.profileData.result.reduce(
        (acc, d) => {
          acc.labels.push(moment(d.time).format(this.displayFormat));
          acc.values.push(d.mean);
          return acc;
        },
        { labels: [], values: [] }
      );
    },
    currentProfilUnit() {
      return this.alert.profile.unit;
    },
    displayDuration() {
      if (this.duration.from && this.duration.to) {
        return {
          from: moment(this.duration.from).format(this.displayFormat),
          to: moment(this.duration.to).format(this.displayFormat),
        };
      }
      const duration = this.intervalToMoment(this.duration);
      return {
        from: moment(duration.from).format(this.displayFormat),
        to: moment(duration.to).format(this.displayFormat),
      };
    },
  },
  async created() {
    if (this.ticketsRight.READ) {
      await this.$store.dispatch('tickets/fetch');
    }
    if (this.deviceDataRight) {
      this.fetchData();
    }
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    startEdition() {
      this.edition = true;
    },
    async exitEdition() {
      // ticket has been edited, upadte the ticket and the alert
      this.edition = false;
      this.$emit('alertUpdated');
      await this.$store.dispatch('tickets/fetch');
    },
    async fetchData() {
      const duration = this.duration.number ? this.intervalToMoment(this.duration) :  this.duration;
      const { data: profilesData } = await api.devices.data.read({
        deviceUuid: this.alert.deviceUuid,
        from: duration.from,
        to: duration.to,
      });
      const finalData = profilesData.find(pData => pData.name === this.alert.profile.name);
      this.profileData = finalData;
    },
    intervalToMoment(duration) {
      const to = moment();
      const from = moment().subtract(duration.number, duration.string);
      return { from: from.format(), to: to.format() };
    },
    updateDuration(newDuration) {
      if (newDuration.value && newDuration.values) {
        this.intervalTime = newDuration.value;
        this.duration = newDuration.values;
        this.fetchData();
      } else {
        this.duration = { from: newDuration.from, to: newDuration.to};
        this.fetchData();
      }
    },
    onResize() {
      if (window.innerWidth > 650) {
        this.chartWidth = 650;
        this.chartHeight = 500;
      } else if (window.innerWidth > 460) {
        this.chartWidth = 500;
        this.chartHeight = 400;
      } else if (window.innerWidth > 300) {
        this.chartWidth = 400;
        this.chartHeight = 300;
      } else {
        this.chartWidth = 270;
        this.chartHeight = 200;
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.row > div
  padding: 5px 5px
.no-padding
  padding 0

.details
  .tabs-container
    display flex
    flex-direction column
    max-width 800px
  .ticket-container
    background-color $light
    display flex
    justify-content center
    align-items center
    margin auto
    width 100%
    .ticket-main
      width 100%
      .ticket-form
        border none
  .plan-container
    display flex
    justify-content center
    align-items center
    .map-container
      height 100%
  .data-container
    display flex
    flex-direction column
    height 100%
    align-items center
    padding $space-2
    .periodSelector
      margin-left auto
      width fit-content
      margin 0 100px
    .date-info
      display flex
      justify-content center
      padding $space-1
      font-weight 300
      font-size $fs-h3
  .message
    display grid
    font-size $fs-h1
    place-items center

</style>