import Vue from 'vue';
import api from '@/services/api';
import { ApiDevice } from '@/types/api';
import { Module } from 'vuex/types';
import { RootStoreState } from '..';
import { HttpMetadata, HttpMetadataDefaults } from '@/types/http';
import { errorMessageFromApiError } from '@/services/api/utils';

export interface OasisDevicesStoreState {
  meta: HttpMetadata,
  collection: ApiDevice[];
}

const getDefaultState = () : OasisDevicesStoreState => ({
  meta: {
    ...HttpMetadataDefaults
  },
  collection: [],
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    set: (state, { collection, meta }: Partial<OasisDevicesStoreState>) => {
      if (collection !== undefined) {
        Vue.set(state, 'collection', collection);
      }

      if (meta !== undefined) {
        Vue.set(state, 'meta', { ...state.meta, ...meta });
      }
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async fetch({ commit, state }) {
      // avoid fetching if we're already doing it
      if (state.meta.isLoading) {
        return;
      }

      // avoid re-loading if data is fresh (loaded less than 5 secs ago)
      if (
        state.meta.lastFetch !== null &&
        (new Date().getTime() - state.meta.lastFetch.getTime() < 5000) &&
        state.meta.error === null
      ) {
        return;
      }

      commit('set', {
        meta: {
          isLoading: true,
          error: null,
        }
      });

      try {
        const fetchResult = await api.oasisDevices.getList();
        commit('set', {
          collection: fetchResult.data,
          meta: {
            lastFetch: new Date(),
            isLoading: false,
            error: null,
          }
        });
      } catch (err) {
        commit('set', {
          meta: {
            isLoading: false,
            error: errorMessageFromApiError(err as Error),
          }
        });
      }
    },
  }
} as Module<OasisDevicesStoreState, RootStoreState>;
