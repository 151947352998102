import { render, staticRenderFns } from "./single-page.vue?vue&type=template&id=69416e42&scoped=true&"
import script from "./single-page.vue?vue&type=script&lang=ts&"
export * from "./single-page.vue?vue&type=script&lang=ts&"
import style0 from "./single-page.vue?vue&type=style&index=0&id=69416e42&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69416e42",
  null
  
)

export default component.exports