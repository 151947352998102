import _ from 'lodash';
import errorsBase from './errors-locale-object.json';
const errorsFR = _.cloneDeep(errorsBase);

errorsFR.api.alertAlreadyResolved = 'L\'alerte a déjà été résolue';
errorsFR.api.alertNotFound = 'Impossible de trouver l\'alerte';
errorsFR.api.alertRuleNotFound = "Impossible de trouver la règle d'alerte";
errorsFR.api.forbiddenUser = 'Accès interdit pour cet utilisateur';
errorsFR.generic = 'Une erreur est survenue';
errorsFR.genericWithDetails = '@:errors.generic ({details})';

export default errorsFR;
