/**
 * Metadata for data retrieved by http calls.
 */
export interface HttpMetadata {
  isLoading: boolean;
  error: string | null;
  lastFetch: Date | null;
}

/**
 * Default values for HttpMetata fields.
 */
export const HttpMetadataDefaults: HttpMetadata = {
  isLoading: false,
  error: null,
  lastFetch: null
};
