export default {
  data() {
    return {
      timeoutID: null,
    };
  },
  methods: {
    debounce(fn, delay) {
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        fn();
      }, delay);
    },
    clearDebounce() {
      clearTimeout(this.timeoutID);
    },
  },
};
