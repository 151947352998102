import { render, staticRenderFns } from "./ticket-comments.vue?vue&type=template&id=839e6480&scoped=true&"
import script from "./ticket-comments.vue?vue&type=script&lang=js&"
export * from "./ticket-comments.vue?vue&type=script&lang=js&"
import style0 from "./ticket-comments.vue?vue&type=style&index=0&id=839e6480&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "839e6480",
  null
  
)

export default component.exports