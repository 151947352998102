const getSelectArr = cols => {
  const res = [];
  for (const d of cols) {
    if (d.indexOf('.') !== -1) {
      if (!cols.includes(d.substring(0, d.indexOf('.')))) {
        res.push(d);
      }
    } else {
      res.push(`${d}.*`);
    }
  }
  return res;
};

const buildWhere = (cols, date, name, functions) => {
  let where = '';
  if (name.length > 0) {
    for (const c of cols) {
      if (where === '') {
        where += `( ${c}.QUuid = '${name}' `;
      } else {
        where += `OR ${c}.QUuid = '${name}'`;
      }
    }
    where += ' )';
  }
  if (date.from) where += `${where.length > 0 ? ' AND (' : '('} data.*.time > '${date.from}'`;
  if (date.to) where += `${date.from ? ' AND' : '( '} data.*.time < '${date.to}'`;
  where += date.to || date.from ? ' )' : '';
  if (functions.length > 0) {
    for (const f of functions) {
      if (f.fnc && f.field && f.sign && f.value)
        if (isNaN(f.value)) {
          where += `${where.length > 0 ? ' AND (' : '('} ${f.fnc === 'value' ? '' : f.fnc}(${f.field}) ${f.sign} '${f.value}' )`;
        } else {
          where += `${where.length > 0 ? ' AND (' : '('} ${f.fnc === 'value' ? '' : f.fnc}(${f.field}) ${f.sign} ${f.value} )`;
        }
    }
  }
  return where;
};

export default {
  buildQuery(columns, node, date, name, functions, limit) {
    let cols = columns.selected.map(x => {
      const elem = columns.functions.find(y => y.value === x && y.func !== 'VALUE');
      return elem ? `${elem.func}(${x})` : x;
    });
    let query = '';
    let select = '';
    const from = node === '' ? 'ROOT-0000-0000-0000-000000000000' : node;
    cols = getSelectArr(cols);
    for (const s of cols) {
      if (select === '') {
        select += s;
      } else {
        select += `, ${s}`;
      }
    }
    select = select === '' ? '*' : select;
    const where = buildWhere(cols, date, name, functions);
    query = `SELECT ${select} FROM ${from} ${where.length > 0 ? `WHERE ${where}` : ''} ${limit !== null ? `LIMIT ${limit}` : ''};`;
    return query;
  },
  disableSend(columns, date, names, functions) {
    if (columns.selected && columns.selected.length > 0) {
      if (functions.length > 0) if (functions[0].fnc && functions[0].field && functions[0].sign && functions[0].value) return false;
      return !(date.from || date.to || names.length);
    }
    return true;
  },
};
