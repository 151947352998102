





























import Vue, { PropType } from 'vue';

import moment from 'moment';
import api from '@/services/api';
import BarGraph, { BarGraphData } from '@/app/components/charts/bar-graph.vue';
import LineHistogramGraph, { LineGraphData } from '@/app/components/charts/line-histogram-graph.vue';
import PeriodRangeSelector, { Period, PeriodType } from '@/app/pages/report/components/period-range-selector.vue';
import FetchableData from '@/app/components/ui/FetchableData.vue';
import { HttpMetadata, HttpMetadataDefaults } from '@/types/http';

import { NodesGroup, DataProfile } from '@/types/api';

export default Vue.extend({
  name: 'ReportingDashboardHistogram',
  components: {
    BarGraph,
    LineHistogramGraph,
    PeriodRangeSelector,
    FetchableData,
  },
  props: {
    nodesGroups: {
      type: Array as PropType<Array<NodesGroup>>,
      required: true,
    }
  },
  data () {
    return {
      period: null as Period | null,
      dataProfile: null as DataProfile | null,
      nodesGroupSelected: {} as { [key: string]: boolean },
      graphData: [] as BarGraphData | LineGraphData,
      histogramMeta: { ...HttpMetadataDefaults } as HttpMetadata,
    };
  },
  computed: {
    filteredData(): BarGraphData | LineGraphData {
      // add filter option for space selection/unselection
      return this.graphData.filter(x => this.nodesGroupSelected[x.label] === true);
    },
    dataProfileUnit(): string | null {
      // TODO update when we have optional chaining to 'return this.dataProfile?.unit || null;'
      if (!this.dataProfile) {
        return null;
      }
      return this.dataProfile.unit;
    },
    nodesGroupNames(): Array<string> {
      return this.nodesGroups.map(ng => ng.name);
    },
    timeIntervalLabels(): string[] {
      if (!this.period) {
        return [];
      }
      switch (this.period.periodType) {
        case PeriodType.Day: {
          return [...Array(24).keys()].map(n => `${n} h`);
        }
        case PeriodType.Week: {
          // TODO check that it begins on mondays in our locale
          return moment.weekdays();
        }
        case PeriodType.Month: {
          const nbDaysInMonth = moment(this.period.from).daysInMonth();
          return [...Array(nbDaysInMonth).keys()].map(n => (n+1).toString());
        }
        case PeriodType.Year: {
          return moment.months();
        }
      }
      return []; // never reached but here so that eslint does not throw an error
    },
    dataProfileName(): string {
      return this.dataProfile ? this.dataProfile.name : '';
    },
    // depending on the unit, we display either a line graph or a bar chart
    graphType(): string {
      const typeList: { [key: string]: string } = {
        '': 'linegraph', // by default
        'db': 'linegraph',
        '°C': 'linegraph',
        '%': 'linegraph',
        'ppm': 'linegraph', // co2 / cov level
        'lux': 'linegraph', // luminosity
        'Wh': 'barGraph', // electricity consumption
        'kWh': 'barGraph', // electricity consumption
        'W': 'linegraph', // power
        'mW': 'linegraph', // power
        'mA': 'barGraph',
        'mHz': 'linegraph', // power frequency
        'µg/m3': 'linegraph', // Hager COVG
        'Mpart/m3': 'linegraph', // Hager COV
        'm3': 'barGraph', // water consumption
        'L/h': 'linegraph', // water flow
        'm3/h': 'barGraph', // air flow
      };
      if (this.dataProfileUnit !== null && this.dataProfileUnit in typeList) {
        return typeList[this.dataProfileUnit];
      }
      return typeList[''];
    }
  },
  watch: {
    nodesGroups: {
      immediate: true,
      async handler() {
        this.nodesGroupSelected = Object.fromEntries(
          this.nodesGroups.map(
            (ng) => [ ng.name, true ]
          )
        );
        await this.fetchData();
      },
    }
  },
  methods: {
    selectionClass(nodesGroupName: string) {
      return {
        'space--selected': this.nodesGroupSelected[nodesGroupName],
      };
    },
    // called when the selected PeriodRange has been updated
    onPeriodRangeUpdated(period: Period) {
      this.period = period;
      this.fetchData();
      this.$emit('periodChange', period);
    },
    async fetchData(): Promise<void> {
      if (!this.period) {
        return;
      }

      if (this.nodesGroups.length === 0) {
        throw new Error(`can't fetch dataprofile`);
      }

      this.histogramMeta.isLoading = true;
      this.histogramMeta.error = null;

      try {
        // fetch dataprofile. For the moment we assume all nodesGroups have the same so we use the dataProfileId from the first.
        const nodesGroupId = this.nodesGroups[0].id;
        this.dataProfile = (await api.nodesGroup.getDataProfile(nodesGroupId)).data;

        // fetch all data
        this.graphData = await Promise.all(
          this.nodesGroups.map(async ng => ({
            label: ng.name,
            values: await this.fetchDataForOneNodesGroup(ng, this.period as Period),
          }))
        );

        this.histogramMeta.lastFetch = new Date();
      } catch (err: any) {
        this.histogramMeta.error = err.message || this.$t('errors.generic');
      }

      this.histogramMeta.isLoading = false;
    },
    // fetch data for one nodesGroup on one period
    async fetchDataForOneNodesGroup(nodesGroup: NodesGroup, period: Period): Promise<Array<number>> {
      const nodesGroupData = (await api.nodesGroup.fetchData(nodesGroup.id, period)).data;
      const data = nodesGroupData.result.map((d: { value: number}) => d.value);
      return data;
    },
  }
});
