


























import Vue, { PropType } from 'vue';
import moment from 'moment';
import axios from 'axios';

import { Report } from '@/types/api';
import { QTableColumn } from '@/types/quasar';

interface ReportData {
  groupBy: string,
  dateExecuted: string,
  id: number,
  filePath: string,
}

/**
 * display a list of reports
 * hacky and pagination not done
 */
export default Vue.extend({
  name: 'ReportsList',
  props: {
    reports: {
      type: Array as PropType<Report[] | null> ,
      required: true,
    }
  },
  data() {
    return {
      limit: 10,
    };
  },
  computed: {
    tableColumns(): QTableColumn[]{
      return [
        {
          name: 'dateExecuted',
          field: 'dateExecuted',
          required: true,
          label: 'Date du rapport',
          align: 'center',
        },
        {
          name: 'startDateTime',
          field: 'startDateTime',
          required: true,
          label: 'Date de début',
          align: 'center',
        },
        {
          name: 'endDateTime',
          field: 'endDateTime',
          required: true,
          label: 'Date de fin',
          align: 'center',
        },
        {
          name: 'groupBy',
          field: 'groupBy',
          required: true,
          label: 'Interval',
          align: 'center',
        },
        {
          name: 'download',
          field: 'download',
          required: true,
          label: 'Télécharger',
          align: 'center',
          classes: 'w-0',
        },
      ];
    },
    reportData(): ReportData[] {
      if (!this.reports) return [];
      const data = (this.reports).map(report => {
        return {
          dateExecuted: moment(report.dateExecuted).format('DD-MM-YYYY HH:mm'),
          startDateTime: moment(report.startDateTime).format('DD-MM-YYYY HH:mm'),
          endDateTime: moment(report.endDateTime).format('DD-MM-YYYY HH:mm'),
          groupBy: report.groupBy,
          id: report.id,
          filePath: report.filePath,
        };
      });
      return data.sort((a, b) => b.id - a.id);
    },
  },
  methods: {
    periodType(reportGroupBy: string): string {
      const groupByToPeriodTypeString = {
        '1d': 'Quotidien',
        '1w': 'Hebdomadaire',
        '1m': 'Mensuel',
        '1y': 'Annuel'
      } as Record<string, string>;
      return groupByToPeriodTypeString[reportGroupBy] || 'n/a';
    },
    reportPeriod(report: Report): string {
      const startDateMoment = moment(report.startDateTime);
      switch (report.groupBy) {
        case '1d':
          return startDateMoment.format('DD-MM-YYYY');
        case '1w':
          return `${startDateMoment.format('DD-MM-YYYY')} - ${moment(report.endDateTime).format('DD-MM-YYYY')}`;
        case '1m':
          return `${moment.months()[startDateMoment.month()]} ${startDateMoment.year()}`;
        case '1y':
          return `${moment(report.startDateTime).year()}`;
        default:
          return 'n/a';
      }
    },
    clickDownload(filePath: string, reportId: number) {
      axios({
        url: `/api/reports/${reportId}/csv`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', filePath);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }
  }
});
