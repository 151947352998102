<!--eslint-disable vue/attribute-hyphenation -->
<template>
  <q-page class="alert-rules">
    <div class="title">
      Centre de règles d'alertes
    </div>
    <div v-if="user.featuresRights.alertRules.WRITE"
        class="actions-container">
      <router-link
        tag="button"
        class="o-button o-button--squared o-button--hover o-button--shadows"
        :to="{ name: 'alertRuleCreation' }"
      >
        {{$t('pages.alertRule.buttons.createNewAlertRule')}}
      </router-link>
    </div>

    <div class="content-container">

      <q-table
        class="table"
        title="Centre de règles d'alertes"
        :data="tableData"
        :columns="columns"
        separator="horizontal"
        row-key="name"
        no-data-label="Vous n'avez aucune règles d'alertes pour l'instant"
        no-results-label="Aucune regle d'alertes ne correspond à ce filtre"
        loading-label="Récupération des règles d'alertes en cours..."
        :loading="loading"
        :pagination.sync="pagination"
        :filter="filter"
      >
        <template slot="top-left">
          <q-search
            v-model="filter"
            hide-underline
            color="tertiary"
            class="table-control-left"
          />

        </template>
        <template slot="top-left">
        </template>

          <q-tr slot="body"
                slot-scope="props"
                class="pointer"
                :props="props"
                @click.native="rowClick(props.row)">
            <q-td key="title"
                :props="props">
              {{ props.row.title }}
            </q-td>
            <q-td key="description"
                  class="long-text"
                  :props="props">
                {{ props.row.description }}
              <q-tooltip v-if="props.row.description.length > 1"
                        anchor="bottom left"
                        class="tooltip"
                        self="top left"
                        :offset="[-20, 10]">
                <div class="wrap-text">
                  {{props.row.description}}
                </div>
              </q-tooltip>
            </q-td>
            <q-td key="nodeList"
                  :props="props">
                {{ props.row.nodesListNumberString }}
              <q-tooltip v-if="props.row.nodeList.length > 0"
                        anchor="bottom left"
                        class="tooltip"
                        self="top left"
                        :offset="[-20, 10]">
                  <div v-for="node in props.row.nodeList"
                       :key="node">
                    {{ node }}
                  </div>
              </q-tooltip>
            </q-td>
            <q-td key="profile"
                :props="props">
              {{ props.row.profile }}
            </q-td>
            <q-td key="trigger"
                class="long-text"
                :props="props">
              {{ props.row.trigger }}
              <q-tooltip anchor="bottom left"
                        class="tooltip"
                        self="top left"
                        :offset="[-20, 10]">
              {{ props.row.trigger }}
                <pre>{{ JSON.stringify(props.row.triggerObj, null, 2) }}</pre>
              </q-tooltip>
            </q-td>
            <q-td key="resolve"
                class="long-text"
                :props="props">
              {{ props.row.resolve }}
              <q-tooltip anchor="bottom left"
                        class="tooltip"
                        self="top left"
                        :offset="[-20, 10]">
                {{ props.row.resolve }}
                <pre>{{ JSON.stringify(props.row.resolveObj, null, 2) }}</pre>
              </q-tooltip>
            </q-td>
          </q-tr>
      </q-table>
    </div>
  </q-page>
</template>

<script>

export default {
  name: 'AlertRules',
  data() {
    return {
      pagination: {
        sortBy: 'datetime',
        descending: true,
        page: 1,
        rowsPerPage: 10
      },
      filter: '',
      columns: [
        {
          name: 'title',
          required: true,
          label: 'Titre',
          align: 'left',
          field: 'title',
          sortable: true,
        },
        {
          name: 'description',
          required: true,
          label: 'Description',
          align: 'left',
          field: 'description',
          sortable: true,
        },

        {
          name: 'nodeList',
          label: 'Topologie',
          align: 'left',
          field: 'nodeList',
        },
        {
          name: 'profile',
          required: true,
          label: 'Profile de donnée',
          align: 'left',
          field: 'profile',
          sortable: true,
        },
        {
          name: 'trigger',
          label: 'Déclanchement',
          align: 'left',
          field: 'trigger',
          sortable: true,
        },
        {
          name: 'resolve',
          label: 'Résolution',
          align: 'left',
          field: 'resolve',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    loading() {
      return this.$store.state.alertRule.meta.isLoading;
    },
    tableData() {
      const filteredAlertRules = [...this.$store.state.alertRule.collection];
      return filteredAlertRules.map(alertRule => {
        const row = {
          title: alertRule.title,
          description: alertRule.description && alertRule.description.trim() || '-',
          nodeList: alertRule.selector?.nodeList || [],
          alertRuleId: alertRule.id,
          triggerObj: alertRule.selector?.trigger || {},
          resolveObj: alertRule.selector?.resolve || {},
        };
        // node list
        if (row.nodeList.length === 0)
          row.nodesListNumberString ='Aucun noeud';
        else if (row.nodeList.length === 1)
          row.nodesListNumberString ='un noeud';
        else
          row.nodesListNumberString = `${row.nodeList.length} noeuds`;

        const profile = alertRule.selector?.profile;
        // data profile
        if (profile?.name) {
          row.profile = profile.name;
          if (profile?.unit)
            row.profile = `${row.profile} (${profile.unit})`;
        } else {
          row.profile = '-';
        }

        // trigger str
        const trigger = row.triggerObj;
        if (trigger.dataSelector && profile?.measure && profile?.value && trigger.op)
          row.trigger =`${trigger.dataSelector} ${profile.measure} ${profile.value}`
                + ` over lasts ${trigger.durationInSeconds} seconds is ${trigger.op}`
                + ` ${trigger.threshold} ${profile.unit}`;
        else
          row.trigger = 'custom';

        // resolve str
        const resolve = row.resolveObj;
        if (resolve.dataSelector && profile?.measure && profile?.value && resolve.op)
          row.resolve = `${resolve.dataSelector} ${profile.measure} ${profile.value}`
                  + ` over lasts ${resolve.durationInSeconds} seconds is`
                  + ` ${resolve.op} ${resolve.threshold} ${profile.unit}`;
        else
          row.resolve = 'custom';

        return row;
      });
    },
  },
  created() {
    this.$store.dispatch('alertRule/fetch');
  },
  methods: {
    rowClick(row) {
      // go to alertRule details page
      this.$router.push({
        name: 'alertRuleDetails',
        params: { alertRuleId: row.alertRuleId },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.pointer
  cursor pointer

.title
  font-weight 400
  margin $space-2
  width 100%
  font-size $fs-h1

.table
  height fit-content
  background-color $dash-light
  border-radius 3px
  box-shadow none

.table-control-left
  margin-right 20px
.table-control-right
  margin-left 20px

.long-text
  max-width 200px
  text-overflow ellipsis
  overflow hidden
.wrap-text
  max-width 300px
  overflow-wrap anywhere

.actions-container
  display flex
  flex-direction row
  gap 10px
  margin 20px 0 20px 0

</style>
