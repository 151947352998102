import Vue from 'vue';
import api from 'api';

// Models
import Root from 'models/topology/root';
import Country from 'models/topology/country';
import City from 'models/topology/city';
import Building from 'models/topology/building';
import Floor from 'models/topology/floor';
import Space from 'models/topology/space';
import Plug from 'models/topology/plug';

const Node = {
  country: Country,
  city: City,
  building: Building,
  floor: Floor,
  space: Space,
  plug: Plug,
};

const getDefaultState = () => ({
  root: null,
  buildings: [],
  fetching: false,
  selectedNode: null,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    root: state => state.root,
  },
  mutations: {
    resetState: state => Object.assign(state, getDefaultState()),
    setRoot: (state, root) => Vue.set(state, 'root', root),
    setBuildings: (state, buildings) => Vue.set(state, 'buildings', buildings),
    setFetchState: (state, fetchState) => (state.fetching = fetchState),
    setSelectedNode: (state, node) => Vue.set(state, 'selectedNode', node),
    create: (state, node) => {
      let iterator = state.root;
      node.path.forEach(pathUuid => {
        if (iterator.uuid !== pathUuid) iterator = iterator.next.find(node => node.uuid === pathUuid);
      });
      if (!iterator.next) iterator.next = [];
      Vue.set(iterator.next, iterator.next.length, node);
    },
    update: (state, { path, updatedData }) => {
      let iterator = state.root;
      path.forEach(pathUuid => {
        if (iterator.uuid !== pathUuid) iterator = iterator.next.find(n => n.uuid === pathUuid);
      });
      const node = iterator.next.find(n => n.uuid === updatedData.uuid);
      Object.keys(node).forEach(k => (k in updatedData ? (node[k] = updatedData[k]) : ''));
    },
    delete: (state, { path, uuid }) => {
      let iterator = state.root;
      path.forEach(pathUuid => {
        if (iterator.uuid !== pathUuid) iterator = iterator.next.find(node => node.uuid === pathUuid);
      });
      const nodeIndex = iterator.next.findIndex(node => node.uuid === uuid);
      iterator.next.splice(nodeIndex, 1);
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    fetch: async ({ commit }) => {
      commit('setFetchState', true);
      const { data: root } = await api.topology.fetch('full');
      commit(
        'setRoot',
        new Root(root, {
          showFloorWithNoSVG: true,
        })
      );
      commit('setFetchState', false);
    },
    fetchEnergy: async ({ commit }) => {
      commit('setFetchState', true);
      const { data: root } = await api.topology.fetch('energy');
      commit(
        'setRoot',
        new Root(root, {
          showFloorWithNoSVG: true,
        })
      );
      commit('setFetchState', false);
    },
    fetchSimple: async ({ commit }) => {
      commit('setFetchState', true);
      const { data: buildings } = await api.topology.fetch('simple');
      commit('setBuildings', buildings);
      commit('setFetchState', false);
    },
    create: async ({ commit }, { node, nodeParent }) => {
      try {
        const { data: newNode } = await api.node.create(node);
        commit('create', new Node[newNode.nodeType](newNode, nodeParent));
      } catch (error) {
        throw error;
      }
    },
    update: async ({ commit }, { node, updatedData }) => {
      const { data: res } = await api.node.update(node.uuid, updatedData);
      commit('update', { path: node.path, updatedData: res });
    },
    delete: async ({ commit, state }, { node, force }) => {
      try {
        await api.node.delete(node.uuid, force);
        commit('delete', node);
      } catch (error) {
        throw error;
      }
    },
  },
};
