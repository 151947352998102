<template>
  <div class="text-field"
       :class="textFieldClass">
    <label v-if="label"
           class="text-field__label"
           :class="labelClass">
      {{label}}
      <span v-if="required"
            class="text-field__required">*</span></label>
    <span v-if="hint"
          class="text-field__hint">{{hint}}</span>
    <div class="input-container"
         :class="inputContainerClass">
      <slot />
    </div>
    <span v-if="errorMessage"
          class="text-field__error-message">{{errorMessage}}</span>
  </div>
</template>

<script>
export default {
  name: 'Field',
  props: {
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labelClass() {
      return {
        'text-field__label--hint': this.hint,
      };
    },
    inputContainerClass() {
      return {
        'input-container--error': this.errorMessage,
      };
    },
    textFieldClass() {
      return {
        'text-field--read-only': this.readOnly,
      };
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.text-field
  display flex
  flex-direction column
  .text-field__label
    margin-bottom $space-1
    font-size $fs-h1
    &--hint
      margin-bottom 0px
  .text-field__required
    color $negative
    font-size $fs-h1
  .text-field__hint
    margin-bottom $space-1
    color $infos
    font-size $fs-body
  .input-container
    margin-bottom 'calc(%s + %s)' % ($space-little 15px)
    font-size $fs-h2
    &--error
      margin-bottom $space-little
  .text-field__error-message
    color $negative
</style>