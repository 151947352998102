<template>
  <svg :style="svgStyle"
       viewBox="0 0 47 46"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <line :style="lineStyle"
          x1="23.5"
          x2="23.5"
          y2="46"
          stroke-width="4"
    />
    <line :style="lineStyle"
          x1="0.00614284"
          y1="22.5"
          x2="46.0058"
          y2="22.6884"
          stroke-width="4"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlusPicto',
  props: {
    size: {
      type: String,
      default: '100%',
    },
    color: {
      type: String,
      default: 'black',
    },
  },
  computed: {
    svgStyle() {
      return {
        width: this.size,
        height: this.size,
      };
    },
    lineStyle() {
      return {
        stroke: this.color,
      };
    },
  },
};
</script>
