import _ from 'lodash';
import widgets from './widgets-locale.json';
const widgetsEN = _.cloneDeep(widgets);

// Base
widgetsEN.base.fetching = 'fetching';
widgetsEN.base.edit = 'éditer';
widgetsEN.base.delete = 'supprimer';
widgetsEN.base.to = 'to';
// PresenceRate
widgetsEN.presenceRate.occupied = 'occupied';
widgetsEN.presenceRate.free = 'free';
widgetsEN.presenceRate.realTime = 'temps réel';
widgetsEN.presenceRate.occupationRate = `taux d'occupation`;
// HagerQai
widgetsEN.hagerQai.quality = `quality`;
widgetsEN.hagerQai.threshold = `threshold`;
export default widgetsEN;
