import Vue from 'vue';
import api from '@/services/api';
import { errorMessageFromApiError } from '@/services/api/utils';

import { HttpMetadata } from '@/types/http';
import { ActionTree, MutationTree } from 'vuex/types';
import { ApiPaginatedResponse, Report } from '@/types/api';

export interface ReportStoreState {
  data: ApiPaginatedResponse<Report> | null,
  meta: HttpMetadata,
}

const getDefaultState = (): ReportStoreState => ({
  data: null,
  meta: {
    lastFetch: null,
    isLoading: false,
    error: null,
  },
});

const state = getDefaultState();

const getters = {};

const mutations: MutationTree<ReportStoreState> = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  set: (state, { data, meta }: Partial<ReportStoreState>) => {
    if (data !== undefined) {
      Vue.set(state, 'data', data);
    }

    if (meta !== undefined) {
      Vue.set(state, 'meta', { ...state.meta, ...meta });
    }
  },
};

const actions: ActionTree<ReportStoreState, ReportStoreState> = {
  resetState({ commit }) {
    commit('resetState');
  },
  async getReports({ commit, state }, params: { offset?: number, limit?: number }) {
    const { offset, limit } = {
      offset: 0,
      limit: 10,
      ...params,
    };

    // avoid fetching if we're already doing it
    if (state.meta.isLoading) {
      return;
    }

    // avoid re-loading if data is fresh (loaded less than 5 secs ago, with identical params)
    if (
      state.meta.lastFetch !== null &&
      (new Date().getTime() - state.meta.lastFetch.getTime() < 5000) &&
      state.meta.error === null &&
      state.data?.offset === offset &&
      state.data?.limit === limit
    ) {
      return;
    }

    commit('set', {
      meta: {
        isLoading: true,
        error: null,
      }
    });

    try {
      const fetchResult = await api.report.getReports({ offset, limit });

      commit('set', {
        data: fetchResult.data,
        meta: {
          lastFetch: new Date(),
          isLoading: false,
          error: null,
        }
      });
    } catch (err) {
      commit('set', {
        meta: {
          isLoading: false,
          error: errorMessageFromApiError(err as Error),
        }
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
