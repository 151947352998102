<template>
  <div class="comments">
    <h3 class="title">
      {{$t('pages.ticket.comments.title')}} {{ commentNumber }}
    </h3>
    <div v-if="comments.length > 0"
         ref="commentList"
         class="comments-list"
         @scroll="handleScroll">
      <span v-for="(com, idx) in comments"
            :key="idx"
            :class="commentClass(com)"
            class="comment-item">
        <span class="message">
          {{ com.message }}
        </span>
        <span class="info">
          <span class="info-author">
            {{ `${com.author.firstName} ${com.author.lastName}` }}
          </span>
          <span class="info-date">
            {{ formatTime(com.createdAt) }}
          </span>
        </span>
      </span>
    </div>
    <div v-else
         class="no-comment">
      {{$t('pages.ticket.comments.noComments')}}
    </div>
    <form v-if="canComment"
          class="send-comment"
          @submit.prevent="sendComment">
      <input v-model="currentComment"
             type="text"
             class="send-comment__input o-input">
      <div class="comment-button o-button o-button--hover o-button--shadows o-button--squared o-button--bordered"
           @click="sendComment">
        {{$t('pages.ticket.comments.send')}}
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'TicketComments',
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    ticketShortId: {
      type: String,
      default: null,
    },
    canComment: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentComment: '',
      canScrollToBottom: true,
      currentCommentNumber: 0,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    commentNumber() {
      const nb = this.comments.length;
      return nb > 0 ? `(${nb})` : '';
    },
  },
  watch: {
    comments(comments) {
      if (comments.length > this.currentCommentNumber) {
        this.currentCommentNumber = comments.length;
        this.canScrollToBottom = true;
      }
      this.scrollToBottom();
    },
  },
  methods: {
    handleScroll(evt) {
      const isScrolledToBottom = evt.target.scrollHeight - evt.target.scrollTop === evt.target.clientHeight;
      if (!isScrolledToBottom) this.canScrollToBottom = false;
    },
    async sendComment() {
      const comment = {
        message: this.currentComment,
      };
      await this.$store.dispatch('tickets/comment/create', { ticketId: this.ticketShortId, comment });
      this.currentComment = '';
      this.scrollToBottom();
    },
    commentClass(comment) {
      return {
        'comment-item--me': (!this.user && comment.authorUuid === '00000000-0000-0000-0000-A0000000000A') || (this.user && comment.authorUuid === this.user.uuid),
      };
    },
    scrollToBottom() {
      setTimeout(() => {
        if (this.canScrollToBottom && this.$refs.commentList) this.$refs.commentList.scrollTop = this.$refs.commentList.scrollHeight;
      }, 500);
    },
    formatTime(date) {
      return moment(date).format('D MMM YYYY, kk:mm');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.comments
  display flex
  flex-direction column
  border-radius $border-radius-smooth
  background-color white
  box-shadow $shadow-card
  .title
    padding $space-2
    border-bottom $grid-border
    text-transform uppercase
    font-weight 300
    font-size $fs-h1
    line-height 1
  .comments-list
    flex 1
    overflow-y auto
    padding $space-2
    max-height 200px
    .comment-item
      display flex
      flex-shrink 0
      flex-direction column
      margin-bottom 8px
      padding 4px 8px
      max-width 350px
      width fit-content
      border-radius $border-radius
      background-color lighten($grey, 70%)
      &--me
        margin-left auto
        background-color $dark
        color white
        .info
          color lighten($grey, 20%) !important
      .message
        margin-bottom 4px
        width 100%
        font-size $fs-h3
      .info
        color darken($grey, 20%)
        font-size $fs-little
        .info-author
          margin-right 8px
  .no-comment
    padding $space-2
    text-align center
    font-size $fs-h3

.send-comment
  display flex
  flex-shrink 0
  padding $space-2
  border-top $grid-border
  .send-comment__input
    flex 8
    margin-right 8px
    width 100%
  .comment-button
    display flex
    flex 1
    justify-content center
    align-items center
    text-transform uppercase
</style>
