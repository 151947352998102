<template>
  <circle :id="deviceUuid"
          :cx="x"
          :cy="y"
          :class="deviceClass"
          :r="radius"
          :style="`fill: ${color}; stroke: ${color}`"
          class="device"
          @mouseenter="hoverEnter"
          @mouseout="hoverOut" />
</template>

<script>
export default {
  name: 'DeviceDot',
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    deviceUuid: {
      type: String,
      default: 'device',
    },
    viewerMode: {
      type: String,
      default: 'deviceEdition',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: Number,
      default: 8,
    },
    color: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    deviceClass() {
      return {
        'device--selected': this.selected,
        'device--edition': this.viewerMode === 'deviceEdition',
        'device--navigation': this.viewerMode === 'navigation',
        'device--hovered': this.isHovered && this.viewerMode === 'deviceEdition',
      };
    },
  },
  methods: {
    hoverEnter() {
      this.isHovered = true;
    },
    hoverOut() {
      this.isHovered = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.device
  &--edition
    cursor pointer
    fill black
  &--navigation
    fill white
  &--selected
    fill black
  &--hovered
    fill white
    stroke-width 4px
    stroke black
  &--big
    stroke-width 30px
</style>
