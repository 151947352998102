import securedAxios from '../securedAxios';

const NodesGroup = {
  endpoint: '/api/nodes-groups',
  fetch() {
    return securedAxios.get(`${this.endpoint}`);
  },
  getNodesGroup(nodesGroupId) {
    return securedAxios.get(`${this.endpoint}/${nodesGroupId}`);
  },
  delete(nodesGroupId) {
    return securedAxios.delete(`${this.endpoint}/${nodesGroupId}`);
  },
  save(nodesGroup) {
    // update nodesGroup
    if (nodesGroup.id) {
      return securedAxios.patch(`${this.endpoint}/${nodesGroup.id}`, nodesGroup);
    }
    // create new nodesGroup
    return securedAxios.post(`${this.endpoint}`, nodesGroup);
  },
  getDataProfile(nodesGroupId) {
    return securedAxios.get(`${this.endpoint}/${nodesGroupId}/data-profile`);
  },
  fetchData(nodesGroupId, period) {
    const queryParams = `periodType=${period.periodType}&periodFrom=${encodeURIComponent(period.from)}&periodTo=${encodeURIComponent(period.to)}`;
    return securedAxios.get(`${this.endpoint}/${nodesGroupId}/data?${queryParams}`);
  }
};

export default NodesGroup;