import _ from 'lodash';
import ticket from './ticket-locale-object.json';
const ticketEN = _.cloneDeep(ticket);

// Sidebar out
ticketEN.sidebarOut.title = 'tickets';
ticketEN.sidebarOut.ticketStatus.all = 'all';
ticketEN.sidebarOut.ticketStatus.new = 'new';
ticketEN.sidebarOut.ticketStatus.todo = 'todo';
ticketEN.sidebarOut.ticketStatus.inProgress = 'in progress';
ticketEN.sidebarOut.ticketStatus.done = 'done';
ticketEN.sidebarOut.buttons.createNewTicket = 'create a new ticket';
ticketEN.sidebarOut.buttons.createTicket = 'create ticket';
ticketEN.sidebarOut.messages.noTicket = 'no ticket';
ticketEN.sidebarOut.messages.createTicketOnBuilding = 'to create a ticket select a building';
ticketEN.sidebarOut.messages.createTicketOnSpace = `you can right click on a space to create a ticket`;
// ticket info
ticketEN.ticketInfo.title = 'new ticket';
ticketEN.ticketInfo.date = 'date';
ticketEN.ticketInfo.state = 'state';
ticketEN.ticketInfo.author = 'author';
ticketEN.ticketInfo.location = 'location';
ticketEN.ticketInfo.goToTicket = 'go to ticket';
// creation / edition
ticketEN.creationEdition.createTitle = `create ticket`;
ticketEN.creationEdition.editTitle = `edit ticket`;
ticketEN.creationEdition.labels.title = `title`;
ticketEN.creationEdition.labels.scope = `scope`;
ticketEN.creationEdition.labels.type = `type`;
ticketEN.creationEdition.labels.priority = `priority`;
ticketEN.creationEdition.labels.message = `message`;
ticketEN.creationEdition.labels.documents = `documents`;
ticketEN.creationEdition.labels.addDocument = `add document`;
ticketEN.creationEdition.labels.moreActions = `more actions`;
ticketEN.creationEdition.labels.building = `building`;
ticketEN.creationEdition.labels.map = `map`;
//        scopes
ticketEN.creationEdition.scopes.dot = `dot`;
ticketEN.creationEdition.scopes.room = `room`;
ticketEN.creationEdition.scopes.floor = `floor`;
ticketEN.creationEdition.scopes.building = `building`;
//        types
ticketEN.creationEdition.types.plumbing = `plumbing`;
ticketEN.creationEdition.types.electricity = `electricity`;
ticketEN.creationEdition.types.cleaning = `cleaning`;
ticketEN.creationEdition.types.intervention = `intervention`;
ticketEN.creationEdition.types.other = `other`;
//        priorities
ticketEN.creationEdition.priorities.low = `low`;
ticketEN.creationEdition.priorities.medium = `medium`;
ticketEN.creationEdition.priorities.high = `high`;
//        buttons
ticketEN.creationEdition.buttons.create = `create`;
ticketEN.creationEdition.buttons.update = `update`;
ticketEN.creationEdition.buttons.delete = `delete`;
ticketEN.creationEdition.buttons.share = `share`;
ticketEN.creationEdition.buttons.edit = `edit`;
ticketEN.creationEdition.buttons.copy = `copy`;
ticketEN.creationEdition.buttons.cancel = `cancel`;
//        messages
ticketEN.creationEdition.messages.noDocuments = `no documents uploaded`;
// Comments
ticketEN.comments.title = 'comments';
ticketEN.comments.noComments = 'no comments';
ticketEN.comments.send = 'send';
// toasts
ticketEN.toasts.errorCreatingUpdatingTicket = 'Error when creating/updating ticket';
ticketEN.toasts.cannotDownloadFile = 'Cannot download file';
ticketEN.toasts.cannotDeleteFile = 'Cannot delete file';
ticketEN.toasts.ticketDeleted = 'Ticket successfully deleted';
ticketEN.toasts.errorDeletingTicket = 'Error when deleting ticket';
ticketEN.toasts.linkPastedClipboard = 'Link pasted to clipboard';
ticketEN.toasts.errorPastingClipboard = 'Error copying link to clipboard';
ticketEN.toasts.errorUpdatingTicketStatus = 'Error updating ticket status';

export default ticketEN;
