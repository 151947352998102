import securedAxios from '../securedAxios';

const device = {
  create({ deviceTypeId, name, uuid, spaceUuid, plugUuid, description, x, y, z }) {
    return securedAxios.post(`/api/qpark/devices/add`, {
      deviceTypeId,
      name,
      uuid,
      space: spaceUuid,
      plug: plugUuid,
      description,
      x,
      y,
    });
  },
  update(deviceUuid, positionX, positionY) {
    return securedAxios.patch(`/api/space/user-topology/node/${deviceUuid}`, {
      x: positionX,
      y: positionY,
    });
  },
  delete(deviceUuid) {
    return securedAxios.delete(`/api/qpark/devices/${deviceUuid}`);
  },
  fetch() {
    return securedAxios.get(`/api/qpark/device-plugs`);
  },
  removeAllDevices(deviceUuidList) {
    // FIXME Must be move to action
    deviceUuidList.forEach(async deviceUuid => {
      await securedAxios.removeDevice(deviceUuid);
    });
  },
  deviceTypes: {
    fetch() {
      return securedAxios.get(`/api/space/device-types`);
    },
  },
  data: {
    read({ deviceUuid, from, to }) {
      return securedAxios.post(`/api/data`, { deviceUuid, from, to });
    },
  },
};

export default device;
