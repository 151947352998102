import { render, staticRenderFns } from "./ReportConfigEdit.vue?vue&type=template&id=310a58da&scoped=true&"
import script from "./ReportConfigEdit.vue?vue&type=script&lang=ts&"
export * from "./ReportConfigEdit.vue?vue&type=script&lang=ts&"
import style0 from "./ReportConfigEdit.vue?vue&type=style&index=0&id=310a58da&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310a58da",
  null
  
)

export default component.exports