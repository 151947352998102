






























import Vue from 'vue';
import { errorMessageFromApiError } from '@/services/api/utils';
import { ApiDevice } from '@/types/api';
import { HttpMetadata, HttpMetadataDefaults } from '@/types/http';
import FetchableData from '@/app/components/ui/FetchableData.vue';
import Device from './components/Device.vue';

import api from '@/services/api';

export default Vue.extend({
  name: 'DevicesDevice',
  components: {
    FetchableData,
    Device,
  },
  props: {
    deviceUuid: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    device: {
      data: null as ApiDevice | null,
      meta: { ...HttpMetadataDefaults } as HttpMetadata,
    },
  }),
  computed: {
    historyStackIsEmpty() {
      return window.history.length <= 2;
    },
  },
  async created() {
    await this.fetchDevice();
  },
  methods: {
    async fetchDevice() {
      if (this.device.meta.isLoading) {
        return;
      }
      this.device.meta.isLoading = true;
      this.device.meta.error = null;

      try {
        this.device.data = (await api.oasisDevices.getDeviceByUuid(this.deviceUuid)).data;
        this.device.meta.lastFetch = new Date();
      } catch (err) {
        this.device.meta.error = errorMessageFromApiError(err as Error);
      }

      this.device.meta.isLoading = false;
    },
  }
});
