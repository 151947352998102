export default {
  methods: {
    imageExist(imagePath) {
      const image = new Image();
      image.src = imagePath;
      return image.width !== 0;
    },
    objectIsEmpty(obj) {
      for (const key in obj) if (obj.hasOwnProperty(key)) return false;
      return true;
    },
    random(min, max) {
      return Math.round(Math.random() * (max - min) + min);
    },
    generateRandomUUID: () => {
      let firstPart = (Math.random() * 46656) | 0;
      let secondPart = (Math.random() * 46656) | 0;
      firstPart = `000${firstPart.toString(36)}`.slice(-3);
      secondPart = `000${secondPart.toString(36)}`.slice(-3);
      return firstPart + secondPart;
    },
    formatData(data, precision = 2, unit) {
      if (data === null || data === undefined) return this.$t('components.commons.noData');
      if (typeof data === 'number') return `${data.toFixed(precision)}${unit ? unit : ''}`;
      return data;
    },
  },
};
