<template>
  <Graph :chartData="chartData"
         :options="options"
         :height="height"
         :width="width" />
</template>

<script>
import Graph from './line-graph.vue';
import ColorsMixin from '@/app/mixins/colors';

export default {
  name: 'GraphDevice',
  components: {
    Graph,
  },
  mixins: [ColorsMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
    unit: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 250,
    },
    width: {
      type: Number,
      default: 400,
    }
  },
  data() {
    return {
      options: {
        animation: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              maxBarThickness: 40,
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: value => {
                  return `${value} ${this.unit}`;
                },
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: '#464749',
          titleFontColor: 'white',
          bodyFontColor: 'white',
          displayColors: false,
          mode: 'index',
          intersect: false,
          callbacks: {
            label: item => {
              return `${item.yLabel.toFixed(2)} ${this.unit ? this.unit : ''}`;
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.values,
            // line
            lineTension: 0.1,
            borderWidth: 0, // hide the line
            // area under the line
            fill: true,
            backgroundColor: this.colors.primary,
            // Line dot
            radius: 0, // hide dots on the line
            pointHitRadius: 10,
            pointBackgroundColor: 'black',
          },
        ],
      };
    },
  },
};
</script>
