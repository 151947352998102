import Vue from 'vue';
import api from 'api';
const getDefaultState = () => ({
  collection: [],
  fetching: false,
});
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    setCollection: (state, collection) => Vue.set(state, 'collection', collection),
    setFetchState: (state, fetchState) => (state.fetching = fetchState),
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    fetch: async ({ commit }) => {
      commit('setFetchState', true);
      let { data: profiles } = await api.profiles.fetch();
      profiles = profiles.map(p => ({ value: p.id, label: p.name, unit: p.unit }));
      commit('setCollection', profiles);
      commit('setFetchState', false);
    },
  },
};
