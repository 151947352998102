import _ from 'lodash';
import notifications from './notification-locale-object.json';
const notificationFR = _.cloneDeep(notifications);

// pop-up
notificationFR.popUp.noUnread = "Vous n'avez aucune notification non lue";
notificationFR.popUp.noNotification = "Vous n'avez aucune notification";
notificationFR.popUp.center = 'Centre de notification';
notificationFR.popUp.seeMore = 'Voir plus';
// actionLink
notificationFR.actionLink.view = 'voir';
notificationFR.actionLink.error = 'lien introuvable';

export default notificationFR;