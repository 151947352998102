import Vue from 'vue';
import api from 'api';

import Zone from 'models/topology/zone';

// Submodules
import properties from '@/services/store/modules/zones/properties';
const getDefaultState = () => ({
  collection: {},
  fetching: false,
  selectedZone: null,
});
export default {
  namespaced: true,
  modules: {
    properties,
  },
  state: getDefaultState(),
  getters: {
    getFloorFromParcel: (state, getters, rootState, rootGetters) => parcel => {
      if (!parcel.spaces.length || !parcel.building) return null;
      const building = rootState.building.collection.find(b => b.uuid === parcel.building);
      if (!building) return;
      return building.next.find(f => {
        const space = f.next.find(s => s.uuid === parcel.spaces[0]);
        return space ? true : false;
      });
    },
    getZoneBySpaceUuid(state, getters, rootState) {
      if (!rootState.building.selectedBuilding) return;
      return spaceIdIFC => {
        const curBuild = rootState.building.selectedBuilding.uuid;
        if (!(curBuild in state.collection) || !state.collection[curBuild].length) return undefined;
        return state.collection[curBuild].find(z => {
          return z.spaces.find(s => s === spaceIdIFC);
        });
      };
    },
    spaceIsAlreadyInZone(state, getters, rootState) {
      if (!rootState.building.selectedBuilding) return;
      return spaceIdIFC => {
        const find = state.collection[rootState.building.selectedBuilding.uuid].find(z => {
          const spaceExist = z.spaces.find(s => s === spaceIdIFC);
          return spaceExist ? true : false;
        });
        return find ? true : false;
      };
    },
    getSpaceZone(state, getters, rootState) {
      if (!rootState.building.selectedBuilding) return;
      return spaceIdIFC => {
        const curBuild = rootState.building.selectedBuilding.uuid;
        if (!(curBuild in state.collection) || !state.collection[curBuild].length) return undefined;
        return state.collection[curBuild].find(z => {
          return z.spaces.find(s => s === spaceIdIFC);
        });
      };
    },
    getCurrentBuildingZonesSpaceCount(state, getters, rootState) {
      if (!rootState.building.selectedBuilding) return;
      let nb = 0;
      const curBuild = rootState.building.selectedBuilding.uuid;
      if (state.collection[curBuild])
        state.collection[curBuild].forEach(z => {
          nb += z.spaces.length;
        });
      return nb;
    },
  },
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    collection: (state, zones) => Vue.set(state, 'collection', zones),
    setFetchingState: (state, fetchState) => (state.fetching = fetchState),
    selectZone: (state, zone) => (state.selectedZone = zone),
    create: (state, { buildingUuid, zone }) => {
      if (!state.collection[buildingUuid]) Vue.set(state.collection, buildingUuid, []);
      Vue.set(state.collection[buildingUuid], state.collection[buildingUuid].length, zone);
    },
    update: (state, { buildingUuid, zone }) => {
      const currentZone = state.collection[buildingUuid].find(z => z.uuid === zone.uuid);
      if (!currentZone) return;
      Object.keys(zone).forEach(k => (currentZone[k] = zone[k]));
    },
    delete: (state, { buildingUuid, zoneUuid }) => {
      const idx = state.collection[buildingUuid].findIndex(zone => zone.uuid === zoneUuid);
      if (idx > -1) state.collection[buildingUuid].splice(idx, 1);
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    fetch: ({ commit, rootState }) => {
      commit('setFetchingState', true);
      rootState.building.collection.forEach(async building => {
        const { data: parcels } = await api.zones.read(building.uuid);
        parcels.forEach(z => {
          commit('create', {
            buildingUuid: building.uuid,
            zone: new Zone(z),
          });
        });
      });
      commit('setFetchingState', false);
    },
    create: async ({ commit }, zone) => {
      try {
        const { data: createdZone } = await api.zones.create({
          name: zone.name,
          spaceList: zone.spaces,
          type: zone.type,
          metadata: {
            color: zone.color,
          },
        });
        return createdZone.uuid;
      } catch (error) {
        throw error;
      }
    },
    update: async ({ commit }, { buildingUuid, zone }) => {
      try {
        if (zone.name && zone.type && zone.metadata) await api.zones.update(zone.uuid, zone);
        if (zone.spaces) await api.zones.spaceList.update(zone.uuid, zone.spaces);
        commit('update', { buildingUuid, zone });
      } catch (error) {
        throw error;
      }
    },
    delete: async ({ commit }, { buildingUuid, zoneUuid }) => {
      try {
        await api.zones.delete(zoneUuid);
        commit('delete', { buildingUuid, zoneUuid });
      } catch (error) {
        throw error;
      }
    },
  },
};
