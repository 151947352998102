import _ from 'lodash';
import report from './report-locale-object.json';
const reportFR = _.cloneDeep(report);

// period range selector
reportFR.periodRangeSelector.labels['D'] = 'Jour';
reportFR.periodRangeSelector.labels['W'] = 'Semaine';
reportFR.periodRangeSelector.labels['M'] = 'Mois';
reportFR.periodRangeSelector.labels['Y'] = 'Année';

// center
reportFR.center.noReports = 'Aucun rapport';

// list
reportFR.list.name = 'Nom';
reportFR.list.frequency = 'Fréquence';
reportFR.list.date = 'Date';
reportFR.list.download = 'Télécharger';
reportFR.list.enabled = 'Activé';
reportFR.list.details = 'Détails';

export default reportFR;