export default class DashBoardWidget {
  description;
  name;
  template;
  title;
  type;
  unit;
  w;
  h;
  intervalRefresh;
  constructor(rawData = {}) {
    this.description = rawData.description;
    this.name = rawData.name;
    this.template = rawData.template;
    this.title = rawData.title;
    this.type = rawData.type;
    this.unit = rawData.unit;
    this.w = rawData.w;
    this.h = rawData.h;
    this.intervalRefresh = rawData.intervalRefresh;
  }
}
