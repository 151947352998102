import _ from 'lodash';
import dashboard from './dashboard-locale.json';
const dashboardEN = _.cloneDeep(dashboard);

// new dashbaord
dashboardEN.newDashboard.name = 'new dashboard';
// options
dashboardEN.options.title = 'options';
dashboardEN.options.editName = 'edit name';
dashboardEN.options.deleteDashboard = 'delete dashboard';
dashboardEN.options.done = 'done';
dashboardEN.options.selectDashboard = 'Select a dashboard to display';
// widget creation
dashboardEN.widgetCreation.button = 'Create new widget';
// toasts
dashboardEN.toasts.dashboardCreated = 'Dashboard successfully created';
dashboardEN.toasts.errorDashboardCreation = 'Error on dashboard creation';
dashboardEN.toasts.dashboardUpdated = 'Dashboard successfully updated';
dashboardEN.toasts.errorDashboardUpdate = 'Error on dashboard update';
dashboardEN.toasts.dashboardDeleted = 'Dashboard successfully deleted';
dashboardEN.toasts.errorDashboardDeleted = 'Error on dashboard deletion';
dashboardEN.toasts.widgetAddedToDashboard = 'Widget successfully added to dashboard';
dashboardEN.toasts.errorAddingWidgetToDashboard = 'Error when adding widget';
// pop-up
dashboardEN.popUp.create = 'Create new Dashboard';
dashboardEN.popUp.noDashboard = 'You have no dashboard, you can create one in the dashboard menu';
dashboardEN.popUp.requestDashboard = 'You do not have access to any dashboard, you can request access from your administrator';
// add dashboard
dashboardEN.addDashboard.dashboardName = 'dashboard name';
dashboardEN.addDashboard.enterDashboardName = 'Enter dashboard name';
dashboardEN.addDashboard.emptyDashboardName = 'You must enter a name, it cannot be empty';
dashboardEN.addDashboard.dashboardNameExist = 'You already have a dashboard named ';
dashboardEN.addDashboard.createDashboard = 'Create dashboard';

export default dashboardEN;
