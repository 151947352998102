export default class Node {
  uuid;
  name;
  nodeType;
  constructor(rawData = {}, parent = null) {
    this.uuid = rawData.uuid;
    this.name = rawData.name;
    this.nodeType = rawData.nodeType;
    if (parent) this.setPath(parent.path, parent.uuid);
  }

  setPath(parentPath, parentUuid) {
    this.path = parentPath ? [...parentPath] : [];
    this.path.push(parentUuid);
  }
}
