import { render, staticRenderFns } from "./alert-rule-details-layout.vue?vue&type=template&id=cfa559ac&scoped=true&"
import script from "./alert-rule-details-layout.vue?vue&type=script&lang=js&"
export * from "./alert-rule-details-layout.vue?vue&type=script&lang=js&"
import style0 from "./alert-rule-details-layout.vue?vue&type=style&index=0&id=cfa559ac&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa559ac",
  null
  
)

export default component.exports