import Node from 'models/topology/node';
import Space from 'models/topology/space';
import Parcel from 'models/topology/parcel';

export default class Floor extends Node {
  level;
  metadata = {};
  whitelist = [];
  constructor(rawData = {}, parent = null) {
    super(rawData, parent);
    this.metadata = rawData.metadata;
    this.level = rawData.level;
    this.whitelist = rawData.whitelist;
    if (rawData.next) this.next = rawData.next.map(s =>  {
      if (s.nodeType === 'premise')
        return new Parcel(s, this);
      else
        return new Space(s, this);
    });
  }
}
