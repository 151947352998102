import App from './App.vue';
import Vue from 'vue';

import store from './services/store/index';
import router from './services/router/index';

import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import { VueHammer } from 'vue2-hammer';
import VueClipboard from 'vue-clipboard2';
import moment from 'moment';
import i18n from '@/services/i18n';

// Our vue plugins
import Palmier from './services/utils/palmier';

// Quasar
import './styles/quasar.styl';
import 'quasar-framework/dist/quasar.ie.polyfills';
import lang from 'quasar-framework/i18n/fr';
import 'quasar-extras/animate';
import 'quasar-extras/roboto-font';
import 'quasar-extras/material-icons';
import 'quasar-extras/fontawesome';
import 'quasar-extras/ionicons';
import 'quasar-extras/mdi';
import Quasar from 'quasar';
const quasarOptions = {
  config: {},
  i18n: lang,
};

Vue.use(Quasar, quasarOptions);
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(Palmier);
Vue.use(VueHammer);
Vue.use(VueClipboard);

// filter to capitalize the first letter of a string ('hello world !' -> Hello world !)
Vue.filter('capitalize', function (value?: string) {
  if (!value) {
    return '';
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

moment.locale(i18n.locale);
// performance tracing
// Vue.config.performance = true;

Vue.config.devtools = true;
Vue.config.warnHandler = function(msg, vm, trace) {};

new Vue({
  el: '#app',
  store: store,
  router: router,
  i18n,
  render: h => h(App),
});
