import Vue from 'vue';
import Vuex, { Module } from 'vuex';

export interface RootStoreState {}

// MODULES
import countries from '@/services/store/modules/countries';
import selections from '@/services/store/modules/selections';
import user from '@/services/store/modules/user';
import building from '@/services/store/modules/buildings';
import topology from '@/services/store/modules/topology';
import oapp from '@/services/store/modules/oapps';
import dashboard from '@/services/store/modules/dashboards';
import zones from '@/services/store/modules/zones';
import tickets from '@/services/store/modules/tickets';
import oql from '@/services/store/modules/oql';
import profiles from '@/services/store/modules/profiles';
import global from '@/services/store/modules/global';
import admin from '@/services/store/modules/admin';
import notification from '@/services/store/modules/notifications';
import alert from '@/services/store/modules/alerts';
import alertRule from '@/services/store/modules/alertRules';
import nodesGroup from '@/services/store/modules/nodesGroups';
import report from '@/services/store/modules/report';
import oasisDevices from '@/services/store/modules/oasisDevices';
// OLD
import ui from '@/services/store/modules/old/ui';
import sum from '@/services/store/modules/sums';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    countries,
    selections,
    building,
    topology,
    oapp,
    dashboard,
    user,
    zones,
    tickets,
    sum,
    oql,
    profiles,
    global,
    admin,
    notification,
    alert,
    alertRule,
    nodesGroup,
    report,
    oasisDevices,
    //old
    ui,
  },
});

export default store;
