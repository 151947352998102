import api from 'api';
import Vue from 'vue';
const getDefaultState = () => ({
  collection: [],
  install: {
    active: false,
    step: 0,
  },
  currentSumId: '',
});
const state = getDefaultState();

const getters = {
  currentSum: state => {
    return state.collection.find(sum => sum.id === state.currentSumId);
  },
};

const mutations = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  setCollection: (state, collection) => Vue.set(state, 'collection', collection),
  setCurrentSumId(state, sumId) {
    state.currentSumId = sumId;
  },
  addSum(state, { sum }) {
    state.collection.push(sum);
  },
  deleteSum(state, sumId) {
    const sumIndex = state.collection.findIndex(sum => sum.id === sumId);
    if (sumIndex < 0) return;
    state.collection.splice(sumIndex, 1);
  },
  patchSum(state, sum) {
    state.collection = state.collection.map(sumFromState => (sumFromState.id === sum.id ? sum : sumFromState));
  },
};

const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  async fetchSums({ commit }) {
    commit('setCollection', (await api.sums.fetch()).data);
  },
  async addSum({ commit }, { name, nodeUuid, sumItems }) {
    const { data: sum } = await api.sums.create(name, nodeUuid, sumItems);
    commit('addSum', { sum });
  },
  async deleteSum({ commit }, sumId) {
    await api.sums.delete(sumId);
    commit('deleteSum', sumId);
  },
  async patchSum({ commit }, { sumId, name }) {
    const response = await api.sums.update(sumId, name);
    commit('patchSum', response.data);
  },
  async patchSumItem({ commit }, { sumId, sumItemId, operator }) {
    const response = await api.sums.sumItems.update(sumId, sumItemId, operator);
    commit('patchSum', response.data);
  },
  async deleteSumItem({ commit }, { sumId, sumItemId }) {
    const response = await api.sums.sumItems.delete(sumId, sumItemId);
    commit('patchSum', response.data);
  },
  async addSumItem({ commit }, { sumId, deviceUuid, profileId, operator }) {
    const response = await api.sums.sumItems.create(sumId, deviceUuid, profileId, operator);
    commit('PATCH_SUM', response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
