const urlBase = process.env.BASE_URL || 'https://oasis.qarnot.com/app/';

const config = {
  i18n: {
    locale: process.env.I18N_DEFAULT_LOCALE || 'fr',
    fallbackLocale: process.env.I18N_FALLBACK_LOCALE || 'en',
  },
  api: {
    baseURL: process.env.API_BASE_URL || '',
  },
  gtc: {
    baseURLProd: process.env.GTC_BASE_URL || '/gtc',
  },
  test: {
    login: {
      email: process.env.TEST_E2E_LOGIN_EMAIL || 'xxx',
      pwd: process.env.TEST_E2E_LOGIN_PASSWORD || 'xxx',
    },
    url: {
      login: process.env.TEST_E2E_URL_LOGIN || `${urlBase}login`,
      ticket: process.env.TEST_E2E_URL_TICKET || `${urlBase}ticket`,
      store: process.env.TEST_E2E_URL_STORE || `${urlBase}store`,
      data: process.env.TEST_E2E_URL_DATA || `${urlBase}data`,
      dashboard: process.env.TEST_E2E_URL_DASHBOARD || `${urlBase}dashboard`,
      config: process.env.TEST_E2E_URL_CONFIGURATION || `${urlBase}configuration`,
    },
  },
};

export default config;

