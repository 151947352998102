import DashboardWidget from './dashboardWidget';
export default class Dashboard {
  id;
  name;
  position;
  widgets = [];
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.name = rawData.name;
    this.position = rawData.position;
    this.dashboardWidgets = rawData.dashboardWidgets.map(widget => new DashboardWidget(widget));
  }
}
