











































































import Vue from 'vue';

import api from '@/services/api';

import { HttpMetadata, HttpMetadataDefaults } from '@/types/http';
import FetchableData from '@/app/components/ui/FetchableData.vue';
import ReportList from './components/ReportList.vue';
import { NodesGroup, Report, ReportConfig } from '@/types/api';
import ReportingDashboardHistogram from '@/app/pages/report/components/reporting-dashboard-histogram.vue';
import _groupBy from 'lodash/groupBy';
import { Period } from './components/period-range-selector.vue';

export default Vue.extend({
  name: 'ReportConfigView',
  components: {
    FetchableData,
    ReportList,
    ReportingDashboardHistogram
  },
  props: {
    reportConfigId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    reportConfig: {
      data: null as ReportConfig | null,
      meta: { ...HttpMetadataDefaults } as HttpMetadata,
    },
    isGeneratingReport: false,
    startDateSelected: null as string | null,
    endDateSelected: null as string | null,
    periodTypeSelected: null as string | null,
  }),
  computed: {
    historyStackIsEmpty() {
      return window.history.length <= 2;
    },
    featuresRights() {
      return this.$store.state.user.user.featuresRights;
    },
    frequencyList(): string {
      if (!this.reportConfig.data) {
        return '';
      }
      const a = [];
      if (this.reportConfig.data.daily) {
        a.push('Quotidien');
      }
      if (this.reportConfig.data.weekly) {
        a.push('Hebdomadaire');
      }
      if (this.reportConfig.data.monthly) {
        a.push('Mensuel');
      }
      if (this.reportConfig.data.yearly) {
        a.push('Annuel');
      }
      return a.join(', ');
    },
    isEnabled(): boolean {
      return this.reportConfig.data?.enabled === true || false;
    },
    reportsForReportList(): Report[] {
      if (!this.reportConfig.data?.reports) {
        return [];
      }
      return this.reportConfig.data?.reports.map((r) => ({
        ...r,
        reportConfig: { title: this.reportConfig.data?.title } as ReportConfig
      }));
    },
    // a key is needed for the v-for, I arbitrarly used the dataProfileId, which is guaranteed to be unique and not changing
    nodesGroupByDataProfile(): { key: number, nodesGroups: NodesGroup[] }[] {
      if (!this.reportConfig.data?.nodesGroups) {
        return [];
      }
      const nodesGroups = this.reportConfig.data.nodesGroups as NodesGroup[];
      const groupByDataProfile = _groupBy(nodesGroups, (result) => result.dataProfileId);
      return Object.values(groupByDataProfile).map(
        nodesGroups => ({
          key: nodesGroups[0]?.dataProfileId,
          nodesGroups,
        })
      );
    }
  },
  async created() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      this.reportConfig.meta = {
        ...this.reportConfig.meta,
        isLoading: true,
        error: null,
      };
      try {
        this.reportConfig.data = (await api.reportConfig.getById(this.reportConfigId)).data;
        this.reportConfig.meta.lastFetch = new Date();
      } catch (err: any) {
        this.reportConfig.meta.error = err.message || this.$t('global.errors.generic');
      }
      this.reportConfig.meta.isLoading = false;
    },
    async generateReport() {
      this.isGeneratingReport = true;
      try {
        await api.report.generateReport(this.reportConfigId, { startDateTime: this.startDateSelected, endDateTime: this.endDateSelected });
        await this.fetch();
      } catch {
        // FIXME what
      }
      this.isGeneratingReport = false;
    },
    inputPeriod(periodSelected: Period) {
      this.startDateSelected = periodSelected.from;
      this.endDateSelected = periodSelected.to;
      this.periodTypeSelected = periodSelected.periodType;
    }
  }
});
