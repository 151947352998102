import Space from 'models/topology/space';
import Node from 'models/topology/node';

export default class Parcel extends Node {
  type;
  metadata = {};
  constructor(rawData = {}, parent = null) {
    super(rawData, parent);
    this.description = rawData.description;
    this.type = rawData.type;
    if (rawData.next) this.next = rawData.next.map(device => new Space(device, this));
  }
}
