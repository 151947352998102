









import Vue, { PropType } from 'vue';
import Bar from './bar.vue';
import ColorsMixin from '@/app/mixins/colors';

export type BarGraphData = Array<{label: string; values: number[]}>;

export default Vue.extend({
  name: 'BarGraph',
  components: {
    Bar
  },
  mixins: [ColorsMixin],
  props: {
    data: {
      type: Object as PropType<BarGraphData>,
      required: true,
    },
    unit: {
      type: String,
      default: '',
    },
    labels: {
      type: Array as PropType<Array<string>>,
      required: true,
    }
  },
  data() {
    return {
      options: {
        legend: {
          display: true,
          position: 'bottom',
        },
        responsive: true,
        scales: {
          xAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
            },
          }],
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
              callback: (value: any) => {
                return `${value} ${this.unit}`;
              },
            },
            gridLines: {
              display: true,
            },
          }]
        },
        tooltips: {
          backgroundColor: '#464749',
          titleFontColor: 'white',
          bodyFontColor: 'white',
          intersect: false,
          callbacks: {
            label: (context: { datasetIndex: number}) => {
              return this.data[context.datasetIndex].label;
            },
            title: (item: { value: string }[]) => {
              return `${parseInt(item[0].value).toFixed(2)} ${this.unit ? this.unit : ''}`;
            }
          },
        },
      }
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.data.map((dt, index) => {
          return {
            label: dt.label,
            backgroundColor: this.colors.bar[index % this.colors.bar.length],
            data: dt.values,
          };
        }),
      };
    },
  },
  methods: {
    ...ColorsMixin.data(),
  }
});
