import _ from 'lodash';
import baseObject from './nodesGroup-locale-object.json';
const nodesGroupEN = _.cloneDeep(baseObject);

// buttons
nodesGroupEN.buttons.createNewNodesGroup = 'Create a new nodes group';
nodesGroupEN.buttons.return = 'return';
nodesGroupEN.buttons.save = 'save';

// toasts
nodesGroupEN.toasts.saved = 'Nodes group saved';

// errors
nodesGroupEN.errors.noGroups = 'No nodes group';
nodesGroupEN.errors.unknowError = 'Unknown nodes group';

// labels
nodesGroupEN.labels.name = 'Name';
nodesGroupEN.labels.description = 'Description';
nodesGroupEN.labels.nodes = 'List of nodes';
nodesGroupEN.labels.profile = 'data profile';

export default nodesGroupEN;