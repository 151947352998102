






import Vue, { PropType } from 'vue';
import LineGraph from './line-graph.vue';
import ColorsMixin from '@/app/mixins/colors';

export type LineGraphData = Array<{label: string; values: number[]}>;

export default Vue.extend({
  name: 'LineHistogramGraph',
  components: {
    LineGraph
  },
  mixins: [ColorsMixin],
  props: {
    data: {
      type: Object as PropType<LineGraphData>,
      required: true,
    },
    unit: {
      type: String,
      default: '',
    },
    labels: {
      type: Array as PropType<Array<string>>,
      required: true,
    }
  },
  data() {
    return {
      options: {
        legend: {
          display: true,
          position: 'bottom',
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              margin: 10,
              gridLines: {
                display: false,
                drawBorder: true,
                tickMarkLength: 10,
              },
              ticks: {
                beginAtZero:false,
                callback: (value: number) => {
                  return `${value} ${this.unit}`;
                },
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: 'black',
          titleFontColor: 'white',
          bodyFontColor: 'white',
          displayColors: true,
          mode: 'index',
          intersect: false,
          position: 'nearest',
          callbacks: {
            label: (context: { value: string}) => {
              return `${parseInt(context.value).toFixed(2)} ${this.unit ? this.unit : ''}`;
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.data.map((data, index) => ({
          label: data.label,
          fill: false,
          borderColor: this.colors.bar[index % this.colors.bar.length],
          data: data.values,
        }))
      };
    },
  },
  methods: {
    ...ColorsMixin.data(),
  }
});
