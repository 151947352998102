import _ from 'lodash';
import report from './report-locale-object.json';
const reportEN = _.cloneDeep(report);

// period range selector
reportEN.periodRangeSelector.labels['D'] = 'Day';
reportEN.periodRangeSelector.labels['W'] = 'Week';
reportEN.periodRangeSelector.labels['M'] = 'Month';
reportEN.periodRangeSelector.labels['Y'] = 'Year';

// center
reportEN.center.noReports = 'No reports';

// list
reportEN.list.name = 'Name';
reportEN.list.frequency = 'Frequency';
reportEN.list.date = 'Date';
reportEN.list.download = 'Download';
reportEN.list.enabled = 'Enabled';
reportEN.list.details = 'Details';

export default reportEN;