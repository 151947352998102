import securedAxios from '../securedAxios';

const Oql = {
  endpoint: '/api/oql',
  sourceCancel: null,
  query(query) {
    /*if (this.sourceCancel) this.sourceCancel.cancel();
    this.sourceCancel = axios.CancelToken.source();*/
    return securedAxios.post(`${this.endpoint}`, query);
  },
};

export default Oql;
