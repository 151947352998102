import api from 'api';
import User from 'models/user';

const getDefaultState = () => ({
  user: null,
});
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    defaultPage: state => state.user.defaultPage,
    featuresRights: state => state.user.featuresRights,
  },
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    setUser: (state, user) => {
      state.user = user;
    },
  },
  actions: {
    login: async ({ commit }, credentials) => {
      try {
        const { data: user } = await api.user.login(credentials);
        commit('setUser', new User(user));
      } catch (error) {
        throw error;
      }
    },
    logout: async ({ commit, dispatch }) => {
      try {
        await api.user.logout();
        commit('resetState');
        dispatch('ui/resetState', null, { root: true});
        dispatch('zones/resetState', null, { root: true});
        dispatch('zones/properties/resetState', null, { root: true});
        dispatch('building/resetState', null, { root: true});
        dispatch('countries/resetState', null, { root: true});
        dispatch('selections/resetState', null, { root: true});
        dispatch('topology/resetState', null, { root: true});
        dispatch('oapp/resetState', null, { root: true});
        dispatch('dashboard/resetState', null, { root: true});
        dispatch('tickets/resetState', null, { root: true});
        dispatch('sum/resetState', null, { root: true});
        dispatch('oql/resetState', null, { root: true});
        dispatch('alert/resetState', null, { root: true});
        dispatch('notification/resetState', null, { root: true});
      } catch (error) {
        throw error;
      }
    },
    logged: async ({ commit }) => {
      try {
        const { data: user } = await api.user.isLogged();
        commit('setUser', new User(user));
      } catch (error) {
        throw error;
      }
    },
  },
};
