import securedAxios from '../securedAxios';

const endpoint =  '/api/space';
const Space = {
  fetch() {
    return securedAxios.get(`${endpoint}`);
  },
  upload(name, data, cancel, onUploadProgress) {
    return securedAxios.post(`${endpoint}?name=${name}`, data, {
      cancelToken: cancel.token,
      onUploadProgress: onUploadProgress,
    });
  },
  create(data) {
    return securedAxios.post(`${endpoint}/building`, data);
  },
  delete(buildingUuid) {
    return securedAxios.delete(`${endpoint}/building/${buildingUuid}`);
  },
  preferences: {
    update(buildingUUID, preferences) {
      return securedAxios.patch(`${endpoint}/preferences/${buildingUUID}`, preferences);
    },
  }
};

export default Space;
