<template>
  <q-page-container>
    <div v-if="alertRuleMeta.isLoading"
         class="message">
        LOADING...
    </div>
    <div v-else-if="alertRuleMeta.error"
         class="message">
      {{ alertRuleMeta.error }}
    </div>
    <div v-else
         class="alert-rule-main">

      <div class="alert-rule-form">

        <!-- title -->
        <div class="alert-rule-title grid-wrapper">
          <label class="o-label">
            {{$t('pages.alertRule.creationEdition.labels.title')}}
          </label>
          <input v-model="alertRule.title"
                class="o-input"
                type="text"
                :disabled="!alertRuleRight.WRITE" >
        </div>

        <!-- description -->
        <div class="alert-rule-description grid-wrapper">
          <label class="o-label">{{$t('pages.alertRule.creationEdition.labels.description')}}</label>
          <div class="grid-tile__value">
            <textarea v-model="alertRule.description"
                      class="o-textarea"
                      rows="3"
                      :disabled="!alertRuleRight.WRITE" />
            </div>
        </div>

        <!-- type and profile -->
        <div class="alert-rule-type-profile grid-wrapper">
          <!-- type -->
          <div class="grid-tile">
            <label class="o-label">{{$t('pages.alertRule.creationEdition.labels.type')}}</label>
            <div class="grid-tile__value">
              <q-select v-model="alertRule.type"
                      :options="types"
                      class="select"
                      invertedLight
                      color="white"
                      :disabled="!alertRuleRight.WRITE" />
              </div>
          </div>
          <!-- profile -->
          <div class="profile-tile grid-tile">
            <label class="o-label">{{$t('pages.alertRule.creationEdition.labels.profile')}}</label>
            <div class="grid-tile__value">
              <q-select v-model="alertRule.selector.dataProfileId"
                      :options="dataProfiles"
                      class="select"
                      invertedLight
                      color="white"
                      :disabled="!alertRuleRight.WRITE" />
            </div>
          </div>
        </div>

        <!-- config -->
        <div class="alert-rule-config grid-wrapper">
          <label class="o-label">{{$t('pages.alertRule.creationEdition.labels.config')}}</label>
          <div class="grid-tile__value">
            <textarea v-model="config"
                      class="o-textarea"
                      rows="4"
                      :disabled="!alertRuleRight.WRITE" />
            </div>
        </div>

        <!-- nodeList and receivers -->
        <div class="alert-rule-nodelist-receivers grid-wrapper">
          <!-- nodeList -->
          <div class="grid-tile">
            <label class="o-label">{{$t('pages.alertRule.creationEdition.labels.nodeList')}}</label>
            <div class="grid-tile__value">
              <NodesSelector v-model="alertRule.selector.nodeList"
                            :oldNodeList="oldNodeList"
                            :disabled="!alertRuleRight.WRITE"
                            @input="nodesSelectorUpdated">
              </NodesSelector>
            </div>
          </div>
          <!-- receivers -->
          <div class="receivers-tile grid-tile">
            <label class="o-label">{{$t('pages.alertRule.creationEdition.labels.receivers')}}</label>
            <div class="grid-tile__value">
              <TreeSelector v-model="receiversSelected"
                            :initialSelection="initialReceivers"
                            :availableItems="receiversAvailable"
                            :hint="$t('pages.alertRule.creationEdition.labels.selectReceivers')"
                            :disabled="!alertRuleRight.WRITE">
              </TreeSelector>
            </div>
          </div>
        </div>

        <!-- trigger/resolve -->
        <div class="grid-wrapper trigger-resolve">
        <!-- selector.trigger -->
        <div class="grid-tile">
          <label class="o-label">{{$t('pages.alertRule.creationEdition.labels.trigger')}}</label>
          <div class="grid-tile__value">
            <textarea v-model="trigger"
                      class="o-textarea"
                      rows="6"
                      :disabled="!alertRuleRight.WRITE" />
            </div>
        </div>
        <!-- selector.resolve -->
        <div class="priority-resolve grid-tile">
          <label class="o-label">{{$t('pages.alertRule.creationEdition.labels.resolve')}}</label>
          <div class="grid-tile__value">
            <textarea v-model="resolve"
                      class="o-textarea"
                      rows="6"
                      :disabled="!alertRuleRight.WRITE" />
            </div>
        </div>
        </div>

        <!-- full selector preview -->
        <div class="alert-rule-selector grid-wrapper">
          <label class="o-label">{{$t('pages.alertRule.creationEdition.labels.selector')}}</label>
          <div class="grid-tile__value">
            <textarea v-if="manualMode && alertRuleRight.WRITE"
                      v-model="selectorManual"
                      class="o-textarea"
                      rows="18"
                      />

            <pre v-else
                 class='json'
                 @click='toggleManualMode'
                 >{{ JSON.stringify(alertRule.selector, null, 4) }}</pre>
          </div>
        </div>

        <!-- enabled -->
        <div class="alert-rule-enabled grid-wrapper">
          <q-toggle v-model="alertRule.enabled"
                    uncheckedIcon="notifications_off"
                    checkedIcon="notifications"
                    class="capitalize"
                    color="green-6"
                    :label="$t('pages.alertRule.creationEdition.labels.enabled')"
                    leftLabel
                     />
        </div>

        <!-- update button  -->
        <div v-if="alertRuleRight.WRITE"
              class="alert-rule-update-submit grid-wrapper">
          <button :class="createButtonClass"
              class="form-button o-button o-button--shadows o-button--hover o-button--squared o-button--primary-gradient"
              :disabled="saveButtonDisabled"
              @click="save()">
            {{$t('pages.alertRule.creationEdition.labels.save')}}
          </button>
        </div>
      </div>
    </div>
  </q-page-container>
</template>

<script>
import api from 'api';
import NodesSelector from '@/app/components/topology/nodes-selector.vue';
import TreeSelector from '@/app/components/inputs/tree-selector.vue';

export default {
  name: 'AlertRuleDetailEditor',
  components: { NodesSelector, TreeSelector },
  props: {
    alertRuleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      alertRule: {},
      receiversAvailable: [],
      receiversSelected: [],
      initialReceivers: [],
      alertRuleMeta: {
        isLoading: false,
        error: null,
      },
      nodeList: [],
      trigger: {},
      resolve: {},
      config: {},
      selectorManual: null,
      manualMode: false,
      oldNodeList: [],
      types: [ // hardcoded until we retrieve types from API
        {
          label: 'threshold',
          value: 0,
        },
        {
          label: 'alert',
          value: 1,
        },
        {
          label: 'inactive device',
          value: 2,
        },
      ]
    };
  },
  computed: {
    alertRuleRight() {
      return this.$store.state.user.user.featuresRights.alertRules;
    },
    dataProfiles() {
      return this.$store.state.profiles.collection;
    },
    isTitleValid() {
      return this.alertRule.title && this.alertRule.title.length > 0;
    },
    isTypeValid() {
      return this.types.map(t => t.value).includes(this.alertRule.type);
    },
    isDataProfileValid() {
      return this.dataProfiles.map(t => t.value).includes(this.alertRule.selector.dataProfileId);
    },
    isConfigValid() {
      return this.isJsonValid(this.config);
    },
    isNodeListValid() {
      return this.alertRule.selector.nodeList.length > 0;
    },
    isTriggerValid() {
      return this.isJsonValid(this.trigger);
    },
    isResolveValid() {
      return this.isJsonValid(this.resolve);
    },
    isSelectorValid() {
      if (this.manualMode)
        return this.isJsonValid(this.selectorManual);
      return true;
    },
    saveButtonDisabled() {
      return !this.isTitleValid
            || !this.isTypeValid
            || !this.isDataProfileValid
            || !this.isConfigValid
            || !this.isNodeListValid
            || !this.isTriggerValid
            || !this.isResolveValid
            || !this.isSelectorValid;
    },
  },
  watch: {
    /**
     * these 2 watchers, aloung with the preview of selector are just debug helpers
     * that are meant to be removed in the futur
     */
    trigger() {
      if (this.alertRule && this.isJsonValid(this.trigger)) this.alertRule.selector.trigger = JSON.parse(this.trigger);
    },
    resolve() {
      if (this.alertRule && this.isJsonValid(this.resolve)) this.alertRule.selector.resolve = JSON.parse(this.resolve);
    },
  },
  async created() {
    this.alertRuleMeta.isLoading = true;
    try {
      if (this.alertRuleId) {
        this.alertRule = (await api.alertRule.getAlertRule(this.alertRuleId)).data;
        this.oldNodeList = this.alertRule.selector.nodeList;
        await this.setReceiversAvailable();
        await this.initReceivers();
      } else {
        this.alertRule = {
          config: {},
          selector: {
            nodeList: [],
            dataProfileId: null,
            trigger: {},
            resolve: {},
          },
          enabled: false,
        };
        this.oldNodeList = [];
      }
      this.trigger = JSON.stringify(this.alertRule.selector.trigger, null, 4);
      this.resolve = JSON.stringify(this.alertRule.selector.resolve, null, 4);
      this.config = JSON.stringify(this.alertRule.config, null, 4);

      if (!this.$store.state.profiles.collection.length) await this.$store.dispatch('profiles/fetch');
    } catch (err) {
      const { data: { errorCode } } = err.response;
      if (errorCode === 'alertRuleNotFound') {
        this.alertRuleMeta.error = `${this.$t('pages.alertRule.errors.notFound')} ${this.alertRuleId}`;
      } else if (errorCode === 'forbiddenUser') {
        this.alertRuleMeta.error = `${this.$t('pages.alertRule.errors.forbiddenUser')}`;
      } else {
        this.alertRuleMeta.error = `${this.$t('pages.alertRule.errors.unknowError')}`;
      }
    }
    this.alertRuleMeta.isLoading = false;
  },
  methods: {
    toggleManualMode() {
      if (!this.manualMode)
        this.selectorManual = JSON.stringify(this.alertRule.selector, null, 2);
      else
        this.alertRule.selector = JSON.parse(this.selectorManual);
      this.manualMode = !this.manualMode;
    },
    nodesSelectorUpdated() {
      this.setReceiversAfterTimeout();
    },
    setReceiversAfterTimeout() {
      // wait before nodeList has been updated since more than 1 seconds
      // to avoid making the request multiples times
      clearTimeout(this.refreshReceiversTimeout);
      this.refreshReceiversTimeout = setTimeout(this.setReceiversAvailable, 500);
    },
    async setReceiversAvailable() {
      // wait before nodeList has been updated since more than 1 seconds
      // to avoid making the request multiples times
      if (!this.alertRule.selector.nodeList.length) {
        this.receiversAvailable.splice(0,this.receiversAvailable.length);
        this.receiversSelected.splice(0,this.receiversSelected.length);
      } else {
        try {
          const receiversAvailable = (await api.qpark.listUsersAboveNodes(this.alertRule.selector.nodeList)).data;
          // update list of available receivers
          this.receiversAvailable = receiversAvailable.map(this.formatUserToSelector);
          // remove selected receivers from selection if these are not available anymore
          this.receiversSelected.forEach((receiverSelectedUuid, index) => {
            if (!this.receiversAvailable.find(receiverAvailable => receiverAvailable.id === receiverSelectedUuid)) {
              this.receiversSelected.splice(index, 1);
            }
          });
        } catch (err) {
          this.$q.notify({
            message: this.$t('pages.alertRule.errors.failedToListReceivers'),
            type: 'negative',
            position: 'bottom-left',
          });
        }
      }
    },
    async initReceivers() {
      try {
        const receiversOfAlertRule = (await api.alertRule.listReceiversOfAlertRule(this.alertRule.id)).data;
        this.initialReceivers = receiversOfAlertRule.map(this.formatUserToSelector);
      } catch (err) {
        this.$q.notify({
          message: this.$t('pages.alertRule.errors.failedToListReceivers'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    formatUserToSelector(user) {
      return { id: user.uuid, label: user.email };
    },
    isJsonValid(value) {
      try {
        JSON.parse(value);
        return true;
      } catch (err) {
        return false;
      }
    },
    async save() {
      const selectorToSave = this.manualMode ? JSON.parse(this.selectorManual): this.alertRule.selector;
      const alertRuleUpdated = {
        ...this.alertRule,
        config: JSON.parse(this.config),
        selector: selectorToSave,
        receivers: this.receiversSelected,
      };

      // title required
      if (!alertRuleUpdated.title) return;
      // nodeList should contain at least 1 element
      if (!alertRuleUpdated.selector.nodeList.length) return;

      await this.$store.dispatch('alertRule/save', { alertRule: alertRuleUpdated });
      await this.$store.dispatch('alertRule/fetch');
      this.manualMode = false;
      this.$emit('saved');
    },
  },
};

</script>

<style lang="stylus" scoped>
@import '~variables'

.alert-rule-main
  .alert-rule-form
    border-radius $border-radius-smooth
    background-color white
    .alert-rule-title
      flex-direction column
      border-top 0
      padding $space-2
    .alert-rule-description
      flex-direction column
      padding $space-2
    .alert-rule-enabled
      flex-direction row
      padding $space-2
      .toggle-switch-container
        padding-left 20px
    .profile-tile
      border-right none
    .resolve-tile
      border-right none
      padding $space-2
    .trigger-resolve
      .grid-tile
        .grid-tile__value
          width 100%
    .alert-rule-config
      flex-direction column
      padding $space-2
    .alert-rule-node-selector
      flex-direction column
      padding $space-2
    .alert-rule-selector
      flex-direction column
      padding $space-2
      pre
        color $dark
    .alert-rule-update-submit
      justify-content flex-end
      padding $space-2
    .q-select
      border 1px solid black
    .form-button
      text-transform uppercase
.o-label
  text-transform capitalize

.grid-title
  font-weight 700
  font-size $fs-h2
.capitalize
  text-decoration capitalize

</style>