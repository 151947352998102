<template>
  <q-page-container class="container">
    <div v-if="alertsOfRuleMeta.isLoading"
         class="message">
        LOADING...
    </div>
    <div v-else-if="alertsOfRuleMeta.error"
         class="message">
      {{ alertsOfRuleMeta.error }}
    </div>
    <div v-else
        class="alerts-of-rule-main">
        <div class="title">{{ "Alerts" }}</div>
        <div class="alerts-stats">
          <div class="flex-wrapper">
            <div class="item flex-tile">
              <label class="label">{{$t('pages.alertRule.labels.triggeredAlerts')}}</label>
              <div class="value">{{ totalAlerts }}</div>
            </div>
            <div class="item flex-tile">
              <label class="label">{{$t('pages.alertRule.labels.resolvedAlerts')}}</label>
              <div class="value">{{ totalResolvedAlerts }}</div>
            </div>
            <div class="item flex-tile">
              <label class="label">{{$t('pages.alertRule.labels.activeAlerts')}}</label>
              <div class="value">{{ totalOpenAlerts }}</div>
            </div>
            <div class="item flex-tile">
              <label class="label">{{$t('pages.alertRule.labels.emergencyHigh')}}</label>
              <div class="value">{{ highAlerts.length }}</div>
            </div>
            <div class="item flex-tile">
              <label class="label">{{$t('pages.alertRule.labels.emergencyNormal')}}</label>
              <div class="value">{{ normalAlerts.length }}</div>
            </div>
            <div class="item flex-tile">
              <label class="label">{{$t('pages.alertRule.labels.emergencyLow')}}</label>
              <div class="value">{{ lowAlerts.length }}</div>
            </div>
            <div class="item flex-tile">
              <label class="label">{{$t('pages.alertRule.labels.avgResolutionTime')}}</label>
              <div class="value">{{ avgResolvedTime }}</div>
            </div>
          </div>
        </div>
        <div class="action-container">
          <div class="list-filters">
            <button :class="filterIsSelected('all')"
                    class="filter-item t_filter-item--all"
                    @click="levelFilter = 'all'">{{$t('pages.alert.priorities.all')}}</button>
            <button :class="filterIsSelected('high')"
                    class="filter-item t_filter-item--high"
                    @click="levelFilter = 'high'">{{$t('pages.alert.priorities.high')}}</button>
            <button :class="filterIsSelected('normal')"
                    class="filter-item t_filter-item--normal"
                    @click="levelFilter = 'normal'">{{$t('pages.alert.priorities.normal')}}</button>
            <button :class="filterIsSelected('low')"
                    class="filter-item t_filter-item--low"
                    @click="levelFilter = 'low'">{{$t('pages.alert.priorities.low')}}</button>
            <button :class="filterIsSelected('resolved')"
                    class="filter-item t_filter-item--resolved"
                    @click="levelFilter = 'resolved'">{{$t('pages.alert.priorities.resolved')}}</button>
          </div>
        </div>
        <div v-if="alerts"
            class="alerts-list-container">
          <div class="alerts-list">
            <AlertSimple v-for="alert in filteredAlerts"
              :key="alert.id"
              class="alert-simple"
              :alertRule="alertRule"
              :alert="alert">
            </AlertSimple>
          </div>
        </div>
    </div>
  </q-page-container>
</template>

<script>
import api from 'api';
import moment from 'moment';
import AlertSimple from '@/app/pages/alert/components/alert-simple.vue';

export default {
  name: 'AlertsOfRule',
  components: { AlertSimple },
  props: {
    alertRuleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      allAlerts: [],
      alertRule: {},
      alerts: [],
      resolvedAlerts: [],
      alertsOfRuleMeta: {
        isLoading: false,
        error: null,
      },
      levelFilter: 'all',
      emergencyLevels: ['low', 'normal', 'high'],
    };
  },
  computed: {
    totalResolvedAlerts() {
      return this.resolvedAlerts.length;
    },
    totalOpenAlerts() {
      return this.alerts.length;
    },
    totalAlerts() {
      return this.allAlerts.length;
    },
    highAlerts() {
      return this.allAlerts.filter(alert => {
        return this.emergencyLevels[alert.urgence] === 'high';
      });
    },
    normalAlerts() {
      return this.allAlerts.filter(alert => {
        return this.emergencyLevels[alert.urgence] === 'normal';
      });
    },
    lowAlerts() {
      return this.allAlerts.filter(alert => {
        return this.emergencyLevels[alert.urgence] === 'low';
      });
    },
    avgResolvedTime() {
      if (!this.allAlerts.length)
        return '-';
      const resolutionTimeMs = this.allAlerts.map(alert => {
        const triggeredTime = moment(alert.createdAt);
        const resolvedTime = alert.dateResolved ? moment(alert.dateResolved) : moment();
        return resolvedTime.diff(triggeredTime);
      });
      const avgResolvedTimeMs = resolutionTimeMs.reduce((a, b) => a + b) / resolutionTimeMs.length;
      return moment.duration(avgResolvedTimeMs).locale(this.$i18n.locale).humanize();
    },
    filteredAlerts() {
      if (this.levelFilter === 'resolved') return this.resolvedAlerts;
      if (this.levelFilter === 'all') return this.alerts;
      return this.alerts.filter(alert => {
        return this.emergencyLevels[alert.urgence] === this.levelFilter;
      });
    },

  },
  async created() {
    this.alertsOfRuleMeta.isLoading = true;
    try {
      if (this.alertRuleId) {
        this.alertRule = (await api.alertRule.getAlertRule(this.alertRuleId)).data;
        const alerts = (await api.alertRule.listAlertsOfAlertRule(this.alertRuleId)).data;
        const alertsSorted = alerts.sort((a, b) => b.urgence - a.urgence);
        this.allAlerts = alertsSorted;
        this.alerts = alertsSorted.filter(a => !a.dateResolved);
        this.resolvedAlerts = alertsSorted.filter(a => !!a.dateResolved);
      }
    } catch (err) {
      if (err.response) {
        const { data: { errorCode } } = err.response;
        if (errorCode === 'alertRuleNotFound') {
          this.alertsOfRuleMeta.error = `${this.$t('pages.alertRule.errors.notFound')} ${this.alertRuleId}`;
        } else if (errorCode === 'forbiddenUser') {
          this.alertsOfRuleMeta.error = `${this.$t('pages.alertRule.errors.forbiddenUser')}`;
        }
      }
      if (!this.alertsOfRuleMeta.error) {
        this.alertsOfRuleMeta.error = `${this.$t('pages.alertRule.errors.unknowError')}`;
      }
    }
    this.alertsOfRuleMeta.isLoading = false;
  },
  methods: {
    filterIsSelected(state) {
      return {
        isSelected: state === this.levelFilter,
      };
    },
  },
};

</script>

<style lang="stylus" scoped>
@import '~variables'

.alert-simple
  margin 5px
.title
  color $font-color
  text-transform uppercase
  font-weight 700
  font-size 2rem

.action-container
  display flex
  align-items center
  padding 0 $space-2 $space-2

.list-filters
  margin-left auto
  .filter-item
    text-transform uppercase
  button
    outline none
    border none
    background none
    letter-spacing 0.5px
    font-weight 400
    font-size $fs-body
    cursor pointer
    &:hover
      text-decoration underline
  .isSelected
    text-decoration underline
    font-weight 900

.alerts-list-container
  display flex
  overflow hidden
  max-height 950px
  .alerts-list
    overflow-y auto
  @media (max-width: 500px)
    max-height 500px

.alerts-stats
  margin 10px auto 10px auto
  .flex-wrapper
    display flex
    flex-wrap wrap
    justify-content center
  .flex-tile
    display flex
    border-radius $border-radius-smooth
    margin 3px
    justify-content flex-center
    border 1px solid black
    text-align center
  .item
    padding 4px
    display flex
    flex-direction column
  .label
    margin-bottom 0
    text-transform capitalize
    color lighten($dark, 20)

.strong
  font-size $fs-h3
  font-weight 600


</style>