import securedAxios from '../securedAxios';


const endpoint = '/api/sums';
const sums = {
  fetch() {
    return securedAxios.get(`${endpoint}`);
  },
  data: {
    read(sumId, from, to) {
      return securedAxios.get(`${endpoint}/${sumId}/compute?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`);
    },
  },
  delete(sumId) {
    return securedAxios.delete(`${endpoint}/${sumId}`);
  },
  create(name, nodeUuid, sumItems) {
    return securedAxios.post(`${endpoint}`, {
      name,
      nodeUuid,
      sumItems
    });
  },
  update(sumId, name) {
    return securedAxios.patch(`${endpoint}/${sumId}`, {
      name
    });
  },
  sumItems: {
    delete(sumId, sumItemId) {
      return securedAxios.delete(`${endpoint}/${sumId}/sumItems/${sumItemId}`);
    },
    create(sumId, deviceUuid, profileId, operator) {
      return securedAxios.post(`${endpoint}/${sumId}/sumItems`, {
        deviceUuid,
        profileId,
        operator
      });
    },
    update(sumId, sumItemId, operator) {
      return securedAxios.patch(`${endpoint}/${sumId}/sumItems/${sumItemId}`, {
        operator
      });
    }
  }
};

export default sums;