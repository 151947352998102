import _ from 'lodash';
import alerts from './alert-locale-object.json';
const alertFR = _.cloneDeep(alerts);

// pop-up
alertFR.popUp.noActive = 'Aucune alerte active';
alertFR.popUp.center = 'Ouvrir Menu Alertes';
alertFR.popUp.seeMore = 'Voir plus';
// title
alertFR.title.active = 'Alertes en cours';
alertFR.title.history = 'Historique des alertes';
alertFR.view.return = 'Retour';
// view
alertFR.view.active = 'Voir les alertes en cours';
alertFR.view.history = 'Voir les alertes résolues';
// priorities
alertFR.priorities.all = 'Toutes';
alertFR.priorities.low = 'Basse';
alertFR.priorities.normal = 'Moyenne';
alertFR.priorities.high = 'Haute';
alertFR.priorities.resolved = 'Résolue';

// labels
alertFR.labels.title = 'titre';
alertFR.labels.localisation = 'emplacement';
alertFR.labels.priority = 'urgence';
alertFR.labels.activeSince = 'actif depuis';
alertFR.labels.data = 'Donnée';
// errors
alertFR.errors.notFound = "il n'y a pas d'alerte avec l'id";
alertFR.errors.forbiddenUser = "Vous n'avez pas accès à l'alerte";
alertFR.errors.unknowError = 'Une erreur est survenue';
alertFR.errors.noLocation = 'Aucune Emplacement trouvée';
// layout
alertFR.layout.history.title = 'Historique';
alertFR.layout.history.lastActive = 'Dernière activation';
alertFR.layout.history.lastActiveNever = 'Jamais';
alertFR.layout.history.nbActiveSince = 'Activée {triggerCount} fois en {since}';
alertFR.layout.history.firstActivation = 'Première activation';

alertFR.layout.alertRule = `Règle de l'alerte`;
alertFR.layout.ignoreDevice = 'Ignorer cet équipement';

alertFR.simple.message = `Message`;
alertFR.simple.device = `Équipement`;
alertFR.simple.emergencyLevel = `Niveau d'urgence`;
alertFR.simple.dateCreated = `Déclanchement de l'alerte`;
alertFR.simple.location = `Localisation`;
alertFR.simple.locationNotFound = `Localisation introuvable`;
alertFR.simple.resolved = 'Résolue';
alertFR.simple.profileName = 'Profil de données';
alertFR.simple.profileUnit = 'Unité';

alertFR.resolved = 'Résolue';
alertFR.active = 'Active';

export default alertFR;