<template>
<div class="ticket-main">
    <div class="ticket-form">

      <!-- title -->
      <div class="ticket-title grid-wrapper">
        <label class="o-label">
          {{$t('pages.ticket.creationEdition.labels.title')}}
        </label>
        <input v-model="ticket.title"
               class="o-input"
              :placeholder="title || ''"
               type="text">
      </div>

      <!-- scope -->
      <div v-if="!publicView"
               class="grid-wrapper">
        <div class="grid-tile">
          <label class="o-label">
            {{$t('pages.ticket.creationEdition.labels.scope')}}
          </label>
          <div class="scope-choices">
            <q-radio v-if="ticket.x && ticket.y"
                     v-model="ticketLocation"
                     :disable="ticket.shortId"
                     val="dot"
                     class="scope-choice q-mr-sm"
                     color="black"
                     :label="$t('pages.ticket.creationEdition.scopes.dot')" />
            <q-radio v-if="ticket.spaceUuid"
                     v-model="ticketLocation"
                     :disable="ticket.shortId"
                     val="room"
                     color="black"
                     class="scope-choice q-mr-sm"
                     :label="$t('pages.ticket.creationEdition.scopes.room')" />
            <q-radio v-if="ticket.floorUuid"
                     v-model="ticketLocation"
                     :disable="ticket.shortId"
                     val="floor"
                     class="scope-choice q-mr-sm"
                     color="black"
                     :label="$t('pages.ticket.creationEdition.scopes.floor')" />
            <q-radio v-if="ticket.buildingUuid"
                     v-model="ticketLocation"
                     :disable="ticket.shortId"
                     val="building"
                     class="scope-choice q-mr-sm"
                     color="black"
                     :label="$t('pages.ticket.creationEdition.scopes.building')" />
          </div>
        </div>
      </div>
      <!-- type and priority -->
      <div class="grid-wrapper">
        <div class="grid-tile">
          <label class="o-label">{{$t('pages.ticket.creationEdition.labels.type')}}</label>
          <span class="grid-tile__value">
            <q-select v-model="ticket.type"
                      :options="optionsTypes"
                      class="select"
                      invertedLight
                      color="white" />
          </span>
        </div>
        <div class="priority-tile grid-tile">
          <label class="o-label">{{$t('pages.ticket.creationEdition.labels.priority')}}</label>
          <span class="grid-tile__value">
            <q-select v-model="ticket.priority"
                      :options="optionsPriorities"
                      class="select"
                      invertedLight
                      color="white" />
          </span>
        </div>
      </div>
      <!-- message -->
      <div class="ticket-message grid-wrapper">
        <label class="o-label">{{$t('pages.ticket.creationEdition.labels.message')}}</label>
        <div class="grid-tile__value">
          <textarea v-model="ticket.message"
                    class="o-textarea"
                    :placeholder="message || ''"
                    rows="8" />
          </div>
      </div>

      <!-- documents -->
      <form enctype="multipart/form-data"
            novalidate
            class="files-form grid-wrapper">
        <label class="o-label">
          {{$t('pages.ticket.creationEdition.labels.documents')}}
        </label>
        <div class="files-list">
          <span v-for="(file, idx) in ticket.documents"
                :key="idx"
                class="file-item file-item--download"
                @click="downloadFile(file)">
            <CrossPicto class="cross"
                        size="15px"
                        color="black"
                        @click.native.stop="deleteFile(file.shortId)"/>
            {{ file.filename }}
          </span>
          <span v-for="(file, idx) in files"
                :key="idx"
                class="file-item">
            <CrossPicto class="cross"
                        size="12px"
                        color="black"
                        @click.native="unselectFile(idx)"/>
            {{ file.name }}
          </span>
          <input id="file"
                 ref="files"
                 type="file"
                 multiple
                 class="input-files"
                 @change="onFilesChange">
          <label for="file">
            <PlusPicto size="20px"
                       class="picto"
                       color="black"/>
            {{$t('pages.ticket.creationEdition.labels.addDocument')}}
          </label>
        </div>
      </form>

      <!-- update button  -->
      <div class="ticket-update-submit grid-wrapper">
        <button v-if="showCancelButton"
          class="form-button o-button o-button--shadows o-button--hover o-button--squared"
          @click="cancel()">
          {{ $t('pages.ticket.creationEdition.buttons.cancel') }}
        </button>
        <button :class="createButtonClass"
            class="form-button o-button o-button--shadows o-button--hover o-button--squared o-button--primary-gradient"
            @click="create()">
          {{ buttonText }}
        </button>
      </div>
    </div>
  <div v-if="isEdition"
    class="ticket-more-actions o-card">
    <div class="grid-header"
        @click="openActions">
      <label class="grid-title">{{$t('pages.ticket.creationEdition.labels.moreActions')}}</label>
      <ChevronPicto size="20px"
                      class="arrow-down"/>
    </div>
    <CollapseContainer :pose="showActions ? 'visible' : 'hidden'">
    <div class="actions grid-wrapper">
    <button :class="createButtonClass"
          class="delete-button o-button o-button--shadows o-button--hover o-button--negative o-button--squared"
          @click="deleteTicket">
      {{$t('pages.ticket.creationEdition.buttons.delete')}}
    </button>
    </div>
    </CollapseContainer>
  </div>
</div>
</template>

<script>
import _ from 'lodash';
import api from 'api';
import posed from 'vue-pose';

import PlusPicto from '@/app/components/ui/pictos/plus.vue';
import CrossPicto from '@/app/components/ui/pictos/cross.vue';
import ChevronPicto from '@/app/components/ui/pictos/chevron.vue';

/**
 * Emits:
 *  - exitEdition: after the ticket had been saved, or when the cancel button has been pressed if it is shown
 */
export default {
  name: 'TicketCreateEditForm',
  components: {
    PlusPicto,
    CrossPicto,
    ChevronPicto,
    CollapseContainer: posed.div({
      visible: {
        height: 'auto',
      },
      hidden: {
        overflow: 'hidden',
        height: '0px',
      },
    }),
  },
  props: {
    publicView: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    ticketPosition: {
      type: Object,
      default: () => {},
    },
    alertId: {
      type: Number,
      default: null
    },
    currentTicket: {
      type: Object,
      default: null,
    },
    // show or not the "cancel" button next to save/update button
    showCancelButton: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      shortId: '',
      files: [],
      ticketLocation: '',
      ticket: {},
      showActions: false,
      titleMaxChar: 125,
    };
  },
  computed: {
    ticketTypes() {
      return this.$store.state.tickets.types;
    },
    ticketPriorities() {
      return this.$store.state.tickets.priorities.filter(p => !['lowest', 'highest'].includes(p));
    },
    isEdition() {
      return !!this.ticket.shortId;
    },
    buttonText() {
      return this.ticket.shortId
        ? this.$t('pages.ticket.creationEdition.buttons.update')
        : this.$t('pages.ticket.creationEdition.buttons.create');
    },
    createButtonClass() {
      return {
        'form-button--disabled': !this.ticket.title,
      };
    },
    optionsTypes() {
      return this.ticketTypes.map(t => (t = { label: _.capitalize(this.$t(`pages.ticket.creationEdition.types.${t}`)), value: t }));
    },
    optionsPriorities() {
      return this.ticketPriorities.map(
        t => (t = { label: _.capitalize(this.$t(`pages.ticket.creationEdition.priorities.${t}`)), value: t })
      );
    },
    currentFloorIndex() {
      return this.$store.state.building.selectedFloor;
    },
    currentFloors() {
      return (this.selectedBuilding && this.selectedBuilding.next) || null;
    },
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
  },
  watch: {
    'ticket.title': function(val) {
      if (this.ticket && val.length > this.titleMaxChar) this.ticket.title = this.ticket.title.substring(0, this.titleMaxChar);
    },
    'ticket.x': function(val) {
      this.setBaseTicketLocation();
    },
    'ticket.y': function(val) {
      this.setBaseTicketLocation();
    },
    'ticket.spaceUuid': function(val) {
      this.setBaseTicketLocation();
    },
    'ticket.floorUuid': function(val) {
      this.setBaseTicketLocation();
    },
    'ticket.buildingUuid': function(val) {
      this.setBaseTicketLocation();
    },
  },
  mounted() {
    if (this.currentTicket) {
      this.ticket = Object.assign({}, this.currentTicket);
    } else {
      this.ticket = {
        buildingUuid: (this.selectedBuilding && this.selectedBuilding.uuid) || this.ticketPosition.buildingUuid,
        floorUuid: (this.currentFloors && this.currentFloors[this.currentFloorIndex].uuid) || this.ticketPosition.floorUuid,
        type: 'other',
        priority: 'medium',
      };
      if (this.publicView) {
        this.ticket.x = this.ticketPosition.x;
        this.ticket.y = this.ticketPosition.y;
      }

      if (!this.ticket.floorUuid) delete this.ticket.floorUuid;
      if (this.ticketPosition.roomUuid) {
        this.ticket.spaceUuid = this.ticketPosition.roomUuid;
      }
      const floor = document.getElementById(`floor${this.currentFloorIndex}`);
      if (this.ticketPosition.x && this.ticketPosition.y && floor) {
        const pt = floor.firstElementChild.createSVGPoint();
        pt.x = this.ticketPosition.x;
        pt.y = this.ticketPosition.y;
        const deviceCoord = pt.matrixTransform(floor.firstElementChild.getScreenCTM().inverse());
        this.ticket.x = deviceCoord.x;
        this.ticket.y = deviceCoord.y;
      }
    }

    this.setBaseTicketLocation();
  },
  methods: {
    openActions() {
      this.showActions = !this.showActions;
    },
    async deleteTicket() {
      try {
        //await api.tickets.private.delete(this.ticket.shortId);
        await this.$store.dispatch('tickets/delete', this.ticket.shortId);
        // delete ticket dot on map
        const ticketDot = document.getElementById(this.ticket.shortId);
        if (ticketDot) {
          const parent = ticketDot.parentElement;
          ticketDot.__vue__.$destroy();
          parent.removeChild(ticketDot);
        }
        this.$emit('close');
        this.$q.notify({
          message: this.$t('pages.ticket.toasts.ticketDeleted'),
          type: 'positive',
          position: 'bottom-left',
        });
        this.$emit('exitEdition');
      } catch (error) {
        this.$q.notify({
          message: error.response.data || this.$t('pages.ticket.toasts.errorDeletingTicket'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    async create() {
      if (!this.ticket || !this.ticket.title) return;
      if (!this.ticket.shortId) {
        if (this.ticketLocation === 'room' || this.ticketLocation === 'floor' || this.ticketLocation === 'building') {
          delete this.ticket.x;
          delete this.ticket.y;
        }
        if (this.ticketLocation === 'floor' || this.ticketLocation === 'building') delete this.ticket.spaceUuid;
        if (this.ticketLocation === 'building') delete this.ticket.floor;
      }
      try {
        if (this.ticket.shortId) {
          await this.$store.dispatch('tickets/updatePrivate', this.ticket);
          if (this.files.length) {
            const formData = new FormData();
            for (let i = 0; i < this.files.length; i++) formData.append(`files[${i}]`, this.files[i]);
            await this.$store.dispatch('tickets/file/create', { ticketId: this.ticket.shortId, files: formData });
          }
        } else {
          let formData = undefined;
          if (this.files.length) {
            formData = new FormData();
            for (let i = 0; i < this.files.length; i++) formData.append(`files[${i}]`, this.files[i]);
          }
          if (this.publicView) {
            const { data: newTicket } = await api.tickets.public.create(this.ticket);
            if (formData) {
              await api.tickets.public.file.create(newTicket.shortId, formData);
            }
            this.$router.push({
              name: 'public-ticket',
              params: { shortId: newTicket.shortId },
            });
          } else {
            if (this.alertId) {
              await this.$store.dispatch('alert/createTicket', { ticket: this.ticket, files: formData, id: this.alertId });
            } else {
              await this.$store.dispatch('tickets/create', { ticket: this.ticket, files: formData });
            }
            await this.$store.dispatch('tickets/fetch');
          }
        }
        this.$emit('exitEdition');
      } catch (error) {
        this.$q.notify({
          message: error.response.data || this.$t('pages.ticket.toasts.errorCreatingUpdatingTicket'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    cancel() {
      this.$emit('exitEdition');
    },
    onFilesChange() {
      this.files = [...this.files, ...this.$refs.files.files];
    },
    unselectFile(idx) {
      this.files.splice(idx, 1);
    },
    async downloadFile(file) {
      const { filename: name, mimetype: type } = file;
      try {
        const response = await api.tickets.public.file.read(this.shortId, file.shortId);
        const blob = new Blob([response.data], { type: type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      } catch (error) {
        this.$q.notify({
          message: error.response.data || this.$t('pages.ticket.toasts.cannotDownloadFile'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    setBaseTicketLocation() {
      if (this.ticket) {
        if (this.ticket.x) this.ticketLocation = 'dot';
        else if (this.ticket.spaceUuid) this.ticketLocation = 'room';
        else if (this.ticket.floorUuid) this.ticketLocation = 'floor';
        else this.ticketLocation = 'building';
      }
    },
    async deleteFile(docId) {
      try {
        await this.$store.dispatch('tickets/file/delete', { ticketId: this.ticket.shortId, docId });
        this.ticket.documents = this.ticket.documents.filter(d => d.shortId !== docId);
      } catch (error) {
        this.$q.notify({
          message: error.response.data || this.$t('pages.ticket.toasts.cannotDeleteFile'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

$input-bg-color = $light

.ticket-main
  .ticket-form
    border-radius $border-radius-smooth
    background-color white
    box-shadow $shadow-card
    .ticket-title
      flex-direction column
      padding $space-2
    .scope-choices
      display flex
      align-items center
      height 100%
      font-size $fs-body
    .priority-tile
      border-right none
    .ticket-message
      flex-direction column
      padding $space-2
    .ticket-update-submit
      justify-content flex-end
      padding $space-2
      grid-gap: 10px;
    .q-select
      border 1px solid black
      box-shadow $shadow-elevation-1
    .form-button
      text-transform uppercase
.o-label
  text-transform capitalize

.files-form
  flex-direction column
  padding $space-2
  .files-list
    display flex
    flex-wrap wrap
    justify-content flex-start
    .file-item
      position relative
      display flex
      flex-direction column
      justify-content center
      align-items center
      margin-right 8px
      margin-bottom 8px
      padding 16px
      border 1px solid lighten($grey, 70%)
      border-radius 5px
      box-shadow $shadow-elevation-1
      color darken($grey, 30%)
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size 0.9rem
      transition box-shadow 0.3s ease
      &--download
        cursor pointer
        &:hover
          box-shadow $shadow-elevation-2
      .cross
        position absolute
        top 5px
        right 5px
        cursor pointer
  .input-files
    position absolute
    z-index -1
    overflow hidden
    width 0.1px
    height 0.1px
    opacity 0
  .input-files + label
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding $space-2
    border 1px solid black
    border-radius $border-radius-smooth
    box-shadow $shadow-elevation-1
    text-transform uppercase
    letter-spacing 0.5px
    font-size 0.9rem
    cursor pointer
    transition all 100ms ease
    .picto
      margin-bottom 8px
    &:hover
      box-shadow $shadow-elevation-2
    &:focus
      outline 1px dotted #000
      outline -webkit-focus-ring-color auto 5px
    .inputfile + label *
      pointer-events none

.ticket-more-actions
  margin-top $space-2
  background-color white
  .grid-header
    display flex
    align-items center
    padding $space-2
    .arrow-down
      margin-left auto
      cursor pointer
  .actions
    padding $space-2
    .delete-button
      margin auto
      width fit-content

.grid-title
  font-weight 700
  font-size $fs-h2
</style>
