import _ from 'lodash';
import baseObject from './alertRule-locale-object.json';
const alertRuleEN = _.cloneDeep(baseObject);

// creation/edition module
alertRuleEN.creationEdition.labels.title = 'title of the alert rule';
alertRuleEN.creationEdition.labels.description = 'description';
alertRuleEN.creationEdition.labels.type = 'type of rule';
alertRuleEN.creationEdition.labels.profile = 'data profile';
alertRuleEN.creationEdition.labels.config = 'configuration';
alertRuleEN.creationEdition.labels.nodeList = 'nodes list';
alertRuleEN.creationEdition.labels.trigger = 'trigger conditions';
alertRuleEN.creationEdition.labels.resolve = 'resolution conditions';
alertRuleEN.creationEdition.labels.selector = 'selector';
alertRuleEN.creationEdition.labels.enabled = 'enabled';
alertRuleEN.creationEdition.labels.save = 'save';
alertRuleEN.creationEdition.labels.receivers = 'receivers';
alertRuleEN.creationEdition.labels.selectReceivers = `Select receivers for this alert rule`;

// list
alertRuleEN.labels.title = 'title';
alertRuleEN.labels.description = 'description';
alertRuleEN.labels.trigger = 'trigger';
alertRuleEN.labels.resolve = 'resolve';
alertRuleEN.labels.nodes = 'nodes list';
alertRuleEN.labels.unit = 'unit';
alertRuleEN.labels.profile = 'data profile';

alertRuleEN.labels.triggeredAlerts = 'triggered alerts';
alertRuleEN.labels.resolvedAlerts = 'resolved alerts';
alertRuleEN.labels.activeAlerts = 'active alerts';
alertRuleEN.labels.emergencyHigh = 'high emergency';
alertRuleEN.labels.emergencyNormal = 'medium emergency';
alertRuleEN.labels.emergencyLow = 'low emergency';
alertRuleEN.labels.avgResolutionTime = 'average resolution time';

alertRuleEN.buttons.createNewAlertRule = 'create a new alert rule';
alertRuleEN.buttons.return = 'return';

alertRuleEN.menu.buttons.center = 'Alert rules center';

alertRuleEN.errors.unknowError = 'Unknown rule';
alertRuleEN.errors.noRules = 'No alert rules';
alertRuleEN.errors.failedToListReceivers = 'Error while fetching receivers list';

alertRuleEN.toasts.saved = 'Alert rule saved';

export default alertRuleEN;