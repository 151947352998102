import _ from 'lodash';
import buildingConfig from './building-config-locale-object.json';
const buildingConfigFR = _.cloneDeep(buildingConfig);

// menu
buildingConfigFR.sidebarIn.menu.spaces = 'pièces';
buildingConfigFR.sidebarIn.menu.parcels = 'lots';
buildingConfigFR.sidebarIn.menu.deleteBuilding = 'supprimer bâtiment';
// properties
buildingConfigFR.properties.title = 'propriétés';
buildingConfigFR.properties.access.private = `privé`;
buildingConfigFR.properties.access.public = `publique`;
buildingConfigFR.properties.type.private = `privé`;
buildingConfigFR.properties.type.public = `publique`;
buildingConfigFR.properties.type.bedroom = `chambre`;
buildingConfigFR.properties.type.bathroom = `salle d'eau`;
buildingConfigFR.properties.type.kitchen = `cuisine`;
buildingConfigFR.properties.type.office = `bureau`;
buildingConfigFR.properties.type['living room'] = `salon`;
buildingConfigFR.properties.type.wc = `wc`;
buildingConfigFR.properties.type.corridor = `couloir`;
buildingConfigFR.properties.type.terrace = `terasse`;
buildingConfigFR.properties.type.balcony = `balcon`;
buildingConfigFR.properties.type.cellar = `cellier`;
buildingConfigFR.properties.type.pmiPMI = `PMI`;
buildingConfigFR.properties.type.hall = `hall`;
buildingConfigFR.properties.type.reception = `accueil`;
buildingConfigFR.properties.type['waiting room'] = `salle d'attente`;
buildingConfigFR.properties.type['meeting room'] = `salle de réunion`;
// parcels
buildingConfigFR.parcel.parcelInfo.createParcel = 'sélectionnez un lot ou créez en un';
buildingConfigFR.parcel.parcelInfo.addSpaceToParcel = "cliquez sur une pièce pour l'ajouter au lot";
buildingConfigFR.parcel.parcelInfo.floor = 'étage';
buildingConfigFR.parcel.parcelInfo.spaces = 'pièces';
buildingConfigFR.parcel.parcelEdition.title = 'Édition de lot';
buildingConfigFR.parcel.parcelEdition.buttons.createParcel = 'Créer un lot';
buildingConfigFR.parcel.parcelEdition.buttons.showParcels = 'afficher tout les lots';
buildingConfigFR.parcel.parcelEdition.buttons.hideParcels = 'cacher tout les lots';
buildingConfigFR.parcel.parcelEdition.buttons.edit = 'modifier';
buildingConfigFR.parcel.parcelEdition.buttons.delete = 'supprimer';
buildingConfigFR.parcel.parcelEdition.form.name = 'nom';
buildingConfigFR.parcel.parcelEdition.form.type = 'type';
buildingConfigFR.parcel.parcelEdition.form.update = 'sauvegarder';
buildingConfigFR.parcel.parcelEdition.form.create = 'créer';
buildingConfigFR.parcel.parcelEdition.form.apartment = 'appartement';
buildingConfigFR.parcel.parcelEdition.form.office = 'bureau';
buildingConfigFR.parcel.parcelEdition.toasts.allParcelsSaved = `les lots on été sauvegardés`;
buildingConfigFR.parcel.parcelEdition.toasts.spaceAlreadyAssignedTo = `cette pièce à déjà été assigné à {zone}`;
buildingConfigFR.parcel.parcelEdition.toasts.spaceHasToBeSameFloor = `les pièces d'un lot doivent être au même étage`;

export default buildingConfigFR;
