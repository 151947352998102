import Vue from 'vue';
import VueRouter from 'vue-router';
import multiguard from 'vue-router-multiguard';
import store from '@/services/store';

// LAYOUTS
const BaseLayout = () => import('@/app/routing-layouts/base.vue');
const NoHeaderLayout = () => import('@/app/routing-layouts/no-header.vue');
const SimpleHeader = () => import('@/app/routing-layouts/simple-header.vue');
// VIEWS
const Login = () => import('@/app/pages/login');
const Data = () => import('@/app/pages/data');
const BuildingConfiguration = () => import('@/app/pages/building-configuration');
const QueryBuilder = () => import('@/app/pages/oql/query-builder.vue');
const QueryWriter = () => import('@/app/pages/oql/query-writer.vue');
const Ticket = () => import('@/app/pages/ticket');
const Dashboard = () => import('@/app/pages/dashboard');
const Store = () => import('@/app/pages/store');
const WifiMapper = () => import('@/app/pages/wifi-mapper');
const PublicTicket = () => import('@/app/pages/ticket/public.vue');
const PublicCreateTicketRedirect = () => import('@/app/pages/ticket/public-create-redirect.vue');
const PublicCreateTicket = () => import('@/app/pages/ticket/public-create.vue');
const Page404 = () => import('@/app/pages/404');
const PageAdmin = () => import('@/app/pages/admin');
const Notification = () => import('@/app/pages/notification');
import AlertRulesCenter from '@/app/pages/alert-rule/components/alert-rules-center.vue';
import AlertCenter from '@/app/pages/alert/components/alert-center.vue';
import AlertDetailsLayout from '@/app/pages/alert/components/alert-details-layout.vue';
import AlertRuleDetailsLayout from '@/app/pages/alert-rule/components/alert-rule-details-layout.vue';
import NodesGroupsCenter from '@/app/pages/nodes-group/components/nodes-groups-center.vue';
import NodesGroupDetailsLayout from '@/app/pages/nodes-group/components/nodes-group-details-layout.vue';

import DevicesList from '@/app/pages/devices/DevicesList';
import DevicesDevice from '@/app/pages/devices/DevicesDevice';

import ReportConfigList from '@/app/pages/report/ReportConfigList.vue';
import ReportConfigView from '@/app/pages/report/ReportConfigView.vue';
import ReportConfigEdit from '@/app/pages/report/ReportConfigEdit.vue';
import ReportConfigNew from '@/app/pages/report/ReportConfigNew.vue';

import SinglePageLayout from '@/app/routing-layouts/single-page.vue';

const routes = [
  {
    path: '',
    beforeEnter: multiguard([requireAuth, requireFeatureRight]),
    component: BaseLayout,
    children: [
      {
        path: 'data',
        name: 'data',
        component: Data,
      },
      {
        path: 'configuration',
        name: 'configuration',
        component: BuildingConfiguration,
        beforeEnter: requireCurrentBuilding,
        props: true,
      },
      {
        path: 'oql',
        component: BaseLayout,
        name: 'oql',
        children: [
          {
            path: 'builder',
            name: 'builder',
            component: QueryBuilder,
          },
          {
            path: 'query',
            name: 'query',
            component: QueryWriter,
          },
        ],
      },
      {
        path: 'ticket',
        name: 'ticket',
        component: Ticket,
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: 'store',
        name: 'store',
        component: Store,
      },
      {
        path: 'admin',
        name: 'admin',
        component: PageAdmin,
      },
      {
        path: 'notification',
        name: 'notifications',
        component: SinglePageLayout,
        redirect: { name: 'notificationsHome' },
        children: [
          {
            path: '/',
            name: 'notificationsHome',
            component: Notification,
          }
        ]
      },
      {
        path: 'alert',
        name: 'alerts',
        redirect: { name: 'AlertCenter' },
        component: SinglePageLayout,
        children: [
          {
            path: '/',
            name: 'AlertCenter',
            component: AlertCenter,
          },
          {
            path: 'details/:alertId',
            name: 'alertDetails',
            component: AlertDetailsLayout,
            props: route => ({
              // FIXME should check that alertId is a number and move to an error page if not
              id: parseInt(route.params.alertId, 10),
            }),
          },
        ],
      },
      {
        path: 'alert-rules',
        name: 'alertRules',
        redirect: { name: 'AlertRulesCenter' },
        component: SinglePageLayout,
        children: [
          {
            path: '/',
            name: 'AlertRulesCenter',
            component: AlertRulesCenter,
          },
          {
            path: 'details/:alertRuleId',
            name: 'alertRuleDetails',
            component: AlertRuleDetailsLayout,
            props: route => ({ alertRuleId: route.params.alertRuleId }),
          },
          {
            path: 'create',
            name: 'alertRuleCreation',
            component: AlertRuleDetailsLayout,
          },
        ],
      },
      {
        path: 'nodes-groups',
        name: 'nodesGroups',
        redirect: { name: 'NodesGroupsCenter' },
        component: SinglePageLayout,
        children: [
          {
            path: '/',
            name: 'NodesGroupsCenter',
            component: NodesGroupsCenter,
          },
          {
            path: 'details/:nodesGroupId',
            name: 'nodesGroupDetails',
            component: NodesGroupDetailsLayout,
            props: route => ({ nodesGroupId: route.params.nodesGroupId }),
          },
          {
            path: 'create',
            name: 'nodesGroupCreation',
            component: NodesGroupDetailsLayout,
          }
        ]
      },
      {
        path: 'reports',
        name: 'reports',
        redirect: {
          name: 'reportConfigList'
        },
        component: SinglePageLayout,
        children: [
          {
            path: 'config/list',
            name: 'reportConfigList',
            // TODO add pagination params
            component: ReportConfigList,
          },
          {
            path: 'config/new',
            name: 'reportConfigNew',
            component: ReportConfigNew,
          },
          {
            path: 'config/edit/:reportConfigId',
            name: 'reportConfigEdit',
            component: ReportConfigEdit,
            props: route => ({
              reportConfigId: Number(route.params.reportConfigId),
            }),
          },
          {
            path: 'config/view/:reportConfigId',
            name: 'reportConfigView',
            component: ReportConfigView,
            props: route => ({
              reportConfigId: Number(route.params.reportConfigId),
            }),
          },
        ]
      },
      {
        path: 'devices',
        name: 'devices',
        redirect: { name: 'devicesList' },
        component: SinglePageLayout,
        children: [
          {
            path: 'list',
            name: 'devicesList',
            component: DevicesList,
          },
          {
            path: 'device/:deviceUuid',
            name: 'devicesDevice',
            component: DevicesDevice,
            props: route => ({
              deviceUuid: route.params.deviceUuid,
            }),
          }
        ]
      }
    ],
  },
  {
    // PAGES WITH LAYOUT AND HEADER BUT NO AUTHENTICATION
    path: '',
    beforeEnter: multiguard([requireAuth]),
    component: BaseLayout,
    children: [
    ]
  },
  {
    // PAGE WITH NO HEADER
    component: NoHeaderLayout,
    path: '/wifi-mapper',
    beforeEnter: requireAuth,
    children: [
      {
        path: '',
        name: 'wifi-mapper',
        component: WifiMapper,
      },
    ],
  },
  {
    // PAGE WITH NO AUTHENTICATION REQUIRED
    path: '/login',
    component: NoHeaderLayout,
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('user/logged');
        next({ name: store.getters['user/defaultPage'] });
      } catch (error) {
        next();
      }
    },
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
      },
    ],
  },
  {
    // PAGE WITH OPTIONAL AUTHENTICATION
    path: '/ticket/:shortId',
    component: SimpleHeader,
    beforeEnter: optionalAuth,
    children: [
      {
        path: '',
        name: 'public-ticket',
        component: PublicTicket,
        props: true,
      },
    ],
  },
  {
    /**
     * exemple: /create-ticket-redir
     * ?t=Renouveler%20le%20stock%20de%20PQ
     * &m=Renouveler%20le%20stock%20de%20PQ%20dans%20les%20toilettes%20H%20ascenseur%20du%20R-1
     * &b=BUILDING-cb51-4ec1-a0e4-5782a0d26c55
     * &f=FLOOR-9f4d-4b50-ab15-93c75ba1e853
     * &r=ROOM-2e07-4847-b28d-0b955cf81175
     * &x=83&y=492

     *
     * this route read query params and sends redirect client to /create-ticket with clear uri
     * by passing props through `props: true`
     */
    path: '/create-ticket-redir',
    component: SimpleHeader,
    beforeEnter: optionalAuth,
    children: [
      {
        path: '',
        name: 'public-create-ticket-redirect',
        component: PublicCreateTicketRedirect,
        props: route => ({
          ticketPosition: {
            roomUuid: route.query.r,
            buildingUuid: route.query.b,
            floorUuid: route.query.f,
            x: route.query.x,
            y: route.query.y,
          },
          title: route.query.t,
          message: route.query.m,
        }),
      },
    ],
  },
  {
    path: '/create-ticket',
    component: SimpleHeader,
    beforeEnter: optionalAuth,
    children: [
      {
        path: '',
        name: 'public-create-ticket',
        component: PublicCreateTicket,
        props: true,
      },
    ],
  },
  {
    path: '*',
    component: SimpleHeader,
    children: [
      {
        path: '',
        component: Page404,
      },
    ],
  },
];

function requireFeatureRight(to, from, next) {
  const userFeaturesRights = store.getters['user/featuresRights'];
  const matched = to.matched.some(p => userFeaturesRights[p.name] && userFeaturesRights[p.name].READ);
  if (matched) next();
  else next({ name: store.getters['user/defaultPage'] });
}

async function requireAuth(to, from, next) {
  try {
    await store.dispatch('user/logged');
    if (store.getters['user/defaultPage'] === undefined) {
      await this.$store.dispatch('user/logout');
    }
    next();
  } catch (error) {
    next({ name: 'login' });
  }
}

async function optionalAuth(to, from, next) {
  try {
    await store.dispatch('user/logged');
    next();
  } catch (error) {
    next();
  }
}

function requireCurrentBuilding(to, from, next) {
  if (store.getters['building/selectedBuilding']) next();
  else next({ name: store.getters['user/defaultPage'] });
}

export default new VueRouter({
  routes: routes,
  mode: 'history',
  base: '/app',
});
