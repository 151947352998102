import securedAxios from '../securedAxios';
import { ApiPaginatedResponse, Report } from '../../../types/api';

const report = {
  endpoint: '/api/reports',
  getReports({ offset = 0, limit = 10 }) {
    return securedAxios.get<ApiPaginatedResponse<Report>>(`${this.endpoint}?offset=${offset}&limit=${limit}`);
  },
  generateReport(reportConfigId: number, options: any = {}) {
    const payload = {
      reportConfigId,
      startDateTime: options.startDateTime,
      endDateTime: options.endDateTime,
    };
    return securedAxios.post<Report>(`${this.endpoint}`, payload);
  }
};

export default report;
