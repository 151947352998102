import _ from 'lodash';
import admin from './admin-locale.json';
const adminEN = _.cloneDeep(admin);

adminEN.input = 'Search users';
adminEN.info.name = 'Full Name';
adminEN.info.group.title = 'Group';
adminEN.info.group.no = 'No group';
adminEN.info.node.title = 'Node';
adminEN.info.node.no = 'No node';
adminEN.info.node.clipboard = 'Copy';
adminEN.info.node.installed = 'Installed at';
adminEN.info.feature.read = 'Read';
adminEN.info.feature.write = 'Write';
adminEN.info.feature.submit = 'Submit';
adminEN.info.feature.edit = 'Edit';
adminEN.info.feature.cancel = 'Cancel';
adminEN.info.feature.add = 'Add';
adminEN.info.feature.modal = 'Please enter a feature name';
adminEN.info.feature.modalConfirmation = 'Are you sure you want delete the feature';
adminEN.info.feature.form = 'Please enter name never used';
adminEN.info.feature.name = 'Name';
adminEN.info.oapp.name = 'Name';
adminEN.info.oapp.installed = 'Installed';
adminEN.info.oapp.available = 'Available';
adminEN.info.dashboard.noDashboard = 'No dashboard for this user';
adminEN.info.dashboard.position = 'Position';
adminEN.info.dashboard.noPosition = 'Any';
adminEN.info.dashboard.creation = 'Creation at';
adminEN.info.dashboard.widget.name = 'Name';
adminEN.info.dashboard.widget.noWidget = 'Any widget for this dashboard';
adminEN.info.dashboard.widget.notAttributed = 'Not attributed';
adminEN.info.dashboard.widget.name_widget = 'Widget Name';
adminEN.info.dashboard.widget.name_oapp = 'Oapp name';
adminEN.info.dashboard.widget.position = 'Position';
adminEN.info.dashboard.widget.weight = 'Weight';
adminEN.info.dashboard.widget.height = 'Height';
adminEN.info.dashboard.widget.button = 'See JSON';
adminEN.info.dashboard.modalCancel = 'Close';
adminEN.info.dashboard.buttonCopy = 'Copy this dashboard';
adminEN.info.dashboard.copy.sentence = 'Choose a user to copy this dashboard please';
adminEN.info.dashboard.copy.list = 'Users list';
adminEN.info.dashboard.copy.error = "This user don't have 'dashboard' feature";
adminEN.info.dashboard.copy.cancel = 'Close';
adminEN.info.dashboard.copy.submit = 'Copy';
adminEN.info.dashboard.buttonShare = 'Share this dashboard';
adminEN.info.dashboard.share.sentence = 'Choose a user to share this dashboard please';
adminEN.info.dashboard.share.list = 'Users list';
adminEN.info.dashboard.share.error = "This user don't have 'dashboard' feature";
adminEN.info.dashboard.share.cancel = 'Close';
adminEN.info.dashboard.share.submit = 'Share';


export default adminEN;
