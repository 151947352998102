<template>
  <div class="timepicker">
    <div class="timepicker__labels-row">
      <span class="timepicker__label">
        {{$t('components.timePicker.hours')}}
      </span>
      <span class="timepicker__label">
        {{$t('components.timePicker.minutes')}}
      </span>
    </div>
    <div class="picker-container">
      <div :ref="name+'-hours'"
           class="hours">
        <span :id="name+'-hour-0'"
              :class="hourClass(0)"
              class="hour-input"
              @click="selectValue('hours', 0)">
          00
        </span>
        <span v-for="index in 23"
              :id="name+'-hour-'+index"
              :key="index"
              :class="hourClass(index)"
              class="hour-input"
              @click="selectValue('hours', index)">
          {{ index >= 10 ? index : `0${index}` }}
        </span>
      </div>
      <div :ref="name+'-minutes'"
           class="minutes">
        <span :id="name+'-minute-0'"
              :class="minuteClass(0)"
              class="minute-input"
              @click="selectValue('minutes', 0)">
          00
        </span>
        <span v-for="index in 5"
              :id="name+'-minute-'+index"
              :key="index"
              :class="minuteClass(index * 10)"
              class="minute-input"
              @click="selectValue('minutes', index * 10)">
          {{ index * 10 }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'TimePicker',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: ',',
    },
    value: {
      type: Object,
      default: undefined,
    },
    min: {
      type: Object,
      default: undefined,
    },
    max: {
      type: Object,
      default: undefined,
    },
    date: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    hours() {
      const momentHours = this.value ? moment(this.value).format('HH') : undefined;
      if (momentHours) return parseInt(momentHours);
      return undefined;
    },
    minutes() {
      const momentMinutes = this.value ? moment(this.value).format('mm') : undefined;
      if (momentMinutes) return parseInt(momentMinutes);
      return undefined;
    },
    minHours() {
      return this.min ? parseInt(moment(this.min).format('HH')) : undefined;
    },
    maxHours() {
      return this.max ? parseInt(moment(this.max).format('HH')) : undefined;
    },
    minMinutes() {
      return this.min ? parseInt(moment(this.min).format('mm')) : undefined;
    },
    maxMinutes() {
      return this.max ? parseInt(moment(this.max).format('mm')) : undefined;
    },
    minuteClass() {
      return value => {
        return {
          'minute-input--selected': this.minutes >= value && this.minutes < value + 10,
          'minute-input--disabled': this.isMinuteDisabled(value),
        };
      };
    },
    hourClass() {
      return value => {
        return {
          'hour-input--selected': this.hours === value,
          'hour-input--disabled': this.isHourDisabled(value),
        };
      };
    },
  },
  watch: {
    opened() {
      this.centerValues();
    },
  },
  mounted() {
    this.centerValues();
  },
  methods: {
    isMinuteDisabled(value) {
      const currentTime = moment
        .utc(moment.duration(value, 'minutes').as('milliseconds'))
        .add(this.hours, 'hours')
        .format('HH:mm');
      const currentDateTime = moment(`${this.date} ${currentTime}`, 'DD.MM.YY HH:mm');
      return (this.max && moment(this.max).isBefore(currentDateTime)) || (this.min && moment(this.min).isAfter(currentDateTime));
    },
    isHourDisabled(value) {
      const currentDateTime = moment(`${this.date} ${moment.utc(value * 3600 * 1000).format('HH')}`, 'DD.MM.YY HH');
      return (
        (this.max &&
          moment(this.max)
            .set('minute', 0)
            .isBefore(currentDateTime)) ||
        (this.min &&
          moment(this.min)
            .set('minute', 0)
            .isAfter(currentDateTime))
      );
    },
    selectValue(type, value) {
      let newVal = undefined;
      if (type === 'hours') {
        if (this.isHourDisabled(value)) return;
        const hours = moment.utc(value * 3600 * 1000);
        newVal = hours.add(this.minutes, 'minutes');
      } else {
        if (this.isMinuteDisabled(value)) return;
        const minutes = moment.utc(moment.duration(value, 'minutes').as('milliseconds'));
        newVal = minutes.add(this.hours, 'hours');
      }
      this.$emit('input', newVal);
    },
    centerValues() {
      const hour = document.getElementById(`${this.name}-hour-${this.hours}`);
      const hoursBox = this.$refs[`${this.name}-hours`].getBoundingClientRect();
      this.$refs[`${this.name}-hours`].scrollTop = hour.offsetTop - hoursBox.height / 2;

      const minute = document.getElementById(`${this.name}-minute-${Math.floor(this.minutes / 10)}`);
      const minutesBox = this.$refs[`${this.name}-minutes`].getBoundingClientRect();
      this.$refs[`${this.name}-minutes`].scrollTop = minute.offsetTop - minutesBox.height / 2;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.timepicker
  min-width 200px
  .timepicker__labels-row
    display flex
    justify-content center
    padding 5px 0
    border-bottom solid 1px $dark
    .timepicker__label
      width 100%
      text-align center
      text-transform uppercase
      font-size $fs-h3
  .picker-container
    display flex
    justify-content center
    width 100%
    height 100%
    .hours, .minutes
      display flex
      flex-direction column
      align-items center
      overflow auto
      padding 5px
      width 100%
      height 200px
      font-size 1.8rem
      @media screen and (max-width: 500px)
        justify-content start
    .hours
      @media screen and (max-width: 500px)
        overflow scroll
        height 280px
    .hour-input, .minute-input
      margin 5px 0
      padding 2px 0 2px 0
      width 100%
      text-align center
      cursor pointer
      &--selected
        border-radius 5px
        background-color white !important
        color black !important
      &:hover
        border-radius 5px
        background-color $dark
        color white
      &--disabled
        background-color black !important
        color $dark !important

::-webkit-scrollbar
  width 3px

::-webkit-scrollbar-track
  background #2f2f2f

::-webkit-scrollbar-thumb
  background #828282

::-webkit-scrollbar-thumb:hover
  background #b9b9b9
</style>
