import securedAxios from '../securedAxios';

const Alert = {
  endpoint: '/api/alerts',
  fetch() {
    return securedAxios.get(`${this.endpoint}`);
  },
  getAlert(alertId) {
    return securedAxios.get(`${this.endpoint}/${alertId}`);
  },
  createTicket(alertId, ticket) {
    return securedAxios.post(`${this.endpoint}/${alertId}/ticket`, ticket);
  },
  ignoreDevice(alertId) {
    return securedAxios.patch(`${this.endpoint}/${alertId}/ignore-device`);
  },
};

export default Alert;