import _ from 'lodash';
import oapps from './oapps-locale-object.json';
const oappsEN = _.cloneDeep(oapps);

// Commons
oappsEN.commons.noData = 'no data';
oappsEN.commons.close = 'close';
oappsEN.commons.more = 'more';
oappsEN.commons.dataRepresentationLabel = 'type of representation';
oappsEN.commons.rainbow = 'rainbow';
oappsEN.commons['heatmap-rainbow'] = 'heatmap';
oappsEN.commons.binary = 'binary';
oappsEN.commons.greyToPrimary = 'gradient unicolore';
// DATA
oappsEN.data.temperature = 'temperature';
oappsEN.data.co2 = 'CO2';
oappsEN.data.presence = 'occupation';
// MEAN
oappsEN.mean.temperature = 'mean temperature';
oappsEN.mean.co2 = 'mean CO2';
oappsEN.mean.presence = 'mean occupation';
// Temperature
oappsEN.temperature.name = 'Temperature';

// Co2
oappsEN.co2.name = 'CO2';

// Presence
oappsEN.presence.name = 'Presence';

// Parking
oappsEN.parking.name = 'Parking';

// Sound
oappsEN.sound.name = 'Sound';

// Philips Hue Lights
oappsEN['philips hue lights'].name = 'Philips Hue Lights';

// Air
oappsEN.air.name = 'Air';

// Carriers
oappsEN.carriers.name = 'Carriers';

// Wifi
oappsEN.wifi.name = 'Wifi';

// COV
oappsEN.cov.name = 'COV';

// Humidity
oappsEN.humidity.name = 'Humidity';

// Electricity
oappsEN.electricity.name = 'Electricity';

// Generic
oappsEN.generic.name = 'Generic';

// UTL
oappsEN.utl.name = 'UTL';

// Hager
oappsEN.hager.name = 'Hager';

export default oappsEN;
