import securedAxios from '../securedAxios';

const Profiles = {
  endpoint: '/api/profiles',
  fetch() {
    return securedAxios.get(`${this.endpoint}`);
  },
};

export default Profiles;
