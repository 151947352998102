import _ from 'lodash';
import graph from './graph-locale.json';
const graphEN = _.cloneDeep(graph);

graphEN.navBar.back = 'back';
graphEN.navBar.space = 'space';
graphEN.navBar.energy = 'energy';
graphEN.tools.graphSize = 'graph size';
graphEN.tools.drag = 'drag';
graphEN.tools.center = 'center';
// sidebar node
graphEN.sidebarNode.emptyDescription = `empty description`;

graphEN.sidebarNode.nodeTypes.root = `root`;
graphEN.sidebarNode.nodeTypes.country = `country`;
graphEN.sidebarNode.nodeTypes.city = `city`;
graphEN.sidebarNode.nodeTypes.building = `building`;
graphEN.sidebarNode.nodeTypes.floor = `floor`;
graphEN.sidebarNode.nodeTypes.premise = `premise`;
graphEN.sidebarNode.nodeTypes.room = `room`;
graphEN.sidebarNode.nodeTypes.plug = `plug`;
graphEN.sidebarNode.nodeTypes.device = `device`;
graphEN.sidebarNode.nodeTypes.air = `air`;
graphEN.sidebarNode.nodeTypes.alarm = `alarm`;
graphEN.sidebarNode.nodeTypes.water = `water`;
graphEN.sidebarNode.nodeTypes.water_hot = `hot water`;
graphEN.sidebarNode.nodeTypes.water_cold = `cold water`;
graphEN.sidebarNode.nodeTypes.electricity = `electricity`;
graphEN.sidebarNode.nodeTypes.electricity_aux = `electricity aux`;
graphEN.sidebarNode.nodeTypes.electricity_heating = `electricity heating`;
graphEN.sidebarNode.nodeTypes.electricity_ecs = `electricity ecs`;
graphEN.sidebarNode.nodeTypes.electricity_light = `electricity light`;
graphEN.sidebarNode.nodeTypes.electricity_process = `electricity process`;
graphEN.sidebarNode.nodeTypes.electricity_other = `electricity other`;
graphEN.sidebarNode.nodeTypes.states = `states`;
graphEN.sidebarNode.nodeTypes.qrad = `qrad`;

graphEN.sidebarNode.nodeActions.addNode = `add node`;
graphEN.sidebarNode.nodeActions.editNode = `edit node`;
graphEN.sidebarNode.nodeActions.deleteNode = `delete node`;
graphEN.sidebarNode.nodeActions.addDevice = `add device`;
graphEN.sidebarNode.nodeActions.deleteDevice = `delete device`;
// sidebar data
graphEN.sidebarData.profile = `profile`;
graphEN.sidebarData.fetching = 'fetching device data';
graphEN.sidebarData.noData = 'no data for this profile';

graphEN.sidebarData.profiles['motion left'] = 'motion left';
graphEN.sidebarData.profiles['motion right'] = `motion right`;
graphEN.sidebarData.profiles['motion center'] = `motion center`;
graphEN.sidebarData.profiles['temperature target'] = `temperature target`;
graphEN.sidebarData.profiles['temperature heatsink'] = `temperature heatsink`;
graphEN.sidebarData.profiles['temperature air'] = `temperature air`;
graphEN.sidebarData.profiles.sound = `sound`;
graphEN.sidebarData.profiles.humidity = `humidity`;
graphEN.sidebarData.profiles.co2 = `CO2`;
graphEN.sidebarData.profiles.cov = `COV`;
graphEN.sidebarData.profiles.consumed = ``;
graphEN.sidebarData.profiles['power all'] = `power all`;
graphEN.sidebarData.profiles['power low'] = `power low`;
graphEN.sidebarData.profiles['power current all'] = `power current all`;
graphEN.sidebarData.profiles['power current low'] = `power current low`;
graphEN.sidebarData.profiles['power factor all'] = `power factor all`;
graphEN.sidebarData.profiles['power factor low'] = `power factor low`;
graphEN.sidebarData.profiles['power frequency all'] = `power frequency all`;
graphEN.sidebarData.profiles['power voltage all'] = `power voltage all`;
graphEN.sidebarData.profiles['consumed energy all'] = `consumed energy all`;
graphEN.sidebarData.profiles['power target'] = `power target`;
graphEN.sidebarData.profiles['sauter alarms'] = `sauter alarms`;
graphEN.sidebarData.profiles['sauter state'] = `sauter state`;
graphEN.sidebarData.profiles['sauter counter elec'] = `sauter counter elec`;
graphEN.sidebarData.profiles['sauter counter water'] = `sauter counter water`;
graphEN.sidebarData.profiles['hager covl'] = `COVL`;
graphEN.sidebarData.profiles['hager covg'] = `COVG`;
graphEN.sidebarData.profiles['hager aerosol'] = `aerosol`;
graphEN.sidebarData.profiles['flow to inside'] = `flow to inside`;
graphEN.sidebarData.profiles['flow to outside'] = `flow to outside`;
graphEN.sidebarData.profiles['flow target'] = `flow target`;
graphEN.sidebarData.profiles['temperature to inside'] = `temperature to inside`;
graphEN.sidebarData.profiles['temperature to outside'] = `temperature to outside`;
graphEN.sidebarData.profiles['temperature from inside'] = `temperature from inside`;
graphEN.sidebarData.profiles['temperature from outside'] = `temperature from outside`;
graphEN.sidebarData.profiles['temperature target'] = `temperature target`;

graphEN.sidebarSum.edit = 'Edit';
graphEN.sidebarSum.create = 'Create';
graphEN.sidebarSum.delete = 'Delete';
graphEN.sidebarSum.createSum = 'Create sum';
graphEN.sidebarSum.editSum = 'Edit this sum';
graphEN.sidebarSum.nameLabel = 'Name';
graphEN.sidebarSum.nameHint = 'Choose a name for your personalized sum';
graphEN.sidebarSum.labelProfile = 'Which profile do you want to use in sum ?';
graphEN.sidebarSum.deviceLabel = 'Devices';
graphEN.sidebarSum.deviceHint = 'Choose how devices are used in your personalized sum';
graphEN.sidebarSum.add = 'Add';
graphEN.sidebarSum.subtract = 'Subtract';
graphEN.sidebarSum.donotuse = 'Do not use';
graphEN.sidebarSum.value = 'Value';
graphEN.sidebarSum.deleteSum.dialogTitle = 'Delete this sum ?';
graphEN.sidebarSum.deleteSum.success = 'Summ successfully deleted';
graphEN.sidebarSum.deleteSum.error = 'Error while deleting sum';
export default graphEN;
