<template>
    <svg class="svg-logo"
          viewBox="-10 9 120 72">
      <path class="svg-logo__a"
            d="M66,13 L105,77 L28,77 Z" />
      <circle cx="26"
              cy="45"
              r="32"
              class="svg-logo__o" />
    </svg>
</template>
<script>
export default {
  name: 'OasisLoader',
};
</script>
<style lang="stylus" scoped>
@import '~variables'

.svg-logo__a
  fill none
  stroke $dark
  stroke-width 3.5px
  stroke-dasharray 225
  stroke-linecap round
  stroke-dashoffset 450px
  animation dash 1.5s ease forwards infinite
.svg-logo__o
  opacity 1
  fill none
  stroke $dark
  stroke-width 3.5px

@keyframes dash
  to
    stroke-dashoffset 0px

</style>
