import Vue from 'vue';
import api from 'api';

export default {
  namespaced: true,
  state: {
    collection: {
      users: [],
      usersFirst: [],
      features: [],
      nodes: [],
      oapps: [],
      dashboards: [],
    },
    selected: {
      user: {},
      features: [],
      nodes: [],
      oapps: [],
      dashboards: [],
    },
    editOapps: [],
    limit: 10,
  },
  mutations: {
    setUsersCollection(state, users) {
      state.collection.users = users;
      state.selected.user = users[0];
      state.collection.features.length = users.length;
      state.collection.oapps.length = users.length;
      state.collection.dashboards.length = users.length;
    },
    setusersFirst(state, users) {
      state.collection.usersFirst = [...users];
      state.collection.usersFirst.splice(state.limit);
    },
    setFeatureCollection(state, { data, index }) {
      Vue.set(state.collection.features, index, data);
    },
    setNodeCollection(state, { data, index }) {
      Vue.set(state.collection.nodes, index, data);
    },
    setOappCollection(state, { data, index }) {
      Vue.set(state.collection.oapps, index, data);
    },
    setDashboardCollection(state, { data, index }) {
      Vue.set(state.collection.dashboards, index, data);
    },
    setUser(state, user) {
      state.selected.user = user;
    },
    userSelectFirst(state, index) {
      const cp = [...state.collection.users];
      cp.splice(0, 0, state.collection.users[index]);
      cp.splice(index, 1);
      state.collection.users = cp;
      const splice = [...cp];
      splice.splice(state.limit);
      state.collection.usersFirst = splice;
    },
    oappSelectFirst(state, index) {
      const cp = [...state.collection.oapps];
      cp.splice(0, 0, state.collection.oapps[index]);
      cp.splice(index, 1);
      state.collection.oapps = cp;
    },
    dashboardSelectFirst(state, index) {
      const cp = [...state.collection.dashboards];
      cp.splice(0, 0, state.collection.dashboards[index]);
      cp.splice(index, 1);
      state.collection.dashboards = cp;
    },
    setFeatures(state, features) {
      state.selected.features = features;
    },
    setNodes(state, nodes) {
      state.selected.nodes = nodes;
    },
    setOapps(state, oapps) {
      state.selected.oapps = oapps;
    },
    setDashboards(state, dashboards) {
      state.selected.dashboards = dashboards;
    },
    addDashboard(state, { dashboard, index }) {
      state.collection.dashboards[index].push(dashboard);
    },
    swapFeaturesData(state, { index, position, userIndex }) {
      if (position === 0) {
        const val = !state.collection.features[userIndex][index].read;
        Vue.set(state.collection.features[userIndex][index], 'read', val);
      } else {
        const val = !state.collection.features[userIndex][index].write;
        Vue.set(state.collection.features[userIndex][index], 'write', val);
      }
    },
    swapOappsData(state, { index, position, userIndex }) {
      if (position === 0) {
        if (!state.editOapps.includes(index)) state.editOapps.push(index);
        else state.editOapps.splice(state.editOapps.indexOf(index), 1);
        const val = !state.collection.oapps[userIndex][index].installed;
        Vue.set(state.collection.oapps[userIndex][index], 'installed', val);
      }
    },
    addFeature(state, { feature, userIndex }) {
      state.collection.features[userIndex].push(feature);
    },
    updateFeature(state, { feature, userIndex }) {
      const index = state.collection.features[userIndex].findIndex(f => {
        return f.featureId === feature.featureId;
      });
      state.collection.features[userIndex].splice(index, 1, feature);
    },
    deleteFeature(state, { featureId, userIndex }) {
      const index = state.collection.features[userIndex].findIndex(u => {
        return u.uuid === featureId;
      });
      state.collection.features[userIndex].splice(index - 1, 1);
    },
  },
  actions: {
    async fetchUsers({ commit }) {
      const { data: listUsers } = await api.admin.users.fetch();
      commit('setUsersCollection', listUsers);
      commit('setusersFirst', listUsers);
    },

    async changeUserSelected({ commit, state }, index) {
      commit('setUser', state.collection.users[index]);
      if (index > state.limit) {
        commit('userSelectFirst', index);
        commit('oappSelectFirst', index);
        commit('dashboardSelectFirst', index);
      }
      if (!state.collection.features[index] || !state.collection.oapps[index] || !state.collection.dashboards[index]) {
        const { data: features } = await api.admin.user.getFeatures(state.collection.users[index].uuid);
        const { data: nodes } = await api.admin.user.getNodes(state.collection.users[index].uuid);
        const { data: oapps } = await api.admin.user.getOapps(state.selected.user.uuid);
        const { data: dashboards } = await api.admin.user.getDashboards(state.selected.user.uuid);
        commit('setDashboardCollection', { data: dashboards, index: index });
        commit('setOappCollection', { data: oapps, index: index });
        commit('setFeatureCollection', { data: features, index: index });
        commit('setNodeCollection', { data: nodes, index: index });
      }
      commit('setFeatures', state.collection.features[index]);
      commit('setNodes', state.collection.nodes[index]);
      commit('setDashboards', state.collection.dashboards[index]);
      commit('setOapps', state.collection.oapps[index]);
    },
    copyDashboard({ commit, state }, dashboard) {
      const index = state.collection.users.findIndex(u => {
        return u.uuid === dashboard.userUuid;
      });
      if (state.collection.dashboards[index]) {
        commit('addDashboard', { index: index, dashboard: dashboard });
      }
    },

    swapDatas({ commit, state }, { position, index, uuid, tab }) {
      const userIndex = state.collection.users.findIndex(u => {
        return u.uuid === uuid;
      });
      tab = tab.charAt(0).toUpperCase() + tab.slice(1);
      commit(`swap${tab}Data`, { index: index, position: position, userIndex: userIndex });
    },

    async deleteElem({ commit, state }, featureId) {
      const userIndex = state.collection.users.findIndex(u => {
        return u.uuid === state.selected.user.uuid;
      });
      await api.admin.features.deleteFeature({ featureId: featureId });
      commit('deleteFeature', { featureId: featureId, userIndex: userIndex });
    },

    updateFeature({ commit, state }, { feature, action }) {
      const userIndex = state.collection.users.findIndex(u => {
        return u.uuid === state.selected.user.uuid;
      });
      if (action === 'create') commit('addFeature', { feature: feature, userIndex: userIndex });
      else commit('updateFeature', { feature: feature, userIndex: userIndex });
    },
  },
};
