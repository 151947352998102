import Vue from 'vue';
import api from 'api';
import axios from 'axios';
import Oapp from 'models/oapp';
const getDefaultState = () => ({
  collection: [],
  currentOapp: undefined,
  fetching: false,
  sidebarExpanded: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    installed: state => state.collection.filter(oapp => oapp.installed),
    available: state => state.collection,
  },
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    setCollection: (state, collection) => Vue.set(state, 'collection', collection),
    setCurrentOapp: (state, oappId) => {
      state.currentOapp = state.collection.find(oapp => oapp.id === oappId);
    },
    updateFetching(state, value) {
      state.fetching = value;
    },
    update: (state, { oappId, data}) => {
      const oapp = state.collection.find(o => o.id === oappId);
      if (!oapp) return;
      Object.keys(data).forEach(k => {
        Vue.set(oapp, k, data[k]);
      });
    },
    toggleSidebarExpanded(state) {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    fetch: async ({ commit, rootState }) => {
      let oapps = undefined;
      if (rootState.user.user.featuresRights.store.READ) {
        oapps = (await api.oapp.fetch()).data;
      }
      else if (rootState.user.user.featuresRights.oapp.READ) {
        oapps = (await api.oapp.fetchInstalled()).data;
      }
      else {
        return;
      }
      const newCollection = oapps.map(oapp => new Oapp(oapp));
      commit('setCollection', newCollection);
    },
    async install({ state, commit }, oappId) {
      const oapp = state.collection.find(o => o.id === oappId);
      if (!oapp) return;
      await api.oapp.install(oappId);
      commit('update', { oappId, data: { installed: true } });
    },
    async uninstall({ state, commit }, oappId) {
      const oapp = state.collection.find(o => o.id === oappId);
      if (!oapp) return;
      await api.oapp.uninstall(oappId);
      commit('update', { oappId, data: { installed: false } });
    },
    putCurrentOappData({ state, rootState, commit, rootGetters }, options) {
      const curSel = rootGetters['selections/filteredSelection'];
      const currentoapp = state.currentOapp.id.toLowerCase();
      return api.oapp.data.update(currentoapp, state.oapps[currentoapp].type, {
        selection: curSel,
        ...options,
      });
    },
    async fetchCurrentOappData({ getters, state, rootState, commit, rootGetters }) {
      const curSel = rootGetters['selections/filteredSelection'];
      const currentOapp = state.currentOapp;
      if (!currentOapp || !Object.keys(curSel).length || !rootGetters['selections/devices'].length) {
        commit('updateFetching', false);
        if (api.oapp.sourceCancel) api.oapp.sourceCancel.cancel();
        return;
      }
      //const currentoapp = state.currentOappId;
      try {
        if(state.fetching === false) {
          commit('updateFetching', true);
          if (rootState.selections.time.range !== 'live') {
            commit('update', { oappId: currentOapp.id, data: { data: null } });
            const { data } = await api.oapp.data.read(currentOapp.id, currentOapp.type, {
              from: rootState.selections.time.from,
              to: rootState.selections.time.to,
              selection: curSel,
            });
            if (state.currentOapp.id !== '' && Object.keys(curSel).length) commit('update', { oappId: currentOapp.id, data: { data } });
          } else {
            const { data } = await api.oapp.last.read(currentOapp.id, currentOapp.type, {
              selection: curSel,
            });
            commit('update', { oappId: currentOapp.id, data: { data } });
          }
          commit('updateFetching', false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          commit('updateFetching', false);
          commit('update', { oappId: currentOapp.id, data: { data: null } });
        }
      }
    },
  },
};
