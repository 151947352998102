
import securedAxios from '../securedAxios';

const endpoints = {
  private: '/api/tickets',
  public: '/public/tickets'
};
const Tickets = {
  private: {
    read(shortId) {
      return securedAxios.get(`${endpoints.private}/${shortId}`);
    },
    delete(shortId) {
      return securedAxios.delete(`${endpoints.private}/${shortId}`);
    },
    fetch() {
      return securedAxios.get(`${endpoints.private}`);
    },
    create(ticket) {
      return securedAxios.post(`${endpoints.private}`, ticket);
    },
    update(shortId, ticket) {
      return securedAxios.patch(`${endpoints.private}/${shortId}`, ticket);
    },
    global: {
      getTypes() {
        return securedAxios.get(`${endpoints.private}/types`);
      },
      getStatus() {
        return securedAxios.get(`${endpoints.private}/status`);
      },
      getPriorities() {
        return securedAxios.get(`${endpoints.private}/priorities`);
      },
    },
    file: {
      delete(shortId, docId) {
        return securedAxios.delete(`${endpoints.private}/${shortId}/documents/${docId}`);
      },
    }
  },
  public: {
    read(shortId) {
      return securedAxios.get(`${endpoints.public}/${shortId}`);
    },
    update(shortId, ticket) {
      return securedAxios.patch(`${endpoints.public}/${shortId}`, ticket);
    },
    create(ticket) {
      return securedAxios.post(`${endpoints.public}`, ticket);
    },
    getTypes() {
      return securedAxios.get(`${endpoints.public}/types`);
    },
    getPriorities() {
      return securedAxios.get(`${endpoints.public}/priorities`);
    },
    file: {
      create(shortId, files) {
        return securedAxios.post(`${endpoints.public}/${shortId}/documents`, files, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      },
      read(ticketId, fileId) {
        return securedAxios.get(`${endpoints.public}/${ticketId}/documents/${fileId}`, { responseType: 'arraybuffer', encoding: null });
      },
    },
    comments: {
      create(shortId, { message }) {
        return securedAxios.post(`${endpoints.public}/${shortId}/comments`, { message });
      },
      fetch(shortId) {
        return securedAxios.get(`${endpoints.public}/${shortId}/comments`);
      },
    }
  },
};

export default Tickets;
