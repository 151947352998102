import _ from 'lodash';
import dashboard from './dashboard-locale.json';
const dashboardFR = _.cloneDeep(dashboard);

// new dashbaord
dashboardFR.newDashboard.name = 'nouveau dashboard';
// options
dashboardFR.options.title = 'options';
dashboardFR.options.editName = 'renommer';
dashboardFR.options.deleteDashboard = 'supprimer';
dashboardFR.options.done = 'terminer';
dashboardFR.options.selectDashboard = 'Sélectionnez un dashboard à afficher';
// widget creation
dashboardFR.widgetCreation.button = 'Ajouter un widget';
// toasts
dashboardFR.toasts.dashboardCreated = 'Création du dashboard réussie';
dashboardFR.toasts.errorDashboardCreation = 'Erreur à la création du dashboard';
dashboardFR.toasts.dashboardUpdated = 'Dashboard à jour';
dashboardFR.toasts.errorDashboardUpdate = 'Erreur pendant la mise à jour du dashboard';
dashboardFR.toasts.dashboardDeleted = 'Le dashboard a bien été supprimé';
dashboardFR.toasts.errorDashboardDeleted = 'Erreur durant la suppression du dashboard';
dashboardFR.toasts.widgetAddedToDashboard = 'Widget ajouté au dashboard';
dashboardFR.toasts.errorAddingWidgetToDashboard = "Erreur lors de l'ajout du widget";
// pop-up
dashboardFR.popUp.create = 'Créer nouveau dashboard';
dashboardFR.popUp.noDashboard = "Vous n'avez pas de dashboard, vous pouvez en créer un dans le menu du dashboard";
dashboardFR.popUp.requestDashboard = "Vous n'avez accès à aucun dashboard, vous pouvez demandez des accès à votre administrateur";
// add dashboard
dashboardFR.addDashboard.dashboardName = 'nom du dashboard';
dashboardFR.addDashboard.enterDashboardName = 'Entrez le nom du dashboard';
dashboardFR.addDashboard.emptyDashboardName = 'Vous devez entrer un nom, il ne peut pas être vide';
dashboardFR.addDashboard.dashboardNameExist = 'Vous avez déjà un dashboard appelé ';
dashboardFR.addDashboard.createDashboard = 'Créer le dashboard';

export default dashboardFR;
