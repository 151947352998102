import securedAxios from '../securedAxios';

const Widget = {
  endpoint: '/api/dashboard',
  create(dashboardId, widget) {
    return securedAxios.post(`${this.endpoint}/${dashboardId}/${widget.id}`, widget);
  },
  update({ id, name, x, y, w, h, config }) {
    return securedAxios.patch(`${this.endpoint}/widget/${id}`, { name, x, y, w, h, config });
  },
  delete(widgetId) {
    return securedAxios.delete(`${this.endpoint}/widget/${widgetId}`);
  },
  data: {
    read(dashboardWidgetId) {
      return securedAxios.post(`/api/dashboard-widgets/${dashboardWidgetId}`);
    },
  }
};

export default Widget;
