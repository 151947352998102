export default {
  data() {
    return {
      colors: {
        primary: '#F7BBBF',
        secondary: '#373737',
        black: '#000000',
        dark: '#464749',
        grey: '#B4B2A9',
        silver: '#DED9D2',
        light: '#F3F1EF',
        darkPink: '#f3afb3',
        infos: '#8a8686',
        neutral: '#E0E1E2',
        positive: '#21BA45',
        negative: '#E91E63',
        info: '#31CCEC',
        warning: '#F2C037',
        bar: [
          '#6641ff',
          '#2de291',
          '#009de9',
          '#cced00',
        ],
      },
    };
  },
};
