import Vue from 'vue';
import DeviceDot from '@/app/components/devices/device-dot.vue';
import UtilsMixin from '@/app/mixins/utils';

export default {
  mixins: [UtilsMixin],
  data() {
    return {
      movingDevice: false,
    };
  },
  methods: {
    updateDeviceDotMode(viewerMode) {
      const deviceDotList = [...document.querySelectorAll('.device')];
      deviceDotList.map(d => {
        d.__vue__.viewerMode = viewerMode;
      });
    },
    updateDeviceDotSelection() {
      this.currentBuilding.devices.forEach(device => {
        const floor = this.getFloorFromSpaceId(device.space);
        if (this.objectIsEmpty(this.filteredSelection) || !this.filteredSelection[this.currentBuilding.uuid]) return;
        const deviceElem = document.getElementById(device.uuid);
        if(!deviceElem) return;
        if (
          (this.filteredSelection[this.currentBuilding.uuid] &&
            this.filteredSelection[this.currentBuilding.uuid][floor.uuid] &&
            this.filteredSelection[this.currentBuilding.uuid][floor.uuid].length === 0) ||
          this.spaceIsSelected(device.space)
        ) {
          deviceElem.__vue__.selected = true;
          if (this.currentOapp && this.currentOapp.data && this.currentOapp.data && this.currentOapp.type === 'custom') {
            Object.keys(this.currentOapp.data.values.spaces).forEach(s =>
              this.currentOapp.data.values.spaces[s].forEach(l => {
                if (l.light.uniqueid === device.device) {
                  deviceElem.__vue__.color = `#${l.light.state.hex}`;
                } else {
                  deviceElem.__vue__.color = 'black';
                }
              })
            );
          } else deviceElem.__vue__.color = 'black';
        } else {
          deviceElem.__vue__.selected = false;
          deviceElem.__vue__.color = 'black';
        }
      });
    },
    setDevicesOnSpaces(deviceList) {
      deviceList.map(d => {
        const space = this.$palmier.getSpace(d.space);
        let device;
        // if no coord, set device to orange color and place it at center
        if (d.x === 0 && d.y === 0) {
          // get spaceElem text thas is already centered
          const spaceTextElem = document.querySelector(`.space-name#${d.space}`);
          const centeredCoord = {
            x: spaceTextElem.x.baseVal[0].value,
            y: spaceTextElem.y.baseVal[0].value,
          };
          // use thoses coord to center the device
          device = this.createDeviceDot(d.uuid, centeredCoord.x, centeredCoord.y, null, 8, '#fa983a');
        } else device = this.createDeviceDot(d.uuid, d.x, d.y);
        space.parentElement.insertBefore(device.$el, space.nextSibling);
      });
    },
    saveDevice() {
      this.setCurrentDevice({ created: true });
      this.popup.show = false;
      this.$store.dispatch('oapp/fetchCurrentOappData');
    },
    setCurrentDevice({ uuid, positionX, positionY, description, created }) {
      this.currentDevice.uuid = null;
      this.popup.show = false;
      this.currentDevice.uuid = uuid;
      this.currentDevice.positionX = positionX || 0;
      this.currentDevice.positionY = positionY || 0;
      this.currentDevice.description = description || '';
      this.currentDevice.created = created || false;
    },
    removeDeviceDot(uuid) {
      const device = document.getElementById(uuid);
      if (!device) return;
      const parent = device.parentElement;
      device.__vue__.$destroy();
      parent.removeChild(device);
      this.setCurrentDevice({ uuid: null });
      this.popup.show = false;
      this.$store.dispatch('oapp/fetchCurrentOappData');
    },
    moveDeviceDot(device) {
      this.popup.show = false;
      const deviceElem = document.getElementById(device.uuid);
      if (!deviceElem) return;
      const parent = deviceElem.parentElement;
      deviceElem.__vue__.$destroy();
      parent.removeChild(deviceElem);
      this.currentDevice.space = device.space;
      this.currentDevice.plug = device.plug;
      this.movingDevice = true;
    },
    createDeviceDot(uuid, positionX, positionY, parentSVG = null, radius = 8, color = 'black') {
      if (parentSVG) {
        const pt = parentSVG.createSVGPoint();
        pt.x = positionX;
        pt.y = positionY;
        const deviceCoord = pt.matrixTransform(parentSVG.getScreenCTM().inverse());
        positionX = deviceCoord.x;
        positionY = deviceCoord.y;
      }
      const DeviceDotClass = Vue.extend(DeviceDot);
      // component created this way are not reactive !!!
      const device = new DeviceDotClass({
        propsData: {
          x: positionX,
          y: positionY,
          deviceUuid: uuid,
          radius,
          color,
        },
      });
      device.$mount();
      return device;
    },
    showPopup(positionX, positionY) {
      const viewerRect = this.$refs.viewer.getBoundingClientRect();
      this.popup.show = true;
      this.popup.positionX = positionX - viewerRect.left;
      this.popup.positionY = positionY - viewerRect.top;
      this.popup.left = this.popup.positionX > viewerRect.width / 2;
      this.popup.top = this.popup.positionY > viewerRect.height / 2;
    },
    isSpaceToEdit: (currentSpace, space) => space !== null && currentSpace.idIFC === space.id,
    isDevice: evt => evt.target.classList.contains('device'),
  },
};
