/**
 * topology structure: Root -> countries -> cities -> buildings -> floors -> rooms -> plugs -> devices
 */

export default {
  methods: {
    async getRoot() {
      if (!this.$store.state.topology.root) {
        await this.$store.dispatch('topology/fetch');
      }
      return this.$store.state.topology.root;
    },
    // get topology until building
    async getPathToBuilding(buildingUuid) {
      let buildingTopology = {};
      const topologyRoot = await this.getRoot();
      // Countries
      topologyRoot.next.map(country => {
        if (buildingTopology.building || !country.next) return;
        // Cities
        country.next.map(city => {
          if (buildingTopology.building || !city.next) return;
          // Buildings
          city.next.map(building => {
            if (building.uuid === buildingUuid) {
              buildingTopology = ({
                country: {uuid: country.uuid, name: country.name },
                city: { uuid: city.uuid, name: city.name },
                building: { uuid: building.uuid, name: building.name },
              });
            }
          });
        });
      });
      return buildingTopology;
    },
    // get topology until floor
    async getPathToFloor(floorUuid) {
      let floorTopology = {};
      const topologyRoot = await this.getRoot();
      // Countries
      topologyRoot.next.map(country => {
        if (floorTopology.floor || !country.next) return;
        // Cities
        country.next.map(city => {
          if (floorTopology.floor || !city.next) return;
          // Buildings
          city.next.map(building => {
            if (floorTopology.floor || !building.next) return;
            // Floors
            building.next.map(floor => {
              if (floor.uuid === floorUuid) {
                const floorSvg = this.$store.state.building.floors[floor.uuid].metadata.svg;
                floorTopology = ({
                  country: {uuid: country.uuid, name: country.name },
                  city: { uuid: city.uuid, name: city.name },
                  building: { uuid: building.uuid, name: building.name },
                  floor: { uuid: floor.uuid, name: floor.name, svg: floorSvg },
                });
              }
            });
          });
        });
      });
      return floorTopology;
    },
    // get topology until room
    async getPathToRoom(roomUuid) {
      let roomTopology = {};
      const topologyRoot = await this.getRoot();
      // Countries
      topologyRoot.next.map(country => {
        if (roomTopology.room || !country.next) return;
        // Cities
        country.next.map(city => {
          if (roomTopology.room || !city.next) return;
          // Buildings
          city.next.map(building => {
            if (roomTopology.room || !building.next) return;
            // Floors
            building.next.map(floor => {
              if (roomTopology.room || !floor.next) return;
              // Rooms
              floor.next.map(room => {
                if (room.uuid === roomUuid) {
                  const floorSvg = this.$store.state.building.floors[floor.uuid].metadata.svg;
                  roomTopology = ({
                    country: {uuid: country.uuid, name: country.name },
                    city: { uuid: city.uuid, name: city.name },
                    building: { uuid: building.uuid, name: building.name },
                    floor: { uuid: floor.uuid, name: floor.name, svg: floorSvg },
                    room: { uuid: room.uuid, name: room.name },
                  });
                }
              });
            });
          });
        });
      });
      return roomTopology;
    },
    // get topology until plug
    async getPathToPlug(plugUuid) {
      let plugTopology = {};
      const topologyRoot = await this.getRoot();
      // Countries
      topologyRoot.next.map(country => {
        if (plugTopology.plug || !country.next) return;
        // Cities
        country.next.map(city => {
          if (plugTopology.plug || !city.next) return;
          // Buildings
          city.next.map(building => {
            if (plugTopology.plug || !building.next) return;
            // Floors
            building.next.map(floor => {
              if (plugTopology.plug || !floor.next) return;
              // Rooms
              floor.next.map(room => {
                if (plugTopology.plug || !room.next) return;
                // Plugs
                room.next.map(plug => {
                  if (plug.uuid === plugUuid) {
                    const floorSvg = this.$store.state.building.floors[floor.uuid].metadata.svg;
                    plugTopology = ({
                      country: {uuid: country.uuid, name: country.name },
                      city: { uuid: city.uuid, name: city.name },
                      building: { uuid: building.uuid, name: building.name },
                      floor: { uuid: floor.uuid, name: floor.name, svg: floorSvg },
                      room: { uuid: room.uuid, name: room.name },
                      plug: { uuid: plug.uuid, name: plug.name },
                    });
                  }
                });
              });
            });
          });
        });
      });
      return plugTopology;
    },
    // get topology until device
    async getPathToDevice(deviceUuid) {
      let deviceTopology = {};
      const topologyRoot = await this.getRoot();
      // Countries
      topologyRoot.next.map(country => {
        if (deviceTopology.device || !country.next) return;
        // Cities
        country.next.map(city => {
          if (deviceTopology.device || !city.next) return;
          // Buildings
          city.next.map(building => {
            if (deviceTopology.device || !building.next) return;
            // Floors
            building.next.map(floor => {
              if (deviceTopology.device || !floor.next) return;
              // Rooms
              floor.next.map(room => {
                if (deviceTopology.device || !room.next) return;
                // Plugs
                room.next.map(plug => {
                  if (deviceTopology.device || !plug.next) return;
                  // Devices
                  plug.next.map(device => {
                    if (device.uuid === deviceUuid) {
                      const floorSvg = this.$store.state.building.floors[floor.uuid].metadata.svg;
                      deviceTopology = ({
                        country: {uuid: country.uuid, name: country.name },
                        city: { uuid: city.uuid, name: city.name },
                        building: { uuid: building.uuid, name: building.name },
                        floor: { uuid: floor.uuid, name: floor.name, svg: floorSvg },
                        room: { uuid: room.uuid, name: room.name },
                        plug: { uuid: plug.uuid, name: plug.name },
                        device: { uuid: device.uuid, name: device.name },
                      });
                    }
                  });
                });
              });
            });
          });
        });
      });
      return deviceTopology;
    },
    async getPathToNode(requestedNodeId, includeSVG) {
      const topologyRoot = await this.getRoot();
      const recursePath = (currentNodes, result = {}) => {
        for (const nextNode of currentNodes) {
          if (nextNode.uuid === requestedNodeId) {
            // means we found the right node
            result[nextNode.nodeType] = {
              uuid: nextNode.uuid,
              name: nextNode.name,
            };
            if (includeSVG && this.$store.state.building.floors[nextNode.uuid]) {
              result[nextNode.nodeType].svg = this.$store.state.building.floors[nextNode.uuid].metadata.svg;
            }
            return { result, found: true };
          }
          if (nextNode.next) {
            // means we haven't found the result node yet
            // so we keep going deeper
            const res = recursePath(nextNode.next, result);
            if (res && res.found) {
              // means we've found the right node deeper and we're going back up
              result[nextNode.nodeType] = {
                uuid: nextNode.uuid,
                name: nextNode.name,
              };
              if (includeSVG && this.$store.state.building.floors[nextNode.uuid]) {
                result[nextNode.nodeType].svg = this.$store.state.building.floors[nextNode.uuid].metadata.svg;
              }
              return { result, found: true };
            }
          }
        }
        return { result, found: false };
      };
      const { result: path, found } = recursePath([topologyRoot]);
      path.nodeFound = found;
      return path;
    },
    async getFullPathToNode(requestedNodeId, includeSVG) {
      const topologyRoot = await this.getRoot();
      const recursePath = (currentNodes, result = []) => {
        for (const nextNode of currentNodes) {
          if (nextNode.uuid === requestedNodeId) {
            // means we found the right node
            result.push(nextNode);
            return { result, found: true };
          }
          if (nextNode.next) {
            // means we haven't found the result node yet
            // so we keep going deeper
            const res = recursePath(nextNode.next, result);
            if (res && res.found) {
              // means we've found the right node deeper and we're going back up
              result.push(nextNode);
              return { result, found: true };
            }
          }
        }
        return { result, found: false };
      };
      const { result } = recursePath([topologyRoot]);
      return result;
    },
  },
};