import Vue from 'vue';
import api from '@/services/api';
import { ApiNotification } from '@/types/api';
import { Module } from 'vuex/types';
import store, { RootStoreState } from '..';
import { HttpMetadata, HttpMetadataDefaults } from '@/types/http';
import { errorMessageFromApiError } from '@/services/api/utils';

export interface NotificationsStoreState {
  meta: HttpMetadata,
  collection: ApiNotification[];
}

const getDefaultState = () : NotificationsStoreState => ({
  meta: {
    ...HttpMetadataDefaults
  },
  collection: [],
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    set: (state, { collection, meta }: Partial<NotificationsStoreState>) => {
      if (collection !== undefined) {
        Vue.set(state, 'collection', collection);
      }

      if (meta !== undefined) {
        Vue.set(state, 'meta', { ...state.meta, ...meta });
      }
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async fetch({ commit, state }) {
      // avoid fetching if we're already doing it
      if (state.meta.isLoading) {
        return;
      }

      // avoid re-loading if data is fresh (loaded less than 5 secs ago)
      if (
        state.meta.lastFetch !== null &&
        (new Date().getTime() - state.meta.lastFetch.getTime() < 5000) &&
        state.meta.error === null
      ) {
        return;
      }

      commit('set', {
        meta: {
          isLoading: true,
          error: null,
        }
      });

      try {
        const fetchResult = await api.notification.fetch();
        commit('set', {
          collection: fetchResult.data,
          meta: {
            lastFetch: new Date(),
            isLoading: false,
            error: null,
          }
        });
      } catch (err) {
        commit('set', {
          meta: {
            isLoading: false,
            error: errorMessageFromApiError(err as Error),
          }
        });
      }
    },
    async setReadStatusForAll(
      { commit, state },
      { readStatus }: { readStatus: boolean })
    {
      const res = await api.notification.patchAll({ read: readStatus });

      // manually update the store instead of re-fetching
      const updatedDateRead = readStatus ? new Date(): null;
      commit('set', {
        collection: state.collection.map(item => ({
          ...item,
          dateRead: updatedDateRead,
        })),
      });

      return { updatedCount: res.data.data.updatedCount };
    },
    async setReadStatusById({ commit, state }, { notificationId, readStatus } : { notificationId: number, readStatus: boolean }) {
      await api.notification.patchById(notificationId, { read: readStatus });

      // manually update the store instead of re-fetching
      const updatedDateRead = readStatus ? new Date(): null;
      commit('set', {
        collection:
          state.collection.map(item => (
            item.id === notificationId ?
              {
                ...item,
                dateRead: updatedDateRead,
              }
              :
              {
                ...item
              }
          )),
      });
    }
  }
} as Module<NotificationsStoreState, RootStoreState>;
