import Vue from 'vue';
const getDefaultState = () => ({
  time: {
    from: null,
    to: null,
    range: null,
    timeline: null,
  },
  buildings: {},
  filters: {},
  zones: [],
});
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    filteredBuildings: (state, getters, rootState) => {
      if (Object.keys(state.filters).length === 0 || Object.keys(state.filters).every(k => state.filters[k].length === 0))
        return state.buildings;
      const newSelection = {};
      Object.keys(state.buildings).forEach(b => {
        Object.keys(state.buildings[b]).forEach(f => {
          if (state.buildings[b][f].length === 0) {
            const building = rootState.building.collection.find(build => build.uuid === b);
            const floor = building.next.find(fl => fl.uuid === f);
            floor.next.forEach(sp => {
              if (
                sp.properties &&
                Object.keys(state.filters).every(k => {
                  return state.filters[k].length === 0 || (k in sp.properties && state.filters[k].includes(sp.properties[k]));
                })
              )
                if (!newSelection[b]) newSelection[b] = { [f]: [sp] };
                else if (!(f in newSelection[b])) newSelection[b][f] = [sp];
                else newSelection[b][f].push(sp);
            });
          } else
            state.buildings[b][f].forEach(s => {
              if (
                s.properties &&
                Object.keys(state.filters).every(k => {
                  return state.filters[k].length === 0 || (k in s.properties && state.filters[k].includes(s.properties[k]));
                })
              )
                if (!newSelection[b]) newSelection[b] = { [f]: [s] };
                else if (!(f in newSelection[b])) newSelection[b][f] = [s];
                else newSelection[b][f].push(s);
            });
        });
      });
      return newSelection;
    },
    spacesInFilters: (state, getters, rootState) => {
      //returns all the spaces included in filters
      const selectSpaces = [];
      rootState.building.collection.forEach(b => {
        b.next.forEach(f => {
          f.next.forEach(s => {
            if (
              (!s.properties && Object.keys(state.filters).length === 0) ||
              (s.properties &&
                Object.keys(state.filters).every(k => {
                  return state.filters[k].length === 0 || (k in s.properties && state.filters[k].includes(s.properties[k]));
                }))
            )
              selectSpaces.push(s);
          });
        });
      });
      return selectSpaces;
    },
    selectInFilters: (state, getters, rootState) => select => {
      // returns the spaces of the select param if included in filters
      const spacesInFilters = [];
      //return null if no current filters enabled
      if (
        Object.keys(state.filters).length === 0 ||
        Object.keys(state.filters).every(k => {
          return state.filters[k].length === 0;
        })
      )
        return null;

      if ('floor' in select && !('space' in select)) {
        const building = rootState.building.collection.find(x => x.uuid === select.building);
        const floor = building.next.find(x => x.uuid === select.floor);
        floor.next.forEach(s => {
          if (
            s.properties &&
            Object.keys(state.filters).every(k => {
              return state.filters[k].length === 0 || (k in s.properties && state.filters[k].includes(s.properties[k]));
            })
          )
            spacesInFilters.push(s);
        });
      } else if ('space' in select) {
        if (
          select.space.properties &&
          Object.keys(state.filters).every(k => {
            return state.filters[k].length === 0 || (k in select.space.properties && state.filters[k].includes(select.space.properties[k]));
          })
        )
          spacesInFilters.push(select.space);
      }
      return spacesInFilters;
    },
    filteredSelection: (state, getters, rootState) => {
      if (Object.keys(state.filters).length === 0 || Object.keys(state.filters).every(k => state.filters[k].length === 0))
        return state.buildings;
      const newSelection = {};
      Object.keys(state.buildings).forEach(b => {
        Object.keys(state.buildings[b]).forEach(f => {
          if (state.buildings[b][f].length === 0) {
            const building = rootState.building.collection.find(build => build.uuid === b);
            const floor = building.next.find(fl => fl.uuid === f);
            floor.next.forEach(sp => {
              if (
                sp.properties &&
                Object.keys(state.filters).every(k => {
                  return state.filters[k].length === 0 || (k in sp.properties && state.filters[k].includes(sp.properties[k]));
                })
              )
                if (!newSelection[b]) newSelection[b] = { [f]: [sp] };
                else if (!(f in newSelection[b])) newSelection[b][f] = [sp];
                else newSelection[b][f].push(sp);
            });
          } else
            state.buildings[b][f].forEach(s => {
              if (
                s.properties &&
                Object.keys(state.filters).every(k => {
                  return state.filters[k].length === 0 || (k in s.properties && state.filters[k].includes(s.properties[k]));
                })
              )
                if (!newSelection[b]) newSelection[b] = { [f]: [s] };
                else if (!(f in newSelection[b])) newSelection[b][f] = [s];
                else newSelection[b][f].push(s);
            });
        });
      });
      return newSelection;
    },
    info: (state, getters, rootState) => {
      let buildings = 0;
      let floors = 0;
      let spaces = 0;
      let surfaces = 0;
      let sensors = 0;
      const curSel = getters.filteredSelection;
      Object.keys(curSel).forEach(buildingKey => {
        buildings++;
        const floorsKeys = Object.keys(curSel[buildingKey]);
        floors += floorsKeys.length;

        const building = rootState.building.collection.find(b => b.uuid === buildingKey);
        Object.keys(curSel[buildingKey]).forEach(f => {
          const selectSpaces = curSel[buildingKey][f].length;
          const curFloor = building.next.find(fl => fl.uuid === f);
          if (selectSpaces > 0) {
            // some spaces of the floor (whitelisted) are selected
            spaces += selectSpaces;
            curSel[buildingKey][f].forEach(s => {
              const curSpace = curFloor.whitelist.find(sp => sp.uuid === s.uuid);
              surfaces += curSpace ? curSpace.metadata.surface : 0;
              building.devices.forEach(d => {
                if (d.space === s.idIFC) sensors++;
              });
            });
          } else {
            // all floor (whitelisted) is selected
            spaces += curFloor ? curFloor.whitelist.length : 0;
            if (curFloor.whitelist.length === 0) return;
            curFloor.next.forEach(s => {
              surfaces += s.metadata && s.metadata.surface ? s.metadata.surface : 0;
              building.devices.forEach(d => {
                if (d.space === s.idIFC) sensors++;
              });
            });
          }
        });
      });
      return { buildings, floors, spaces, surfaces, sensors };
    },
    devices: (state, getters, rootState) => {
      const selectedSpaces = getters.selectedSpaces;
      if (selectedSpaces.length === 0) return [];
      const buildings = rootState.building.collection;
      const allDevices = [];
      const currentDevices = [];
      buildings.forEach(b => {
        allDevices.push(...b.devices);
      });
      selectedSpaces.forEach(s => {
        const device = allDevices.find(d => d.space === s.idIFC);
        if (device) currentDevices.push(device);
      });
      return currentDevices;
    },
    selectedSpaces: (state, getters, rootState) => {
      const spaces = [];
      const curSel = getters.filteredSelection;
      Object.keys(curSel).forEach(b => {
        const building = rootState.building.collection.find(build => build.uuid === b);
        Object.keys(curSel[b]).forEach(f => {
          const selectSpaces = curSel[b][f].length;
          const curFloor = building.next.find(fl => fl.uuid === f);
          if (selectSpaces)
            curSel[b][f].forEach(s => {
              const curSpace = curFloor.next.find(sp => sp.uuid === s.uuid);
              const properties = { access: 'none', type: 'none' };
              if (curSpace.properties) {
                properties.access = curSpace.properties.access || 'none';
                properties.type = curSpace.properties.type || 'none';
              }
              spaces.push({ uuid: curSpace.uuid, name: curSpace.name, properties, idIFC: curSpace.idIFC });
            });
          else
            curFloor.next.forEach(s => {
              const curSpace = curFloor.next.find(sp => sp.uuid === s.uuid);
              const properties = { access: 'none', type: 'none' };
              if (curSpace.properties) {
                properties.access = curSpace.properties.access || 'none';
                properties.type = curSpace.properties.type || 'none';
              }
              spaces.push({ uuid: curSpace.uuid, name: curSpace.name, properties, idIFC: curSpace.idIFC });
            });
        });
      });
      return spaces;
    },
  },
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    setTime: (state, { from, to }) => {
      state.time.from = from;
      state.time.to = to;
    },
    setTimeRange: (state, range) => {
      state.time.range = range;
    },
    setTimeline: (state, date) => {
      state.time.timeline = date;
    },
    selectBuilding: (state, selection) => {
      if ('building' in selection && !(selection.building in state.buildings)) Vue.set(state.buildings, selection.building, {});
      if ('floor' in selection && !(selection.floor in state.buildings[selection.building]))
        Vue.set(state.buildings[selection.building], selection.floor, []);
      if ('space' in selection && !state.buildings[selection.building][selection.floor].includes(selection.space))
        state.buildings[selection.building][selection.floor].push(selection.space);
    },
    unselectBuilding: (state, selection) => {
      if (!selection) Vue.set(state, 'buildings', {});
      if (!selection || !(selection.building in state.buildings)) return;
      if ('building' in selection && !('floor' in selection)) Vue.delete(state.buildings, selection.building);
      if ('floor' in selection && !('space' in selection)) {
        if (state.buildings[selection.building]) {
          Vue.delete(state.buildings[selection.building], selection.floor);
          if (Object.keys(state.buildings[selection.building]).length === 0) Vue.delete(state.buildings, selection.building);
        }
      }
      if ('space' in selection) {
        if (!(selection.floor in state.buildings[selection.building])) return;
        const spaceIndex = state.buildings[selection.building][selection.floor].findIndex(s => s.uuid === selection.space.uuid);
        state.buildings[selection.building][selection.floor].splice(spaceIndex, 1);
        if (Object.keys(state.buildings[selection.building][selection.floor]).length === 0)
          Vue.delete(state.buildings[selection.building], selection.floor);
        if (Object.keys(state.buildings[selection.building]).length === 0) Vue.delete(state.buildings, selection.building);
        state.zones = state.zones.filter(z => !z.spaces.includes(selection.space.idIFC));
      }
    },
    addFilter: (state, { key, value }) => {
      if (!state.filters[key]) Vue.set(state.filters, key, []);
      if (!value) return; //Return if only key creation
      state.filters[key].push(value);
    },
    removeFilter: (state, { key, value }) => {
      if (value) state.filters[key].splice(state.filters[key].indexOf(value), 1);
      else Vue.delete(state.filters, key);
    },
    addZone: (state, zone) => {
      state.zones.push(zone);
    },
    removeZone: (state, zone) => {
      const zoneIdx = state.zones.findIndex(z => z.uuid === zone.uuid);
      if (zoneIdx > -1) state.zones.splice(zoneIdx, 1);
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    selectZone: ({ commit, state, rootState, getters }, zone) => {
      commit('addZone', zone);
      rootState.building.collection.forEach(b => {
        commit('unselectBuilding', { building: b.uuid });
      });
      const spacesInFilters = getters.spacesInFilters;
      state.zones.forEach(z => {
        z.spaces.forEach(s => {
          let space = null;
          let floor = null;
          const building = rootState.building.collection.find(b => {
            floor = b.next.find(f => {
              space = f.next.find(sp => {
                return sp.idIFC === s;
              });
              return space;
            });
            return floor;
          });
          if ((space && floor && building && spacesInFilters.length === 0) || (space && spacesInFilters.find(sp => sp.uuid === space.uuid)))
            commit('selectBuilding', { building: building.uuid, floor: floor.uuid, space });
        });
      });
    },
    unselectZone: ({ commit, rootState }, zone) => {
      commit('removeZone', zone);
      if (!('spaces' in zone)) return;
      zone.spaces.forEach(s => {
        let space = null;
        let floor = null;
        const building = rootState.building.collection.find(b => {
          floor = b.next.find(f => {
            space = f.next.find(sp => {
              return sp.idIFC === s;
            });
            return space;
          });
          return floor;
        });
        if (space && floor && building) commit('unselectBuilding', { building: building.uuid, floor: floor.uuid, space });
      });
    },
  },
};
