import _ from 'lodash';
import login from './login-locale-object.json';
const loginEN = _.cloneDeep(login);

loginEN.leftPart.europeanText = `Qarnot computing is co-funded by the European union and région Ile de France through European Regional Development Fund.`;
loginEN.rightPart.title = 'Access your buildings';
loginEN.rightPart.email.label = 'Email';
loginEN.rightPart.email.subLabel = 'Exemple: xyz@domaine.xy';
loginEN.rightPart.password.label = 'Password';
loginEN.rightPart.password.subLabel = 'Must have at least 8 characters';
loginEN.rightPart.buttons.connection = 'connection';
loginEN.rightPart.formErrors.unknownEmail = 'Unknowm email';
loginEN.rightPart.formErrors.wrongPassword = 'Wrong password';
loginEN.rightPart.formErrors.requiredEmail = 'Email is required';
loginEN.rightPart.formErrors.requiredPassword = 'Password is required';
loginEN.rightPart.formErrors.invalidEmail = 'Invalid email';
loginEN.rightPart.forgotPassword = 'Forgot your password ?';
loginEN.rightPart.error.noFeatures = 'Your account was not set up to access Oasis';

export default loginEN;
