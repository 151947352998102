import Vue from 'vue';
import api from 'api';

const getDefaultState = () => ({
  collection: [],
});

const state = getDefaultState();

const mutations = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  setCollection: (state, collection) => Vue.set(state, 'collection', collection),
  save(state, nodesGroupData) {
    if (!nodesGroupData.id) {
      // new nodesGroup
      state.collection.push(nodesGroupData);
    } else {
      // update existing nodesGroup
      const nodesGroup = state.collection.find(t => t.id === nodesGroup.id);
      if (!nodesGroup) return;
      Object.keys(nodesGroup).forEach(k => {
        Vue.set(nodesGroup, k, nodesGroup[k]);
      });
    }
  },
};

const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  async fetch({ commit }) {
    commit('setCollection', (await api.nodesGroup.fetch()).data);
  },
  async save({ commit }, { nodesGroup: nodesGroupData }) {
    const { data: nodesGroup } = await api.nodesGroup.save(nodesGroupData);
    commit('save', { nodesGroup });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
