<template>
  <div class="content-container">
    <div v-if="nodesGroupMeta.isLoading"
         class="message">
        LOADING...
    </div>
    <div v-else-if="nodesGroupMeta.error"
         class="message">
      {{ nodesGroupMeta.error }}
    </div>
    <div v-else
         class="nodes-group-main">

      <div class="nodes-group-form">

        <!-- name -->
        <div class="nodes-group-name grid-wrapper">
          <label class="o-label">
            {{$t('pages.nodesGroup.labels.name')}}
          </label>
          <input v-model="nodesGroup.name"
                class="o-input"
                type="text"
                :disabled="!nodesGroupsRight.WRITE" >
        </div>

        <!-- description -->
        <div class="nodes-group-description grid-wrapper">
          <label class="o-label">{{$t('pages.nodesGroup.labels.description')}}</label>
          <div class="grid-tile__value">
            <textarea v-model="nodesGroup.description"
                      class="o-textarea"
                      rows="3"
                      :disabled="!nodesGroupsRight.WRITE" />
            </div>
        </div>

        <!-- profile -->
        <div class="profile-tile grid-tile">
          <label class="o-label">{{$t('pages.nodesGroup.labels.profile')}}</label>
          <div class="grid-tile__value">
            <q-select v-model="nodesGroup.dataProfileId"
                    :options="dataProfiles"
                    class="select"
                    invertedLight
                    color="white"
                    :disabled="!nodesGroupsRight.WRITE" />
          </div>
        </div>

        <!-- nodeList -->
        <div class="grid-tile">
          <label class="o-label">{{$t('pages.nodesGroup.labels.nodes')}}</label>
          <div class="grid-tile__value">
            <NodesSelector v-model="nodesGroup.nodes"
                          :oldNodeList="oldNodeList"
                          :disabled="!nodesGroupsRight.WRITE" >
            </NodesSelector>
          </div>
        </div>

        <!-- update button  -->
        <div class="nodes-group-update-submit grid-wrapper">
          <button :class="createButtonClass"
              class="form-button o-button o-button--shadows o-button--hover o-button--squared o-button--primary-gradient"
              :disabled="saveButtonDisabled || !nodesGroupsRight.WRITE"
              @click="save()">
            {{$t('pages.nodesGroup.buttons.save')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import NodesSelector from '@/app/components/topology/nodes-selector.vue';

export default {
  name: 'NodesGroupDetailsEditor',
  components: { NodesSelector },
  props: {
    nodesGroupId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      nodesGroup: {},
      nodesGroupMeta: {
        isLoading: false,
        error: null,
      },
      manualMode: false,
      oldNodeList: [],
    };
  },
  computed: {
    nodesGroupsRight() {
      return this.$store.state.user.user.featuresRights.nodesGroups;
    },
    dataProfiles() {
      return this.$store.state.profiles.collection;
    },
    isNameValid() {
      return this.nodesGroup.name && this.nodesGroup.name.length > 0;
    },
    isNodeValid() {
      return this.nodesGroup.nodes && this.nodesGroup.nodes.length > 0;
    },
    isDataProfileValid() {
      return this.dataProfiles.map(t => t.value).includes(this.nodesGroup.dataProfileId);
    },
    saveButtonDisabled() {
      return !this.isNameValid
            || !this.isDataProfileValid
            || !this.isNodeValid;
    },
  },
  async created() {
    this.nodesGroupMeta.isLoading = true;
    try {
      if (this.nodesGroupId) {
        this.nodesGroup = (await api.nodesGroup.getNodesGroup(this.nodesGroupId)).data;
        this.oldNodeList = this.nodesGroup.nodes;
      } else {
        this.nodesGroup = {
          name: null,
          description: null,
          dataProfileId: null,
          nodes: null,
        };
        this.oldNodeList = [];
      }

      if (!this.$store.state.profiles.collection.length) await this.$store.dispatch('profiles/fetch');
    } catch (err) {
      const { data: { errorCode } } = err.response;
      if (errorCode === 'nodesGroupNotFound') {
        this.nodesGroupMeta.error = `${this.$t('pages.nodesGroup.errors.noGroups')} ${this.nodesGroupId}`;
      } else {
        this.nodesGroupMeta.error = `${this.$t('pages.nodesGroup.errors.unknowError')}`;
      }
    }
    this.nodesGroupMeta.isLoading = false;
  },
  methods: {
    async save() {
      const nodesGroupUpdated = this.nodesGroup;

      // title required
      if (!nodesGroupUpdated.name) return;
      // nodeList should contain at least 1 element
      if (!nodesGroupUpdated.nodes.length) return;

      await this.$store.dispatch('nodesGroup/save', { nodesGroup: nodesGroupUpdated });
      await this.$store.dispatch('nodesGroup/fetch');
      this.$emit('saved');
    },
  },
};

</script>

<style lang="stylus" scoped>
@import '~variables'

.nodes-group-main
  display flex
  justify-content center
  align-items center
  margin auto
  padding-top 1%
  .nodes-group-form
    .nodes-group-name
      flex-direction column
      padding $space-2
    .nodes-group-description
      flex-direction column
      padding $space-2
    .profile-tile
      border-right none
    .nodes-group-update-submit
      justify-content flex-end
      padding $space-2
    .q-select
      border 1px solid black
      box-shadow $shadow-elevation-1
    .form-button
      text-transform uppercase
.o-label
  text-transform capitalize

.content-container
  background-color white
  height fit-content
  border-radius 3px

</style>