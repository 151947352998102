import { ApiNotification, ApiPatchNotificationByIdPayload, ApiPatchNotificationsPayload, ApiPatchNotificationsResponse, ApiStatusResponse } from '@/types/api';
import { AxiosResponse } from 'axios';
import securedAxios from '../securedAxios';

const Notification = {
  endpoint: '/api/notifications',
  fetch(): Promise<AxiosResponse<ApiNotification[]>> {
    return securedAxios.get(`${this.endpoint}`);
  },
  patchById(notificationId: number, payload: ApiPatchNotificationByIdPayload): Promise<AxiosResponse<ApiStatusResponse>> {
    return securedAxios.patch(`${this.endpoint}/${notificationId}`, payload);
  },
  patchAll(payload: ApiPatchNotificationsPayload): Promise<AxiosResponse<ApiPatchNotificationsResponse>> {
    return securedAxios.patch(`${this.endpoint}`, payload);
  },
};

export default Notification;
