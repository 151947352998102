import axios from 'axios';
import config from '@/../config/config';
import router from '@/services/router';

const securedAxios = axios.create({
  baseURL: config.api.baseURL,
  withCredentials: true,
});

securedAxios.interceptors.response.use(
  res => res,
  error => {
    if (error.response.status === 401) router.push({ name: 'login' });
    return Promise.reject(error);
  }
);

export default securedAxios;
