<template>
  <div class="period-selector">
    <div class="container">
      <div v-for="period in periods"
            :key="period.value"
            class="period"
            :class="periodClass(period.value)"
            @click="selectTimeRange(period)">
        {{$t(`components.periodSelector.labels.${period.label}`)}}
        <q-tooltip color="white">{{$t(`components.periodSelector.longNames.${period.longName}`)}}</q-tooltip>
      </div>
    </div>
    <div v-show="showDateTime"
          class="container">
      <div class="date-input-container">
        <span :class="selectedClass('startDate')"
              class="control-time__date-input"
              @click.stop.self="openDrawer('startDate')">
          {{ formatedDateTime.startDate }}
        </span>
        <span :class="selectedClass('startTime')"
              class="date-input__hours"
              @click.stop.self="openDrawer('startTime')">
          {{ formatedDateTime.startTime }}
        </span>
      </div>
      <div class="date-input__chevron">
                <ChevronIcon size="10px" />
      </div>
      <div class="date-input-container">
        <span :class="selectedClass('endDate')"
              class="control-time__date-input theme-dark"
              @click.stop.self="openDrawer('endDate')">
          {{ formatedDateTime.endDate }}
        </span>
        <span :class="selectedClass('endTime')"
              class="date-input__hours"
              @click.stop.self="openDrawer('endTime')">
          {{ formatedDateTime.endTime }}
        </span>
      </div>
    </div>
    <transition name="fade">
      <Drawer v-if="drawer.startDate || drawer.endDate || drawer.startTime || drawer.endTime"
              v-on-clickaway="away"
              :side="$q.platform.is.mobile ? 'left' : 'right'"
              posY="70px"
              :class="inAlert ? 'calendar-drawer-alert' : 'calendar-drawer'">
        <q-datetime-picker v-show="drawer.startDate"
                           v-model="timeRange.startDate"
                           :max="timeRange.endDate"
                           minimal
                           format24h
                           dark
                           color="white"
                           type="date"
                           @input="unselectTimeRange()" />
        <q-datetime-picker v-show="drawer.endDate"
                           v-model="timeRange.endDate"
                           :min="timeRange.startDate"
                           :max="today"
                           minimal
                           dark
                           format24h
                           color="white"
                           type="date"
                           @input="unselectTimeRange()" />
        <time-picker v-show="drawer.startTime"
                     v-model="timeRange.startTime"
                     :max="endDateTime"
                     :opened="drawer.startTime"
                     :date="formatedDateTime.startDate"
                     name="startTime"
                     @input="unselectTimeRange()" />
        <time-picker v-show="drawer.endTime"
                     v-model="timeRange.endTime"
                     :min="startDateTime"
                     :opened="drawer.endTime"
                     :date="formatedDateTime.endDate"
                     :max="today"
                     name="endTime"
                     @input="unselectTimeRange()" />
      </Drawer>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import DebounceMixin from '@/app/mixins/debounce';
import ChevronIcon from '@/app/components/ui/pictos/chevron.vue';
import Drawer from '@/app/components/ui/drawer.vue';
import TimePicker from '@/app/components/inputs/time-picker.vue';
import { directive as onClickaway } from 'vue-clickaway';

export default {
  name: 'PeriodSelector',
  components: {
    ChevronIcon,
    Drawer,
    TimePicker,
  },
  directives: { onClickaway: onClickaway },
  mixins: [DebounceMixin],
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: String,
      default: '24hours',
    },
    showDateTime: {
      type: Boolean,
      default: false,
    },
    inAlert: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      periods: [
        { value: '24hours', label: '1D', longName: 'last 24 hours', values: { number: 24, string: 'hour' } },
        { value: '1weeks', label: '1W', longName: 'last week', values: { number: 1, string: 'week' } },
        { value: '1months', label: '1M', longName: 'last month', values: { number: 1, string: 'month' } },
        { value: '1years', label: '1Y', longName: 'last year', values: { number: 1, string: 'year' } },
      ],
      drawer: {
        startDate: false,
        endDate: false,
        startTime: false,
        endTime: false,
      },
      timeRange: {
        startDate: undefined,
        endDate: undefined,
        startTime: undefined,
        endTime: undefined,
      },
      timeRangeButtons: [
        {
          timeRange: 'year',
          selected: false,
        },
        {
          timeRange: 'month',
          selected: false,
        },
        {
          timeRange: 'week',
          selected: false,
        },
        {
          timeRange: 'hour',
          selected: true,
        },
      ],
      today: null,
      timeRangeSelected: true,
    };
  },
  computed: {
    dateFrom() {
      return this.$store.state.selections.time.from;
    },
    dateTo() {
      return this.$store.state.selections.time.to;
    },
    formatedDateTime() {
      return {
        startDate: moment(this.timeRange.startDate).format('DD.MM.YY'),
        endDate: moment(this.timeRange.endDate).format('DD.MM.YY'),
        startTime: moment(this.timeRange.startTime).format('HH[H]mm'),
        endTime: moment(this.timeRange.endTime).format('HH[H]mm'),
      };
    },
    selectedClass() {
      return type => (this.drawer[type] ? 'selectedDrawer' : '');
    },
    startDateTime() {
      return moment(`${this.formatedDateTime.startDate} ${this.formatedDateTime.startTime}`, 'DD.MM.YY HH[H]mm');
    },
    endDateTime() {
      return moment(`${this.formatedDateTime.endDate} ${this.formatedDateTime.endTime}`, 'DD.MM.YY HH[H]mm');
    },
  },
  watch: {
    timeRange: {
      handler(val) {
        this.setDate();
      },
      deep: true,
    },
  },
  mounted() {
    this.today = moment()
      .utc()
      .format();
    if (this.dateFrom && this.dateTo) {
      this.timeRange.startDate = this.dateFrom;
      this.timeRange.startTime = moment().utc(this.dateFrom);
      this.timeRange.endDate =  this.dateTo;
      this.timeRange.endTime = moment().utc(this.dateTo);
      this.resetSelectedButtons();
    } else this.setTimeRangeDate('hour');
    this.$store.commit('selections/setTimeRange', 'day');
  },
  methods: {
    ...mapActions('oql', ['sendQuery']),
    periodClass(period) {
      return {
        'period--selected': this.value === period,
      };
    },
    emitPeriod(period) {
      this.$emit('input', period);
    },
    buttonClass(selected) {
      return {
        'o-button--black': selected,
        'o-button--hover': !selected,
      };
    },
    setDate() {
      this.$store.commit('selections/setTime', {
        from: this.startDateTime.format(),
        to: this.endDateTime.format(),
      });
      this.debounce(this.sendQuery, 1000);
      this.today = moment()
        .utc()
        .format();
    },
    selectTimeRange(btn) {
      this.timeRangeSelected = true;
      this.setTimeRangeDate(btn.values.string);
      this.resetSelectedButtons();
      this.emitPeriod(btn);
      btn.selected = !btn.selected;
    },
    resetSelectedButtons() {
      this.timeRangeButtons.forEach(btn => (btn.selected = false));
    },
    setTimeRangeDate(timeRange) {
      let startDate = moment().utc();
      if (timeRange === 'year') startDate = startDate.subtract(1, 'years');
      else if (timeRange === 'month') startDate = startDate.subtract(1, 'months');
      else if (timeRange === 'week') startDate = startDate.subtract(1, 'weeks');
      else if (timeRange === 'hour') startDate = startDate.subtract(24, 'hour');
      this.timeRange.startDate = startDate;
      this.timeRange.endDate = this.today;
      this.timeRange.startTime = moment().utc(startDate);
      this.timeRange.endTime = moment().utc(this.today);
    },
    unselectTimeRange() {
      if (!this.timeRangeSelected) this.resetSelectedButtons();
      const period = { from: this.startDateTime.format(), to: this.endDateTime.format() };
      this.emitPeriod(period);
    },
    away() {
      this.resetAllDrawers();
    },
    resetAllDrawers(type = null) {
      Object.keys(this.drawer).forEach(k => {
        if (!type || type !== k) this.drawer[k] = false;
      });
    },
    openDrawer(type) {
      this.timeRangeSelected = false;
      if (type === 'startDate') this.drawer.startDate = !this.drawer.startDate;
      else if (type === 'endDate') this.drawer.endDate = !this.drawer.endDate;
      else if (type === 'startTime') this.drawer.startTime = !this.drawer.startTime;
      else this.drawer.endTime = !this.drawer.endTime;
      this.resetAllDrawers(type);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.period-selector
  display flex
  flex-direction column
  justify-content center
  .container
    display flex
    flex-direction row
  .period
    margin 0 14px
    color $grey
    cursor pointer
    &:hover, &--selected
      position relative
      color black
      font-weight 700
      &:after
        position absolute
        right 0
        bottom -4px
        left 0
        height 2px
        background-color black
        content ''
  .calendar-drawer
    position absolute
    top 23px
    right 100%
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      top 170px
      right 0
      left 0
      z-index 100
      margin 0 auto
  .calendar-drawer-alert
    position absolute
    top 100px
    right 45%
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      top 170px
      right 0
      left 0
      z-index 100
      margin 0 auto

.date-input-container
  display flex
  flex-direction column
  justify-content left
  align-items center
  font-size $nfs-body
  .control-time__date-input
    padding $space-little
    font-weight $fw-bold
    cursor pointer
    user-select none
  .date-input__chevron
    transform rotate(-90deg)
  .date-input__hours
    padding $space-little
    color $dark
    font-weight $fw-light
    cursor pointer
    user-select none
  .selectedDrawer
    height 23px
    border-radius 4px
    background-color black
    color white

.date-input__chevron
  margin 0 14px
  width 20px
  height @width
  transform rotate(-90deg)

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
</style>