import _ from 'lodash';
import components from './components-locale-object.json';
const componentsFR = _.cloneDeep(components);

// Commons
componentsFR.commons.noData = 'aucune donnée';
// TimeControle
componentsFR.timeControle.year = 'année';
componentsFR.timeControle.month = 'mois';
componentsFR.timeControle.week = 'semaine';
componentsFR.timeControle.day = 'jour';
componentsFR.timeControle.live = 'live';
// TimePicker
componentsFR.timePicker.hours = 'heures';
componentsFR.timePicker.minutes = 'minutes';
// BuildingSelection
componentsFR.buildingSelection.filterPlaceholder = 'Filtrer par bâtiments';
// FloorSelection
componentsFR.floorSelection.floors = 'étages';
componentsFR.floorSelection.all = 'TOUT';
// FilterSelection
componentsFR.filterSelection.add = 'AJOUTER';
componentsFR.filterSelection.noFilters = 'Aucun filtres';
componentsFR.propertiesSelection.select = 'Selectionnez';
componentsFR.propertiesSelection.subText = 'Une catégorie pour filtrer votre sélection';
componentsFR.propertiesSelection.type = 'type';
componentsFR.propertiesSelection.access = 'accès';
// ZoneSelection
componentsFR.zoneSelection.searchPlaceholder = 'recherchez par nom ou étage';
// SelectionInfo
componentsFR.selectionInfo.buildings = 'bâtiment | bâtiment | bâtiments'; // pluralization : 0 | 1 | more
componentsFR.selectionInfo.floors = 'étage | étage | étages';
componentsFR.selectionInfo.spaces = 'pièce | pièce | pièces';
componentsFR.selectionInfo.sensors = 'radiateur | radiateur | radiateurs';
// Timeline
componentsFR.timeline.barText.selectBuilding = 'selectionnez un bâtiment';
componentsFR.timeline.barText.noDevice = 'aucun péripherique dans la selection';
componentsFR.timeline.barText.noData = 'aucune donnée';
// Shortcuts
componentsFR.shortcuts.name = 'raccourcis';
componentsFR.shortcuts.commons.or = 'ou';
componentsFR.shortcuts.keys.ctrl = 'ctrl';
componentsFR.shortcuts.keys.wheel = 'molette';
componentsFR.shortcuts.keys.arrows = 'flèches directionnelles';
componentsFR.shortcuts.keys.mouseRight = 'clic droit';
componentsFR.shortcuts.keys.mouseLeft = 'clic gauche';
componentsFR.shortcuts.keys.pageUp = 'page haut';
componentsFR.shortcuts.keys.pageDown = 'page bas';
componentsFR.shortcuts.navigation.name = 'navigation';
componentsFR.shortcuts.navigation.zoomCurrentFloor = `zoomer sur l'étage actuel`;
componentsFR.shortcuts.navigation.moveCurrentFloor = `déplacer l'étage actuel`;
componentsFR.shortcuts.navigation.nextFloorUpDown = 'étage suivant/précédent';
componentsFR.shortcuts.selection.name = 'sélection';
componentsFR.shortcuts.selection.selectSpace = 'sélectionnez une pièce';
componentsFR.shortcuts.selection.selectMultiSpace = 'sélectionnez plusieurs pièces';
componentsFR.shortcuts.selection.spaceDetails = `détails d'une pièce`;
componentsFR.shortcuts.edition.name = 'édition';
componentsFR.shortcuts.edition.addDeviceInSpace = 'ajouter un capteur dans la pièce';
componentsFR.shortcuts.edition.leaveEditionMode = 'quitter le mode édition';
componentsFR.shortcuts.edition.inSpace = 'dans la pièce';
componentsFR.shortcuts.edition.outSpace = 'hors de la pièce';
// SpaceInfo
componentsFR.spaceInfo.editDevice = 'editer les capteurs';
componentsFR.spaceInfo.presence.occupied = 'Occupée';
componentsFR.spaceInfo.presence.free = 'Libre';
// DeviceInfo
componentsFR.deviceInfo.editDevice = 'éditer le capteur';
componentsFR.deviceInfo.save = 'sauvegarder';
componentsFR.deviceInfo.move = 'déplacer';
componentsFR.deviceInfo.labels.deviceUuid = 'uuid du capteur';
componentsFR.deviceInfo.labels.type = 'type';
componentsFR.deviceInfo.labels.description = 'description';
// ColorPicker
componentsFR.colorPicker.selectColor = 'sélectionnez une couleur';
// AccountMenu
componentsFR.accountMenu.addBuilding = 'ajouter un bâtiment';
componentsFR.accountMenu.store = 'boutique';
componentsFR.accountMenu.signOut = 'se déconnecter';
// ColorRangeHelper
componentsFR.colorRangeHelper.max = 'Maximum';
componentsFR.colorRangeHelper.noData = 'Aucune donnée';
componentsFR.colorRangeHelper.good = 'Bon';
componentsFR.colorRangeHelper.bad = 'Mauvais';
componentsFR.colorRangeHelper.oapps.presence.occupied = 'Occupé';
componentsFR.colorRangeHelper.oapps.presence.free = 'Libre';
componentsFR.colorRangeHelper.oapps.presence.mid = 'Partiellement occupé';
// PeriodSelector
componentsFR.periodSelector.labels['1D'] = '1J';
componentsFR.periodSelector.labels['1W'] = '1S';
componentsFR.periodSelector.labels['1M'] = '1M';
componentsFR.periodSelector.labels['1Y'] = '1A';
componentsFR.periodSelector.longNames['last 24 hours'] = 'les dernières 24 heures';
componentsFR.periodSelector.longNames['last week'] = 'la dernière semaine';
componentsFR.periodSelector.longNames['last month'] = 'le dernier mois';
componentsFR.periodSelector.longNames['last year'] = 'la dernière année';
// Duration
componentsFR.duration.since = 'depuis';
componentsFR.duration.second = 'seconde';
componentsFR.duration.minute = 'minute';
componentsFR.duration.hour = 'heure';
componentsFR.duration.day = 'jour';
componentsFR.duration.now = `A l'instant`;
componentsFR.duration.today = 'Aujourd’hui';
componentsFR.duration.week = 'Cette Semaine';
componentsFR.duration.previous = 'Notifications précédentes';

// Pagination
componentsFR.pagination.previous = 'Précédent';
componentsFR.pagination.next = 'Prochain';

export default componentsFR;
