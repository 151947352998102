import Vue from 'vue';
import api from 'api';
import Dashboard from '../../models/dashboard/dashboard';

const getDefaultState = () => ({
  collection: [],
  currentDashboardId: null,
});

const state = getDefaultState();

const mutations = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  setCurrentDashboardId(state, dashboardId) {
    state.currentDashboardId = dashboardId;
  },
  setCollection: (state, collection) => Vue.set(state, 'collection', collection),
  createDashboard(state, dashboard) {
    state.collection.push(dashboard);
  },
  updateDashboard(state, dashboard) {
    const dashboardIndex = state.collection.findIndex(d => d.id === dashboard.id);
    if (dashboardIndex < 0) return;
    Vue.set(state.collection, dashboardIndex, dashboard);
  },
  deleteDashboard(state, dashboardId) {
    const dashboardIndex = state.collection.findIndex(d => d.id === dashboardId);
    if (dashboardIndex < 0) return;
    Vue.delete(state.collection, dashboardIndex);
  },
  createWidget(state, { dashboardId, widget }) {
    const dash = state.collection.find(d => d.id === dashboardId);
    if (dash) dash.dashboardWidgets.push(widget);
  },
  updateWidget(state, { dashboardId, widget }) {
    const dashboard = state.collection.find(d => d.id === dashboardId);
    if (!dashboard) return;
    const oldWidget = dashboard.dashboardWidgets.find(w => w.id === widget.id);
    if (oldWidget) {
      if (widget.name) oldWidget.config.name = widget.name;
      if (widget.x) oldWidget.x = widget.x;
      if (widget.y) oldWidget.y = widget.y;
      if (widget.h) oldWidget.h = widget.h;
      if (widget.w) oldWidget.w = widget.w;
      if (widget.config) oldWidget.config = widget.config;
    }
  },
  deleteWidget(state, { dashboardId, widgetId }) {
    const dashboard = state.collection.find(d => d.id === dashboardId);
    if (!dashboard) return;
    const widgetIndex = dashboard.dashboardWidgets.findIndex(w => w.id === widgetId);
    if (widgetIndex < 0) return;
    dashboard.dashboardWidgets.splice(widgetIndex, 1);
  },
};

const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  async fetch({ state, commit }) {
    const { data: dashboards } = await api.dashboard.fetch();
    commit('setCollection', dashboards.map(dashboard => new Dashboard(dashboard)));
  },
  async createDashboard({ commit }, dashboard) {
    const { data: dash } = await api.dashboard.create(dashboard);
    dash.dashboardWidgets = [];
    commit('createDashboard', dash);
    commit('setCurrentDashboardId', dash.id);
  },
  async updateDashboard({ commit }, dashboard) {
    await api.dashboard.update(dashboard.id, dashboard);
    commit('updateDashboard', dashboard);
  },
  async deleteDashboard({ commit }, dashboardId) {
    await api.dashboard.delete(dashboardId);
    commit('deleteDashboard', dashboardId);
    commit('setCurrentDashboardId', null);
  },
  async createWidget({ commit }, { dashboardId, newWidget }) {
    const { data: widget } = await api.widget.create(dashboardId, newWidget);
    commit('createWidget', { dashboardId, widget });
  },
  async updateWidget({ commit }, { dashboardId, widget }) {
    await api.widget.update(widget);
    commit('updateWidget', { dashboardId, widget });
  },
  async deleteWidget({ state, commit }, widgetId) {
    await api.widget.delete(widgetId);
    commit('deleteWidget', { dashboardId: state.currentDashboardId, widgetId });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
