import _ from 'lodash';
import buildingConfig from './building-config-locale-object.json';
const buildingConfigEN = _.cloneDeep(buildingConfig);

// menu
buildingConfigEN.sidebarIn.menu.spaces = 'spaces';
buildingConfigEN.sidebarIn.menu.parcels = 'parcels';
buildingConfigEN.sidebarIn.menu.deleteBuilding = 'delete building';
// properties
buildingConfigEN.properties.title = 'properties';
buildingConfigEN.properties.access.private = `private`;
buildingConfigEN.properties.access.public = `public`;
buildingConfigEN.properties.type.private = `private`;
buildingConfigEN.properties.type.public = `public`;
buildingConfigEN.properties.type.bedroom = `bedroom`;
buildingConfigEN.properties.type.bathroom = `bathroom`;
buildingConfigEN.properties.type.kitchen = `kitchen`;
buildingConfigEN.properties.type.office = `office`;
buildingConfigEN.properties.type['living room'] = `living room`;
buildingConfigEN.properties.type.wc = `wc`;
buildingConfigEN.properties.type.corridor = `corridor`;
buildingConfigEN.properties.type.terrace = `patio`;
buildingConfigEN.properties.type.balcony = `balcony`;
buildingConfigEN.properties.type.cellar = `cellar`;
buildingConfigEN.properties.type.PMI = `pmi`;
buildingConfigEN.properties.type.hall = `hall`;
buildingConfigEN.properties.type.reception = `reception`;
buildingConfigEN.properties.type['waiting room'] = `waiting`;
buildingConfigEN.properties.type['meeting room'] = `meeting`;
// parcels
buildingConfigEN.parcel.parcelInfo.createParcel = 'Select or create a parcel';
buildingConfigEN.parcel.parcelInfo.addSpaceToParcel = 'Click on spaces to add them to the parcel';
buildingConfigEN.parcel.parcelInfo.floor = 'floor';
buildingConfigEN.parcel.parcelInfo.spaces = 'spaces';
buildingConfigEN.parcel.parcelEdition.title = 'parcel edition';
buildingConfigEN.parcel.parcelEdition.buttons.createParcel = 'Create a parcel';
buildingConfigEN.parcel.parcelEdition.buttons.showParcels = 'Show all parcels';
buildingConfigEN.parcel.parcelEdition.buttons.hideParcels = 'Hide all parcels';
buildingConfigEN.parcel.parcelEdition.buttons.edit = 'edit';
buildingConfigEN.parcel.parcelEdition.buttons.delete = 'delete';
buildingConfigEN.parcel.parcelEdition.form.name = 'name';
buildingConfigEN.parcel.parcelEdition.form.type = 'type';
buildingConfigEN.parcel.parcelEdition.form.update = 'update';
buildingConfigEN.parcel.parcelEdition.form.create = 'create';
buildingConfigEN.parcel.parcelEdition.form.apartment = 'apartment';
buildingConfigEN.parcel.parcelEdition.form.office = 'office';
buildingConfigEN.parcel.parcelEdition.toasts.allParcelsSaved = `All parcels are saved`;
buildingConfigEN.parcel.parcelEdition.toasts.spaceAlreadyAssignedTo = `Space already assigned to {zone}`;
buildingConfigEN.parcel.parcelEdition.toasts.spaceHasToBeSameFloor = `Spaces has to be on the same floor`;

export default buildingConfigEN;
