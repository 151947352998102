import securedAxios from '../securedAxios';

const endpoint = '/api/space';
const zones = {
  create({ name, type, metadata, spaceList }) {
    return securedAxios.post(`${endpoint}/insert`, {
      name,
      nodeType: 'premise',
      description: '',
      type,
      metadata,
      nodesTo: spaceList,
    });
  },
  update(zoneUuid, { name, type, metadata }) {
    return securedAxios.patch(`${endpoint}/node/${zoneUuid}`, {
      name,
      type,
      metadata,
    });
  },
  delete(zoneUuid) {
    return securedAxios.delete(`${endpoint}/insert/${zoneUuid}`);
  },
  read(buildingUuid) {
    return securedAxios.get(`${endpoint}/under-building/${buildingUuid}/premise/room`);
  },
  spaceList: {
    update(zoneUuid, spaceList) {
      return securedAxios.patch(`${endpoint}/node/${zoneUuid}/subnodes`, {
        subNodes: spaceList,
      });
    },
  },
};

export default zones;
