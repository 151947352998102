import Node from 'models/topology/node';
import Plug from 'models/topology/plug';

export default class Space extends Node {
  uuid;
  name;
  metadata = {};
  properties = {};
  idIFC;
  constructor(rawData = {}, parent = null) {
    super(rawData, parent);
    this.uuid = rawData.uuid;
    this.name = rawData.name;
    this.metadata = rawData.metadata;
    this.idIFC = rawData.idIFC;
    if (rawData.properties) this.properties = rawData.properties;
    if (rawData.next) this.next = rawData.next.map(plug => new Plug(plug, this));
  }
}
