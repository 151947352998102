import securedAxios from '../securedAxios';

const topology = {
  endpoint: '/api/space/user-topology',
  fetch(type = 'simple') {
    if (type === 'simple') return securedAxios.get(`${this.endpoint}`);
    else if (type === 'full') return securedAxios.get(`${this.endpoint}/full`);
    else if (type === 'energy') return securedAxios.get(`${this.endpoint}/energy`);
  },
  patchNode(uuid, params) {
    return securedAxios.patch(`${this.endpoint}/node/${uuid}`, params);
  },
};

export default topology;
