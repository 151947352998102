import Vue from 'vue';
import api from 'api';

const getDefaultState = () => ({
  collection: [],
  meta: {
    lastFetch: null,
    isLoading: false,
    error: null,
  },
});

const state = getDefaultState();

const mutations = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  setCollection: (state, collection) => Vue.set(state, 'collection', collection),
  save(state, alertRuleData) {
    if (!alertRuleData.id) {
      // new alertRule
      state.collection.push(alertRuleData);
    } else {
      // update existing alertRule
      const alertRule = state.collection.find(t => t.id === alertRule.id);
      if (!alertRule) return;
      Object.keys(alertRuleData).forEach(k => {
        Vue.set(alertRuleData, k, alertRuleData[k]);
      });
    }
  },
  set: (state, { collection, meta }) => {
    if (collection !== undefined) {
      Vue.set(state, 'collection', collection);
    }

    if (meta !== undefined) {
      Vue.set(state, 'meta', { ...state.meta, ...meta });
    }
  },
};

const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  async fetch({ commit, state }) {
    // avoid fetching if we're already doing it
    if (state.meta.isLoading) {
      return;
    }

    // avoid re-loading if data is fresh (loaded less than 5 secs ago)
    if (
      state.meta.lastFetch !== null &&
      (new Date().getTime() - state.meta.lastFetch.getTime() < 5000) &&
      state.meta.error === null
    ) {
      return;
    }

    commit('set', {
      meta: {
        isLoading: true,
        error: null,
      }
    });

    try {
      const fetchResult = await api.alertRule.fetch();
      commit('set', {
        collection: fetchResult.data,
        meta: {
          lastFetch: new Date(),
          isLoading: false,
          error: null,
        }
      });
    } catch (err) {
      commit('set', {
        meta: {
          isLoading: false,
          error: errorMessageFromApiError(err),
        }
      });
    }
  },
  async save({ commit }, { alertRule: alertRuleData }) {
    const { data: alertRule } = await api.alertRule.save(alertRuleData);
    commit('save', { alertRule });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
