import Node from 'models/topology/node';
import Country from 'models/topology/country';

export default class Root extends Node {
  next = [];
  constructor(rawData = {}, options = null) {
    super(rawData);
    if (rawData.next) this.next = rawData.next.map(country => new Country(country, this, options));
  }
}
