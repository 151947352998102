export default class Zone {
  uuid;
  type;
  name;
  color;
  spaces = [];
  constructor(rawData) {
    this.uuid = rawData.uuid;
    this.type = rawData.type;
    this.name = rawData.name;
    if (rawData.metadata && rawData.metadata.color) this.color = rawData.metadata.color;
    if (rawData.next) this.spaces = rawData.next;
  }

  addSpace(spaceUuid) {
    this.spaces.push(spaceUuid);
  }

  removeSpace(spaceUuid) {
    const idx = this.spaces.findIndex(uuid => uuid === spaceUuid);
    if (idx > -1) this.spaces.splice(idx, 1);
  }
}
