import _ from 'lodash';

import globalEN from './global/en';
import globalFR from './global/fr';

import loginEN from './pages/login/en';
import loginFR from './pages/login/fr';

import dataEN from './pages/data/en';
import dataFR from './pages/data/fr';

import graphEN from './pages/graph/en';
import graphFR from './pages/graph/fr';

import oappsEN from './oapps/en';
import oappsFR from './oapps/fr';

import widgetsEN from './widgets/en';
import widgetsFR from './widgets/fr';

import buildingConfigEN from './pages/configuration/en';
import buildingConfigFR from './pages/configuration/fr';

import ticketEN from './pages/ticket/en';
import ticketFR from './pages/ticket/fr';

import dashboardEN from './pages/dashboard/en';
import dashboardFR from './pages/dashboard/fr';

import componentsEN from './components/en';
import componentsFR from './components/fr';

import adminEN from './pages/admin/en';
import adminFR from './pages/admin/fr';

import notificationEN from './pages/notification/en';
import notificationFR from './pages/notification/fr';

import alertEN from './pages/alert/en';
import alertFR from './pages/alert/fr';

import alertRuleEN from './pages/alertRule/en';
import alertRuleFR from './pages/alertRule/fr';

import errorsEN from './errors/en';
import errorsFR from './errors/fr';

import reportEN from './pages/report/en';
import reportFR from './pages/report/fr';

import nodesGroupEN from './pages/nodesGroup/en';
import nodesGroupFR from './pages/nodesGroup/fr';

const localObject = {
  global: {},
  errors: {},
  pages: {
    login: {},
    data: {},
    graph: {},
    buildingConfiguration: {},
    ticket: {},
    dashboard: {},
    admin: {},
    notification: {},
    alert: {},
    alertRule: {},
    nodesGroup: {},
  },
  components: {},
  oapps: {},
  widgets: {},
};

const locales = {
  en: _.cloneDeep(localObject),
  fr: _.cloneDeep(localObject),
};

locales.en.global = globalEN;
locales.fr.global = globalFR;

locales.en.pages.login = loginEN;
locales.fr.pages.login = loginFR;

locales.en.pages.data = dataEN;
locales.fr.pages.data = dataFR;

locales.en.pages.graph = graphEN;
locales.fr.pages.graph = graphFR;

locales.en.oapps = oappsEN;
locales.fr.oapps = oappsFR;

locales.en.widgets = widgetsEN;
locales.fr.widgets = widgetsFR;

locales.en.pages.buildingConfiguration = buildingConfigEN;
locales.fr.pages.buildingConfiguration = buildingConfigFR;

locales.en.pages.ticket = ticketEN;
locales.fr.pages.ticket = ticketFR;

locales.en.pages.dashboard = dashboardEN;
locales.fr.pages.dashboard = dashboardFR;

locales.en.components = componentsEN;
locales.fr.components = componentsFR;

locales.en.pages.admin = adminEN;
locales.fr.pages.admin = adminFR;

locales.en.pages.notification = notificationEN;
locales.fr.pages.notification = notificationFR;

locales.en.pages.alert = alertEN;
locales.fr.pages.alert = alertFR;

locales.en.pages.alertRule = alertRuleEN;
locales.fr.pages.alertRule = alertRuleFR;

locales.en.errors = errorsEN;
locales.fr.errors = errorsFR;

locales.en.pages.report = reportEN;
locales.fr.pages.report = reportFR;

locales.en.pages.nodesGroup = nodesGroupEN;
locales.fr.pages.nodesGroup = nodesGroupFR;

export default locales;
