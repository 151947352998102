import securedAxios from '../securedAxios';

const userNodes = {
  endpoint: '/api/qpark',
  fetch() {
    return securedAxios.get(`${this.endpoint}/user-nodes`);
  },
  fetchUnderNode(nodeUUID) {
    return securedAxios.get(`${this.endpoint}/user-nodes/under-node/${nodeUUID}`);
  },
};

export default userNodes;
