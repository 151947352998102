import { ApiPaginatedResponse } from '@/types/api';
import { ReportConfig, ReportConfigCreateResult, ReportConfigCreatePayload, ReportConfigUpdatePayload } from '@/types/api';
import { AxiosResponse } from 'axios';
import securedAxios from '../securedAxios';

export default {
  endpoint: '/api/report-configs',
  /**
   * Get the list of report configs accessible to the user
   */
  getList(
    params? : {
      offset?: number,
      limit?: number
    }): Promise<AxiosResponse<ApiPaginatedResponse<ReportConfig>>>
  {
    params = {
      limit: 25,
      offset: 0,
      ...params,
    };
    return securedAxios.get(this.endpoint, { params });
  },
  /**
   * Get one report config from its id
   */
  getById(id: number): Promise<AxiosResponse<ReportConfig>> {
    return securedAxios.get(`${this.endpoint}/${id}`);
  },
  create(payload: ReportConfigCreatePayload): Promise<AxiosResponse<ReportConfigCreateResult>> {
    return securedAxios.post(this.endpoint, payload);
  },
  update(id: number, payload: ReportConfigUpdatePayload): Promise<AxiosResponse<ReportConfig>> {
    return securedAxios.patch(`${this.endpoint}/${id}`, payload);
  }
};
