import _ from 'lodash';
import alerts from './alert-locale-object.json';
const alertEN = _.cloneDeep(alerts);

// pop-up
alertEN.popUp.noActive = 'No active alerts';
alertEN.popUp.center = 'Open Alerts Menu';
alertEN.popUp.seeMore = 'See more';
// title
alertEN.title.active = 'Current alerts';
alertEN.title.history = 'Alerts history';
// view
alertEN.view.active = 'View active alerts';
alertEN.view.history = 'View resolved alerts';
alertEN.view.return = 'Return';
// priorities
alertEN.priorities.all = 'All';
alertEN.priorities.low = 'Low';
alertEN.priorities.normal = 'Normal';
alertEN.priorities.high = 'High';
alertEN.priorities.resolved = 'Resolved';
// labels
alertEN.labels.title = 'title';
alertEN.labels.localisation = 'localisation';
alertEN.labels.priority = 'priority';
alertEN.labels.activeSince = 'active since';
alertEN.labels.data = 'Data';
// errors
alertEN.errors.notFound = 'There is no alert with the id';
alertEN.errors.forbiddenUser = 'You do not have access to the alert';
alertEN.errors.unknowError = 'An error has occurred';
alertEN.errors.noLocation = 'No location found';
// layout
alertEN.layout.history.title = 'History';
alertEN.layout.history.lastActive = 'Last active';
alertEN.layout.history.lastActiveNever = 'Never';
alertEN.layout.history.nbActiveSince = 'Active {triggerCount} times in the last {since}';
alertEN.layout.history.firstActivation = 'First activation';

alertEN.layout.alertRule = 'Alert rule';
alertEN.layout.ignoreDevice = 'Ignore device';

alertEN.simple.message = `Message`;
alertEN.simple.device = `Device`;
alertEN.simple.emergencyLevel = `Emergency level`;
alertEN.simple.dateCreated = `Alert triggered`;
alertEN.simple.location = `Location`;
alertEN.simple.locationNotFound = `Location not found`;
alertEN.simple.resolved = 'Resolved';
alertEN.simple.profileName = 'Data profile';
alertEN.simple.profileUnit = 'Unit';

alertEN.resolved = 'Resolved';
alertEN.active = 'Active';

export default alertEN;