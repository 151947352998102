<template>
  <q-page class="alert-detail-page">
    <div class="actions-container">
      <div v-if="!historyStackIsEmpty"
        class="pointer"
        @click="$router.back()"
      >
        <q-icon name="fas fa-arrow-left notification-icon"
                size="15px"/>
        <span>{{$t('pages.alert.view.return') }}</span>
      </div>
    </div>
    <div class="content-container">
      <div v-if="alertMeta.isLoading"
           class="message">
           <div class="loader">
            <OasisLoader/>
            {{ $t('global.loading') }}
           </div>
      </div>
      <div v-else-if="alertMeta.error"
           class="message">
        {{ alertMeta.error }}
      </div>
      <div v-else
           class="alert-detail">
        <div class="sidebar">
          <AlertDetailSidebar
            :alert="alert"
            @alertUpdated="onAlertUpdated"
          />
        </div>
        <div class="alert-data">
          <AlertDetails
            :alert="alert"
            @alertUpdated="onAlertUpdated"
          />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import AlertDetailSidebar from '@/app/pages/alert/components/alert-detail-sidebar.vue';
import AlertDetails from '@/app/pages/alert/components/alert-details.vue';
import OasisLoader from '@/app/components/ui/oasis-loader.vue';
import api from 'api';

export default {
  name: 'AlertLayout',
  components: {
    AlertDetailSidebar,
    AlertDetails,
    OasisLoader,
  },
  props: {
    // the alert's id
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      alert: null,
      alertMeta: {
        isLoading: true,
        error: null,
      }
    };
  },
  computed: {
    historyStackIsEmpty() {
      return window.history.length <= 2;
    },
  },
  watch: {
    async id() {
      await this.fetchAlert();
    }
  },
  async created() {
    await this.fetchAlert();
  },
  methods: {
    // fetch the detailed data of the alert we want to show
    async fetchAlert() {
      this.alertMeta = {
        isLoading: true,
        error: null,
      };

      try {
        this.alert = (await api.alert.getAlert(this.id)).data;
      } catch (err) {
        const { data: { errorCode } } = err.response;
        if (errorCode === 'alertNotFound') {
          this.alertMeta.error = `${this.$t('pages.alert.errors.notFound')} ${this.id}`;
        } else if (errorCode === 'forbiddenUser') {
          this.alertMeta.error = `${this.$t('pages.alert.errors.forbiddenUser')}`;
        } else {
          this.alertMeta.error = `${this.$t('pages.alert.errors.unknowError')}`;
        }
      }

      this.alertMeta.isLoading = false;
    },
    // update current (detailed) alert _and_ list of alerts
    async onAlertUpdated() {
      await Promise.allSettled([
        this.fetchAlert(),
        this.$store.dispatch('alert/fetch'),
      ]);
    }
  }
};

</script>

<style lang="stylus" scoped>
@import '~variables'

.alert-detail
  display flex
  flex-direction row
  @media (max-width: 1000px)
    flex-direction column
  font-size $fs-h2
  gap 3px
  justify-content space-evenly
  .sidebar
    display flex
    flex-direction column
    min-width 300px
    max-width 400px
    @media (max-width: 1000px)
      max-width none
  .alert-data
    display flex
    flex-direction column
    margin auto
    background-color white
    max-width 900px
    width 100%
    min-width 700px
    @media (max-width: 1000px)
      max-width 730px
      min-width 300px

.message
  display grid
  font-size $fs-h1
  place-items center

.actions-container
  display flex
  flex-direction row
  gap 10px
  margin 0 0 10px 0
  .pointer
    cursor pointer
  .pointer>*
    margin-right 4px

.content-container
  height fit-content
  border-radius 3px

.loader
  max-width 150px
  width 20%
  gap 10px
  display flex
  flex-direction column
  text-align center

</style>