<template>
  <div v-if="ticket"
       class="ticket-view">
    <div class="ticket-wrapper">
      <span class="ticket-title">
        {{ ticket.title }}
      </span>
      <div class="ticket-actions">
        <button class="share-button o-button o-button--hover o-button--squared o-button--shadows o-button--primary-gradient"
                @click="shareLink">
          {{$t('pages.ticket.creationEdition.buttons.share')}}
        </button>
        <button class="edit-button o-button o-button--hover o-button--squared o-button--shadows  o-button--bordered"
                @click="$emit('startEdition')">
          {{$t('pages.ticket.creationEdition.buttons.edit')}}
        </button>
        <TicketStatus :state="ticket.status"
                      @input="updateStatus" />
      </div>
      <CollapseContainer :pose="showLink ? 'visible' : 'hidden'">
        <div class="share-link-container">
          <div v-if="ticket.shortId"
               class="ticket-url">
            <span class="url">
              {{ getUrl(ticket.shortId) }}
            </span>
            <button class="copy-to-clipboard o-button o-button--squared"
                    @click="copyLink">
              {{$t('pages.ticket.creationEdition.buttons.copy')}}
            </button>
          </div>
        </div>
      </CollapseContainer>
      <CollapseContainer v-if="user.featuresRights.admin.WRITE"
                     :pose="showLink ? 'visible' : 'hidden'">
        <!--
           this block purpose is to make it easy to pre-create links (or qrcodes) for public tickets creation
           based on current ticket for template
        -->
        <div class="grid-wrapper qrcode">
          <div class="grid-tile">
            <label class="grid-tile__label">Copier le lien de création de ticket publique</label>
            <q-btn iconRight="far fa-clone"
                    class="btn"
                    size="sm"
                    outline
                    icon="fas fa-link"
                    @click="copyLinkCreation"/>
          </div>
          <div class="grid-tile">
            <label class="grid-tile__label">Télécharger le QRCode de création de ticket publique</label>
            <a :href="qrcodeUrl"
                    class="btn"
              download="qrcode.png">
              <q-btn iconRight="far fa-arrow-alt-circle-down"
                    outline
                    icon="fas fa-qrcode"
                    size="sm"/>
            </a>
          </div>
        </div>
      </CollapseContainer>
      <div class="ticket-infos grid-wrapper">
        <div class="grid-tile t_grid-tile--date">
          <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.date')}}</label>
          <span class="grid-tile__value">{{ formatDate(ticket.dateCreation) }}</span>
        </div>
        <div class="grid-tile t_grid-tile--author">
          <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.author')}}</label>
          <span class="grid-tile__value">{{ ticket.author.firstName }} {{ ticket.author.lastName }}</span>
        </div>
        <div class="grid-tile t_grid-tile--type">
          <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.type')}}</label>
          <span class="grid-tile__value">{{$t(`pages.ticket.creationEdition.types.${ticket.type}`)}}</span>
        </div>
        <div class="grid-tile t_grid-tile--priority">
          <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.priority')}}</label>
          <span class="grid-tile__value">{{$t(`pages.ticket.creationEdition.priorities.${ticket.priority}`)}}</span>
        </div>
        <div class="grid-tile t_grid-tile--location">
          <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.location')}}</label>
          <span v-if="ticketBuilding && !selectedBuilding"
                class="grid-tile__value">{{ticketBuilding.name}}</span>
          <span v-if="ticketFloor"
                class="grid-tile__value">{{ticketFloor.name}}</span>
          <span v-if="ticketSpace"
                class="grid-tile__value">{{ticketSpace.name}}</span>
        </div>
      </div>
      <div class="ticket-message grid-tile">
        <label class="grid-tile__label">
          {{$t('pages.ticket.creationEdition.labels.message')}}
        </label>
        <textarea v-model="ticket.message"
                  class="form-textarea"
                  rows="8"
                  readonly />
        </div>
      <div class="ticket-documents grid-tile">
        <span class="grid-tile__label">
          {{$t('pages.ticket.creationEdition.labels.documents')}}
        </span>
        <div class="files-list">
          <span v-for="(file, idx) in ticket.documents"
                :key="idx"
                class="file-item file-item--download"
                @click="downloadFile(file)">
            {{ file.filename }}
          </span>
          <span v-if="ticket.documents && ticket.documents.length <= 0"
                class="form-value">
          {{$t('pages.ticket.creationEdition.messages.noDocuments')}}
          </span>
        </div>
      </div>
    </div>
    <TicketComments :comments="ticket.comments"
                    :ticketShortId="ticket.shortId"
                    class="comments"
    />
  </div>
</template>

<script>
import moment from 'moment';
import api from 'api';
import posed from 'vue-pose';

import ColorsMixin from '@/app/mixins/colors';

import TicketStatus from '@/app/pages/ticket/components/ticket-status.vue';
import TicketComments from '@/app/pages/ticket/components/ticket-comments.vue';

export default {
  name: 'TicketView',
  components: {
    TicketComments,
    TicketStatus,
    CollapseContainer: posed.div({
      visible: {
        height: 'auto',
      },
      hidden: {
        overflow: 'hidden',
        height: '0px',
      },
    }),
  },
  mixins: [ColorsMixin],
  props: {
    ticket: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      intervalId: undefined,
      showLink: false,
      qrcodeUrl: '',
    };
  },
  computed: {
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    user() {
      return this.$store.state.user.user;
    },
    ticketBuilding() {
      if (!this.ticket.buildingUuid) return null;
      return this.$store.state.building.collection.find(b => b.uuid === this.ticket.buildingUuid) || null;
    },
    ticketFloor() {
      if (!this.ticketBuilding || !this.ticket.floorUuid) return null;
      return this.ticketBuilding.next.find(f => f.uuid === this.ticket.floorUuid) || null;
    },
    ticketSpace() {
      if (!this.ticketFloor || !this.ticket.spaceUuid) return null;
      return this.ticketFloor.next.find(s => s.uuid === this.ticket.spaceUuid) || null;
    },
  },
  async created() {
    const url = this.getPublicCreationUrl();
    const QRCode = require('qrcode');
    const res = await new Promise((resolve, reject) => {
      QRCode.toDataURL(url, (error, url) => {
        if (error) return reject(error);
        return resolve(url);
      });
    });
    this.qrcodeUrl = res;
  },
  mounted() {
    this.intervalId = setInterval(() => this.refreshTicket(), 3000);
    if (this.selectedBuilding && this.ticket.floorUuid) {
      const floorIdx = this.selectedBuilding.next.findIndex(f => f.uuid === this.ticket.floorUuid);
      this.$store.commit('building/setSelectedFloor', floorIdx);
    }
    if (!this.selectedBuilding) return;
    this.hoverTicketDot();
    if (!this.ticket.x && !this.ticket.y && this.ticket.spaceUuid) this.colorSpace();
    if (!this.ticket.spaceUuid && this.ticket.floorUuid) this.colorFloor();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    shareLink() {
      this.showLink = !this.showLink;
    },
    formatDate(date) {
      return moment(date)
        .format('D MMM YYYY, kk:mm')
        .toString();
    },
    refreshTicket() {
      this.$store.dispatch('tickets/readPrivate', this.ticket.shortId);
    },
    closeTicket() {
      if (this.selectedBuilding) {
        this.unHoverTicketDot();
        if (this.ticket && !this.ticket.x && !this.ticket.y && this.ticket.spaceUuid) this.unColorSpace();
        if (this.ticket && !this.ticket.spaceUuid && this.ticket.floorUuid) this.unColorFloor();
      }
      this.$store.commit('tickets/setCurrentTicket', undefined);
      this.$emit('switchTab');
    },
    async copyLink() {
      const url = this.getUrl(this.ticket.shortId);
      try {
        await this.$copyText(url);
        this.$q.notify({
          message: this.$t('pages.ticket.toasts.linkPastedClipboard'),
          type: 'positive',
          position: 'bottom-left',
        });
      } catch (error) {
        this.$q.notify({
          message: error.response.data || this.$t('pages.ticket.toasts.errorPastingClipboard'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    getUrl(shortId) {
      return `${location.protocol}//${location.host}/ticket/${shortId}`;
    },
    async copyLinkCreation() {
      const url = this.getPublicCreationUrl();
      try {
        await this.$copyText(url);
        this.$q.notify({
          message: this.$t('pages.ticket.toasts.linkPastedClipboard'),
          type: 'positive',
          position: 'bottom-left',
        });
      } catch (error) {
        this.$q.notify({
          message: error.response.data || this.$t('pages.ticket.toasts.errorPastingClipboard'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    getPublicCreationUrl() {
      const ticket = this.ticket;
      let strUrl = `${location.protocol}//${location.host}/create-ticket-redir?`;
      if (ticket.buildingUuid)
        strUrl += `b=${encodeURIComponent(ticket.buildingUuid)}&`;
      if (ticket.floorUuid)
        strUrl += `f=${encodeURIComponent(ticket.floorUuid)}&`;
      if (ticket.spaceUuid)
        strUrl += `r=${encodeURIComponent(ticket.spaceUuid)}&`;
      if (ticket.title)
        strUrl += `t=${encodeURIComponent(ticket.title || '')}&`;
      if (ticket.message)
        strUrl += `m=${encodeURIComponent(ticket.message || '')}&`;
      if (ticket.x)
        strUrl += `x=${encodeURIComponent(ticket.x)}&`;
      if (ticket.y)
        strUrl += `y=${encodeURIComponent(ticket.y)}`;

      return strUrl;
    },
    async downloadFile(file) {
      const { filename: name, mimetype: type } = file;
      try {
        const response = await api.tickets.public.file.read(this.ticket.shortId, file.shortId);
        const blob = new Blob([response.data], { type: type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      } catch (error) {
        this.$q.notify({
          message: error.response.data || 'Cannot download file',
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    async updateStatus(status) {
      try {
        await this.$store.dispatch('tickets/updatePrivate', { shortId: this.ticket.shortId, status });
      } catch (error) {
        this.$q.notify({
          message: error.response.data || this.$t('pages.ticket.toasts.errorUpdatingTicketStatus'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    hoverTicketDot() {
      const device = document.getElementById(this.ticket.shortId);
      if (device) {
        device.classList.add('device--big');
      }
    },
    colorSpace() {
      const space = this.$palmier.getSpace(this.ticket.spaceUuid);
      if (space) {
        this.spaceOldColor = space.style.fill;
        space.style.fill = this.colors.primary;
      }
    },
    colorFloor() {
      const spaces = this.ticketFloor.next.map(s => s.uuid);
      const space = this.$palmier.getSpace(spaces[0]);
      this.floorOldColor = space.style.fill;
      const hoveredZone = this.$palmier.zone(spaces);
      hoveredZone.apply({
        style: s => (s.fill = this.colors.primary),
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.ticket-view
  display flex
  flex-direction column
  overflow-y auto
  padding $space-2
  height 100%
  .button-row
    display flex
    flex-shrink 0
    align-items baseline
    padding 0 $space-2 $space-2

// ticket card container
.ticket-wrapper
  display flex
  flex-direction column
  border-radius $border-radius-smooth
  background-color white
  box-shadow $shadow-card
  .ticket-title
    padding $space-2
    width 100%
    color $font-color
    font-weight 700
    font-size $fs-h1

.ticket-actions
  display flex
  padding $space-2
  border-top $grid-border
  .share-button
    margin-right $space-1
  .edit-button
    border-color $dark
    background-color white
    color $dark
  .ticket-status
    flex-shrink 0
    margin-left auto

.share-link-container
  padding $space-2
  border-top $grid-border

// ticket url copy
.ticket-url
  display flex
  justify-content center
  align-items center
  width fit-content
  border-radius $border-radius-smooth
  background-color $dash-light
  color black
  text-transform none
  font-weight 400
  font-size 1.2rem
  .url
    margin 4px 16px
    margin-right 4px
    color $dark
  .copy-to-clipboard
    margin-left $space-1
    background-color $silver
    color $dark
    cursor pointer

// ticket infos
.ticket-infos
  border-bottom $grid-border

// ticket message
.ticket-message
  padding $space-2
  border-bottom $grid-border
  .message-label
    margin-bottom $space-1
    color lighten($dark, 40)
  .form-textarea
    width 100%
    outline none
    border none
    font-size 1.3rem
    resize none

// ticket documents
.ticket-documents
  padding $space-2
  .files-list
    display flex
    justify-content flex-start
    .file-item
      position relative
      display flex
      flex-direction column
      justify-content center
      align-items center
      margin-right 8px
      padding 16px
      border 1px solid lighten($grey, 70%)
      border-radius 5px
      box-shadow $shadow-elevation-1
      color darken($grey, 30%)
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size 0.9rem
      transition box-shadow 0.3s ease
      &--download
        cursor pointer
        &:hover
          box-shadow $shadow-elevation-2
      .cross
        position absolute
        top 5px
        right 5px
        cursor pointer
  .input-files
    position absolute
    z-index -1
    overflow hidden
    width 0.1px
    height 0.1px
    opacity 0
  .input-files + label
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding 16px
    border-radius 5px
    background-color lighten($grey, 70%)
    color darken($grey, 30%)
    text-transform uppercase
    letter-spacing 0.5px
    font-weight 700
    font-size 0.9rem
    cursor pointer
    transition all 100ms ease
    .picto
      margin-bottom 8px
    &:hover
      background-color lighten($grey, 30%)
      color white
      transition all 100ms ease
    &:focus
      outline 1px dotted #000
      outline -webkit-focus-ring-color auto 5px
    .inputfile + label *
      pointer-events none

.comments
  margin-top $space-2
.qrcode
  a
    color inherit
    text-decoration none
  .btn
    margin-bottom 0
    margin-top 12px
</style>
