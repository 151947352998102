<template>
  <!-- spaces -->
  <div>
    <Field
            hint="Select any nodes in this topology"
            required>
      <treeselect v-show="topology.length > 0"
                  v-model="selectedNodes"
                  :multiple="true"
                  :options="topology"
                  :normalizer="normalizer"
                  openDirection="top"
                  class="nodes-selector"
                  @input="updated" />
    </Field>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Field from '@/app/components/inputs/field.vue';

export default {
  name: 'NodesSelector',
  components: {
    Treeselect,
    Field,
  },
  props: {
    oldNodeList: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      selectedNodes: [],
    };
  },
  computed: {
    topology() {
      return this.$store.state.topology.buildings;
    },
  },
  created() {
    this.selectedNodes = this.oldNodeList;
    if (this.topology.length === 0) this.$store.dispatch('topology/fetchSimple');
  },
  methods: {
    normalizer(node) {
      return {
        id: node.uuid,
        label: node.name,
      };
    },
    updated() {
      this.$emit('input', this.selectedNodes);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.nodes-selector
  font-size $fs-h2
  & >>> .vue-treeselect__control
    border-color $black

</style>