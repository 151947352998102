export default class User {
  firstName = 'Bruce';
  lastName = 'Wayne';
  email = 'demo@qarnot.com';
  uuid = '00000000-0000-0000-0000-A0000000000A';
  defaultPage = undefined;
  featuresRights = {
    data: { READ: true },
    dashboard: { READ: false },
    oql: { READ: false },
    ticket: { READ: false },
    store: { READ: true },
    notifications: {},
    alerts: {},
    alertRules: {},
  };
  constructor(rawData = {}) {
    this.firstName = rawData.firstName;
    this.lastName = rawData.lastName;
    this.email = rawData.email;
    this.uuid = rawData.uuid;
    if (rawData.homepage) this.defaultPage = rawData.homepage;
    this.featuresRights = {
      ...this.featuresRights,
      ...rawData.features,
    };
  }
}
