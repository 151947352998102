import Vue from 'vue';
import api from 'api';
const getDefaultState = () => ({
  collection: [],
  fetching: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    read: state => country => state.collection.find(c => c.label === country || c.alpha2 === country),
  },
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    setCollection: (state, countries) => Vue.set(state, 'collection', countries),
    setFetchState: (state, fetchState) => (state.fetching = fetchState),
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    fetch: async ({ commit }) => {
      commit('setFetchState', true);
      let { data: countries } = await api.country.fetch();
      countries = Object.keys(countries).reduce((acc, key) => {
        acc.push({
          label: countries[key],
          value: countries[key],
          alpha2: key,
        });
        return acc;
      }, []);
      commit('setCollection', countries);
      commit('setFetchState', false);
    },
  },
};
