













































import Vue, { PropType } from 'vue';
import InlineEditor from '@/app/components/ui/inline-editor.vue';
import { between, required } from 'vuelidate/lib/validators';
import { ApiDeviceProperty, ApiDevicePropertyConstraintsType } from '@/types/api';
import api from '@/services/api';
import { errorMessageFromApiError } from '@/services/api/utils';
import store from '../../../../services/store';

export default Vue.extend({
  name: 'DevicePropertyEditor',
  components: {
    InlineEditor,
  },
  props: {
    deviceUuid: {
      type: String,
      required: true,
    },
    property: {
      type: Object as PropType<ApiDeviceProperty>,
      required: true,
    },
  },
  data: () => ({
    value: null as any,
    isSaving: false,
  }),
  computed: {
    devicesControlRights() {
      return store.getters['user/featuresRights'].devicesControl;
    },
    vuelidateValidator(): any {
      const res = { } as any;
      const constraints = this.property.constraints;
      switch (constraints?.type) {
        case ApiDevicePropertyConstraintsType.Number:
          if (constraints.min !== undefined && constraints.max !== undefined) {
            res.between = between(constraints.min, constraints.max);
            res.required = required;
          }
      }
      return res;
    },
    isNumber(): boolean {
      return this.property.constraints?.type === ApiDevicePropertyConstraintsType.Number;
    },
    isBoolean(): boolean {
      return this.property.constraints?.type === ApiDevicePropertyConstraintsType.Boolean;
    },
    isAction(): boolean {
      return this.property.constraints?.type === ApiDevicePropertyConstraintsType.Action;
    },
  },
  watch: {
    property: {
      immediate: true,
      handler() {
        this.value = this.property.value;
      },
    },
  },
  methods: {
    // <input type="number"> -> get numerical value from event. we could use target.valueAsNumber but some people on Internet say it's sometimes broken...
    convertInputNumberValueFromEventAndCallUpdate(event: any, update: any) {
      const value = Number(event.target.value);
      update(value);
    },
    async saveProperty(value: any) {
      this.isSaving = true;
      try {
        await api.oasisDevices.setDeviceProperty(this.deviceUuid, this.property.dataProfile.id, value);
        this.$emit('saved', value);
      } catch (err) {
        this.$emit('error', err);
        throw new Error(errorMessageFromApiError(err as Error));
      } finally {
        this.isSaving = false;
      }
    }
  }
});
