<template>
  <div class="ticket-status">
    <button :class="stateClass('todo')"
            class="state t_state--todo o-button"
            @click.stop="changeStatus('todo')">
      {{$t('pages.ticket.sidebarOut.ticketStatus.todo')}}
    </button>
    <ChevronPicto size="10px"
                  class="separator-arrow" />
    <button :class="stateClass('in progress')"
            class="state t_state--progress o-button"
            @click.stop="changeStatus('in progress')">
      {{$t('pages.ticket.sidebarOut.ticketStatus.inProgress')}}
    </button>
    <ChevronPicto size="10px"
                  class="separator-arrow" />
    <button :class="stateClass('done')"
            class="state t_state--done o-button"
            @click.stop="changeStatus('done')">
      {{$t('pages.ticket.sidebarOut.ticketStatus.done')}}
    </button>
  </div>
</template>

<script>
import ChevronPicto from '@/app/components/ui/pictos/chevron.vue';

export default {
  name: 'TicketStatus',
  components: {
    ChevronPicto,
  },
  props: {
    state: {
      type: String,
      default: 'todo',
      validator: value => ['todo', 'in progress', 'done'].includes(value),
    },
  },
  methods: {
    stateClass(state) {
      return {
        'state--current': this.state === state,
      };
    },
    changeStatus(newState) {
      this.$emit('input', newState);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

$state-blue = #6368df
$state-orange = #957000
$state-green = #398539

.ticket-status
  display flex
  align-items center
  padding 0
  width fit-content
  .state
    padding $space-little
    background-color transparent
    color lighten($dark, 40%)
    text-transform uppercase
    letter-spacing 0.5px
    font-weight 300
    font-size $fs-little
    cursor pointer
  .state:hover, .state--current
    border-radius $border-radius-smooth
    background-color $dark
    color white
    font-weight 700
    // &:hover

.separator-arrow
  margin 0 $space-1
  transform rotate(-90deg)
</style>
