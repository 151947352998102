










import Vue, { PropType } from 'vue';

export enum ButtonGradient {
  Primary,
  Secondary,
}

export default Vue.extend({
  name: 'Button',
  props: {
    gradient: {
      type: Number as PropType<ButtonGradient>,
      default: ButtonGradient.Primary,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    }
  },
  computed: {
    computedClass(): any {
      return {
        'o-button--primary-gradient': this.gradient === ButtonGradient.Primary,
      };
    }
  },
  methods: {
    forwardClick() {
      this.$emit('click');
    }
  }
});
