














import Vue, { PropType } from 'vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Field from '@/app/components/inputs/field.vue';
import { NodesGroup } from '@/types/api';

export default Vue.extend({
  name: 'NodesGroupSelector',
  components: {
    Treeselect,
    Field,
  },
  props: {
    initialNodesGroup: {
      type: Array as PropType<Array<number>>,
      default: () => ([]),
    },
  },
  data() {
    return {
      nodesGroupSelected: [] as Array<number>,
    };
  },
  computed: {
    availableNodesGroup(): Array<NodesGroup> | null {
      return this.$store.state.nodesGroup.collection;
    }
  },
  created() {
    this.$store.dispatch('nodesGroup/fetch');
    this.nodesGroupSelected = [ ...this.initialNodesGroup ];
    this.updated();
  },
  methods: {
    normalizer(nodesGroup: NodesGroup) {
      return {
        id: nodesGroup.id,
        label: nodesGroup.name,
      };
    },
    updated() {
      this.$emit('input', this.nodesGroupSelected);
    },
  }
});

