import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from './locales';
import config from '@/../config/config';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: config.i18n.locale,
  fallbackLocale: config.i18n.fallbackLocale,
  messages: {
    fr: locales.fr,
    en: locales.en,
  },
});

export default i18n;
