import _ from 'lodash';
import ticket from './ticket-locale-object.json';
const ticketFR = _.cloneDeep(ticket);

// Sidebar out
ticketFR.sidebarOut.title = 'tickets';
ticketFR.sidebarOut.ticketStatus.all = 'tous';
ticketFR.sidebarOut.ticketStatus.new = 'nouveau';
ticketFR.sidebarOut.ticketStatus.todo = 'à faire';
ticketFR.sidebarOut.ticketStatus.inProgress = 'en cours';
ticketFR.sidebarOut.ticketStatus.done = 'terminé';
ticketFR.sidebarOut.buttons.createNewTicket = 'créer un nouveau ticket';
ticketFR.sidebarOut.buttons.createTicket = 'créer un ticket';
ticketFR.sidebarOut.messages.noTicket = 'pas de ticket';
ticketFR.sidebarOut.messages.createTicketOnBuilding = 'pour créer un ticket sélectionnez le bâtiment';
ticketFR.sidebarOut.messages.createTicketOnSpace = `vous pouvez aussi faire un  clique droit sur une pièce pour créer un ticket`;
// ticket info
ticketFR.ticketInfo.title = 'nouveau ticket';
ticketFR.ticketInfo.date = 'date';
ticketFR.ticketInfo.state = 'état';
ticketFR.ticketInfo.author = 'créateur';
ticketFR.ticketInfo.location = 'localisation';
ticketFR.ticketInfo.goToTicket = 'aller au ticket';
// creation / edition
ticketFR.creationEdition.createTitle = `création de ticket`;
ticketFR.creationEdition.editTitle = `édition de ticket`;
ticketFR.creationEdition.labels.title = `titre`;
ticketFR.creationEdition.labels.scope = `portée`;
ticketFR.creationEdition.labels.type = `type`;
ticketFR.creationEdition.labels.priority = `priorité`;
ticketFR.creationEdition.labels.message = `message`;
ticketFR.creationEdition.labels.documents = `documents`;
ticketFR.creationEdition.labels.addDocument = `ajouter un document`;
ticketFR.creationEdition.labels.moreActions = `plus d'options`;
ticketFR.creationEdition.labels.building = `bâtiment`;
ticketFR.creationEdition.labels.map = `carte`;
// scopes
ticketFR.creationEdition.scopes.dot = `point`;
ticketFR.creationEdition.scopes.room = `pièce`;
ticketFR.creationEdition.scopes.floor = `étage`;
ticketFR.creationEdition.scopes.building = `bâtiment`;
// types
ticketFR.creationEdition.types.plumbing = `plomberie`;
ticketFR.creationEdition.types.electricity = `électricité`;
ticketFR.creationEdition.types.cleaning = `nettoyage`;
ticketFR.creationEdition.types.intervention = `intervention`;
ticketFR.creationEdition.types.other = `autre`;
// priorities
ticketFR.creationEdition.priorities.low = `basse`;
ticketFR.creationEdition.priorities.medium = `normale`;
ticketFR.creationEdition.priorities.high = `haute`;
//        buttons
ticketFR.creationEdition.buttons.create = `créer`;
ticketFR.creationEdition.buttons.update = `sauvegarder`;
ticketFR.creationEdition.buttons.delete = `supprimer`;
ticketFR.creationEdition.buttons.share = `partager`;
ticketFR.creationEdition.buttons.edit = `éditer`;
ticketFR.creationEdition.buttons.copy = `copier`;
ticketFR.creationEdition.buttons.cancel = `annuler`;
//        messages
ticketFR.creationEdition.messages.noDocuments = `aucun document`;
// Comments
ticketFR.comments.title = 'commentaires';
ticketFR.comments.noComments = 'aucun commentaire';
ticketFR.comments.send = 'envoyer';
// toasts
ticketFR.toasts.errorCreatingUpdatingTicket = 'Erreur de création/Mise a jour du ticket';
ticketFR.toasts.cannotDownloadFile = 'Impossible de télécharger le fichier';
ticketFR.toasts.cannotDeleteFile = 'Impossible de supprimer le fichier';
ticketFR.toasts.ticketDeleted = 'Le ticket à bien été supprimé';
ticketFR.toasts.errorDeletingTicket = 'Erreur lors de la suppression du ticket';
ticketFR.toasts.linkPastedClipboard = 'Lien copié dans le presse papier';
ticketFR.toasts.errorPastingClipboard = 'Erreur lors de la copie du lien dans le presse papier';
ticketFR.toasts.errorUpdatingTicketStatus = 'Erreur lors de la mise a jour du status';

export default ticketFR;
