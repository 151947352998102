import _ from 'lodash';
import baseObject from './alertRule-locale-object.json';
const alertRuleFR = _.cloneDeep(baseObject);

alertRuleFR.creationEdition.labels.title = 'titre de la règle';
alertRuleFR.creationEdition.labels.description = 'description';
alertRuleFR.creationEdition.labels.type = 'type de règle';
alertRuleFR.creationEdition.labels.profile = 'profile de données';
alertRuleFR.creationEdition.labels.config = 'configuration';
alertRuleFR.creationEdition.labels.nodeList = 'liste de noeuds';
alertRuleFR.creationEdition.labels.trigger = 'conditions de déclenchement de l\'alerte';
alertRuleFR.creationEdition.labels.resolve = 'conditions de résolution de l\'alerte';
alertRuleFR.creationEdition.labels.selector = 'selector';
alertRuleFR.creationEdition.labels.enabled = 'activée';
alertRuleFR.creationEdition.labels.save = 'sauvegarder';
alertRuleFR.creationEdition.labels.receivers = 'destinataires';
alertRuleFR.creationEdition.labels.selectReceivers = `Selectionnez les destinataires de cette regle d'alertes`;

alertRuleFR.labels.title = 'titre';
alertRuleFR.labels.description = 'description';
alertRuleFR.labels.trigger = 'déclenchement';
alertRuleFR.labels.resolve = 'résolution';
alertRuleFR.labels.nodes = 'liste de noeuds';
alertRuleFR.labels.unit = 'unité';
alertRuleFR.labels.profile = 'profil de données';

alertRuleFR.labels.triggeredAlerts = 'alertes déclanchées';
alertRuleFR.labels.resolvedAlerts = 'alertes résolues';
alertRuleFR.labels.resolvedAlerts = 'alertes résolues';
alertRuleFR.labels.activeAlerts = 'alertes actives';
alertRuleFR.labels.emergencyHigh = 'urgence haute';
alertRuleFR.labels.emergencyNormal = 'urgence moyenne';
alertRuleFR.labels.emergencyLow = 'urgence basse';
alertRuleFR.labels.avgResolutionTime = 'Temps moyen de résolution';

alertRuleFR.buttons.createNewAlertRule = 'créer une nouvelle règle d\'alertes';
alertRuleFR.buttons.return = 'retour';
alertRuleFR.menu.buttons.center = 'Menu règles d\'alertes';

alertRuleFR.errors.unknowError = 'Règle inconnue';
alertRuleFR.errors.noRules = 'Aucune règle d\'alerte';
alertRuleFR.errors.failedToListReceivers = 'Erreur lors de la récupération de la liste des destinataires';

alertRuleFR.toasts.saved = 'Règle d\'alertes sauvegardée';

export default alertRuleFR;