import _ from 'lodash';
import data from './data-locale-object.json';
const dataFR = _.cloneDeep(data);

// TITLE
dataFR.tabTitle = 'donnée';
// SIDEBAR
dataFR.sidebarIn.time = 'Temporalité';
dataFR.sidebarIn.buildings = 'Bâtiments';
dataFR.sidebarIn.filters = 'Filtres';
dataFR.sidebarIn.parcels = 'Lots';
// NAV INFO
dataFR.navInfo.fullscreen = 'PLEIN ECRAN';
dataFR.navInfo.graph = 'GRAPH';
// SidebarOut
dataFR.sidebarOut.tabs.oapps = 'oapps';
dataFR.sidebarOut.tabs.node = 'nœud';
dataFR.sidebarOut.tabs.calc = 'somme';
dataFR.sidebarOut.tabs.data = 'données';
dataFR.sidebarOut.others.getNewApps = "Obtenez d'autres oapps";

export default dataFR;
