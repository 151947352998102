import { render, staticRenderFns } from "./ticket-create-edit-form.vue?vue&type=template&id=0d483422&scoped=true&"
import script from "./ticket-create-edit-form.vue?vue&type=script&lang=js&"
export * from "./ticket-create-edit-form.vue?vue&type=script&lang=js&"
import style0 from "./ticket-create-edit-form.vue?vue&type=style&index=0&id=0d483422&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d483422",
  null
  
)

export default component.exports