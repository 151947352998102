












































































import Vue from 'vue';
import api from '@/services/api';

import { HttpMetadataDefaults } from '@/types/http';
import { ApiPaginatedResponse, ApiPaginationQuery, ReportConfig } from '@/types/api';
import { QTableColumn, QTablePaginationParams } from '@/types/quasar';
import FetchableData from '@/app/components/ui/FetchableData.vue';

interface ReportConfigData {
  title: string,
  frequency: string,
  enabled: boolean,
  id: number,
}

export default Vue.extend({
  name: 'ReportConfigList',
  components: {
    FetchableData,
  },
  data: () => ({
    reportConfigs: {
      data: null as ApiPaginatedResponse<ReportConfig> | null,
      meta: { ...HttpMetadataDefaults }
    },
    serverPagination: {
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 0, // until the first fetch is done, we have no data -> 0 rows
    },
  }),
  computed: {
    tableColumns(): QTableColumn[]{
      return [
        {
          name: 'title',
          field: 'title',
          required: true,
          label: this.capitalize(`${this.$t('pages.report.list.name')}`),
          align: 'left',
        },
        {
          name: 'frequency',
          field: 'frequency',
          required: true,
          label: this.capitalize(`${this.$t('pages.report.list.frequency')}`),
          align: 'center',
        },
        {
          name: 'enabled',
          field: 'enabled',
          required: true,
          label: this.capitalize(`${this.$t('pages.report.list.enabled')}`),
          align: 'center',
        },
        {
          name: 'details',
          field: 'details',
          required: true,
          label: this.capitalize(`${this.$t('pages.report.list.details')}`),
          align: 'center',
        },
      ];
    },
    reportConfigData(): ReportConfigData[] {
      if (!this.reportConfigs.data) return [];
      const data = (this.reportConfigs.data.elts).map(reportConfig => {
        return {
          title: reportConfig.title,
          frequency: this.frequencyList(reportConfig),
          enabled: reportConfig.enabled,
          id: reportConfig.id,
        };
      });
      return data;
    },
    featuresRights() {
      return this.$store.state.user.user.featuresRights;
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    qTablePaginationToApiPagination({ page, rowsPerPage }: QTablePaginationParams): ApiPaginationQuery {
      return {
        offset: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    },
    apiPaginationToQTablePagination({offset, limit}: ApiPaginationQuery): QTablePaginationParams {
      return {
        page: 1 + Math.floor(offset / limit),
        rowsPerPage: limit,
      };
    },
    async onPaginationUpdated({ pagination } : { pagination: QTablePaginationParams }) {
      await this.fetch(this.qTablePaginationToApiPagination(pagination));
    },
    /**
     * Fetch data from the server, and update pagination.
     *
     * @param apiPagination optional pagination to set, if not provided fetch will re-use the current pagination
     */
    async fetch(apiPagination?: ApiPaginationQuery) {
      if (this.reportConfigs.meta.isLoading) {
        return;
      }

      this.reportConfigs.meta = {
        ...this.reportConfigs.meta,
        isLoading: true,
        error: null,
      };

      try {
        // if pagination changes, update pagination in UI
        if (apiPagination) {
          this.serverPagination = {
            ...this.serverPagination,
            ...this.apiPaginationToQTablePagination(apiPagination),
          };
        } else {
          apiPagination = this.qTablePaginationToApiPagination(this.serverPagination);
        }

        const apiRes = (await api.reportConfig.getList(apiPagination));

        this.reportConfigs.data = apiRes.data;
        this.reportConfigs.meta.lastFetch = new Date();

        // update total number of items on server's pagination
        this.serverPagination.rowsNumber = apiRes.data.total;
      } catch (err: any) {
        this.reportConfigs.meta.error = err.message || this.$t('global.errors.generic');
      }
      this.reportConfigs.meta.isLoading = false;
    },
    capitalize(s: string): string {
      if (!s || s.length === 0) return s;
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    frequencyList(reportConfig: ReportConfig): string {
      if (!reportConfig) {
        return '';
      }
      const a = [];
      if (reportConfig.daily) {
        a.push('Quotidien');
      }
      if (reportConfig.weekly) {
        a.push('Hebdomadaire');
      }
      if (reportConfig.monthly) {
        a.push('Mensuel');
      }
      if (reportConfig.yearly) {
        a.push('Annuel');
      }
      return a.join(', ');
    },
    navigate(reportConfigId: string) {
      this.$router.push({ name: 'reportConfigView', params: { reportConfigId: reportConfigId } });
    },
    async update(value: boolean, reportConfigId: number) {
      await api.reportConfig.update(reportConfigId, { enabled: value });
      await this.fetch();
    },
  },
});
