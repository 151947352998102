<template>
  <router-link
    v-slot="{ navigate }"
    :to="routerLinkParamsTo"
  >
  <div class="alert-simple"
      :class="emergencyLevel"
      @click="navigate">

      <!-- simple row: message -->
      <div class="field grid-wrapper">
        <label class="o-label">{{ $t('pages.alert.simple.message') }}</label>
        <div class="message strong grid-tile__value">{{ alert.message }}</div>
      </div>

      <!-- row with tiles -->
      <div class="grid-wrapper">
        <!-- tile: device -->
        <div v-if="alert.details.deviceUuid"
            class="field grid-tile">
          <label class="o-label">{{ $t('pages.alert.simple.device') }}</label>
          <div class="device grid-tile__value">{{ alert.details.deviceUuid }}</div>
        </div>

        <!-- tile: emergency level -->
        <div class="field grid-tile">
          <label class="o-label">{{ $t('pages.alert.simple.emergencyLevel') }}</label>
          <div class="emergency-level grid-tile__value">
            {{$t(`pages.alert.priorities.${emergencyLevel}`)}}
          </div>
        </div>
        <!-- tile: active since -->
        <div class="field grid-tile">
          <label class="o-label">{{ $t('pages.alert.simple.dateCreated') }}</label>
          <div class="date-created grid-tile__value">
            {{ activeSince }}
          </div>
        </div>
        <!-- tile: resolved since -->
        <div v-if="dateResolved"
            class="field grid-tile">
          <label class="o-label">{{ $t('pages.alert.simple.resolved') }}</label>
          <div class="date-resolved">
            {{ dateResolved }}
          </div>
        </div>

      </div>

      <!-- row: location -->
      <div v-if="strLocation"
           class="field grid-wrapper">
        <label class="o-label">{{ $t('pages.alert.simple.location') }}</label>
        <div class="location grid-tile__value">
          {{ strLocation }}
        </div>
      </div>

      <!-- row with tiles: profile -->
      <div class="grid-wrapper">
        <!-- tile: profile name -->
        <div v-if="dataProfileName"
            class="field grid-tile">
          <label class="o-label">{{ $t('pages.alert.simple.profileName') }}</label>
          <div class="data-profile-name grid-tile__value">
            {{ dataProfileName }}
          </div>
        </div>
        <!-- tile: profile unit -->
        <div v-if="dataProfileUnit"
            class="field grid-tile">
          <label class="o-label">{{ $t('pages.alert.simple.profileUnit') }}</label>
          <div class="data-profile-unit grid-tile__value">
            {{ dataProfileUnit }}
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import moment from 'moment';
import TopologyMixin from '@/app/mixins/topology';

/**
 * emits:
 *  - alertUpdated: when the alert has been updated. It should trigger an update from the component that passes the prop 'alert'
 */
export default {
  name: 'AlertSimple',
  mixins: [ TopologyMixin ],
  props: {
    alert: {
      type: Object,
      required: true,
    },
    alertRule: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      pathNodes: [],
      emergencyLevels: ['low', 'normal', 'high']
    };
  },
  computed: {
    dataProfiles() {
      return this.$store.state.profiles.collection;
    },
    emergencyLevel() {
      if (this.isResolved) return 'resolved';
      return this.emergencyLevels[this.alert.urgence];
    },
    activeSince() {
      return `${moment(this.alert.createdAt).locale(this.$i18n.locale).fromNow()}`;
    },
    dateResolved() {
      if (!this.alert.dateResolved) return null;
      return `${moment(this.alert.dateResolved).locale(this.$i18n.locale).fromNow()}`;
    },
    isResolved() {
      return !!this.alert.dateResolved;
    },
    dataProfile() {
      if (this.alertRule.selector?.dataProfileId === undefined) {
        return null;
      }
      // dataProfiles format: [{"value":4,"label":"Temperature air","unit":"°C"},...]
      const dataProfile = this.dataProfiles.find(profile => profile.value === this.alertRule.selector.dataProfileId);
      return dataProfile;
    },
    dataProfileName() {
      if (!this.dataProfile) {
        return null;
      }
      return this.dataProfile.label;
    },
    dataProfileUnit() {
      if (!this.dataProfile) {
        return null;
      }
      return this.dataProfile.unit;
    },
    strLocation() {
      if (this.pathNodes) {
        return this.pathNodes.map(node => node.name).join(' > ');
      }
      return this.$t('pages.alert.simple.locationNotFound');
    },
    routerLinkParamsTo() {
      return {
        name: 'alertDetails',
        params: {
          alertId: this.alert.id
        }
      };
    }
  },
  async created() {
    const pathNodes = await this.getFullPathToNode(this.alert.details.deviceUuid);
    this.pathNodes = pathNodes.filter(node => node.nodeType !== 'root').reverse();
  }
};
</script>

<style lang="stylus" scoped>
@import '~variables'

$grid-border-dark = 1px solid #dfdfdf

.alert-simple
  cursor pointer
  border-radius $border-radius-smooth
  .label
    color grey
  .strong
    font-size $fs-h3
    font-weight 600
  .field
    padding 10px
    display flex
    flex-direction column
  .field:first-child
    border-top 0
  .field:last-child
    border-right 0

.low
  border 4px solid #d3eaff
.normal
  border 4px solid #fbe1be
.high
  border 4px solid #f29096
.resolved
  border 0
  background-color #dcdcdc

.o-label
  text-transform capitalize
  color lighten($dark, 20)
  margin-bottom 0

.grid-wrapper
  border-top $grid-border-dark
.grid-tile
  border-right $grid-border-dark
.device
  word-break break-all;
</style>