<template>
  <svg :style="svgStyle"
       viewBox="0 0 50 50"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <line :style="lineStyle"
          x1="9.09005"
          y1="8.38285"
          x2="41.617"
          y2="40.9098"
          stroke-width="4"
    />
    <line :style="lineStyle"
          x1="8.3873"
          y1="40.9054"
          x2="41.0471"
          y2="8.512"
          stroke-width="4"
    />
  </svg>
</template>

<script>
export default {
  name: 'CrossPicto',
  props: {
    size: {
      type: String,
      default: '100%',
    },
    color: {
      type: String,
      default: 'black',
    },
  },
  computed: {
    svgStyle() {
      return {
        width: this.size,
        height: this.size,
      };
    },
    lineStyle() {
      return {
        stroke: this.color,
      };
    },
  },
};
</script>
