





























































import Vue, { PropType } from 'vue';
import moment from 'moment';
import DebounceMixin from '@/app/mixins/debounce';
import { directive as onClickaway } from 'vue-clickaway';

export enum PeriodType {
  Day =  'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

/**
 * Type emitted when period changes.
 * If "day" then consider only the "from" value.
 */
export type Period = {
  periodType: PeriodType;
  from: string;
  to: string;
};

interface ValueLabelArray extends Array<{ label: string, value: number }> {}

/**
 * Emits 'input' with selected Period as argument.
 * At launch, emits a day period corresponding to yesterday.
 */
export default Vue.extend({
  name: 'PeriodSelector',
  directives: { onClickaway: onClickaway },
  mixins: [DebounceMixin],
  props: {
    // the minimum date we want to allow choosing, defaults to the begining of 5 years ago
    minDate: {
      type: Object as PropType<Date>,
      default: moment().utc().subtract(5, 'years').startOf('year').toDate(),
    }
  },
  data() {
    const yesterday = moment().subtract(1, 'days').utc().toDate();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    return {
      periods: [
        { value: PeriodType.Day, label: 'D' },
        { value: PeriodType.Week, label: 'W' },
        { value: PeriodType.Month, label: 'M' },
        { value: PeriodType.Year, label: 'Y' },
      ],
      selectedDate: yesterday,
      selectedPeriodType: PeriodType.Day,
      lastDay: yesterday,
      showCalendar: false,
      PeriodType: PeriodType, // needed so that it's accessible from the template
      selectedMonth: currentMonth,
      currentYear: currentYear,
      selectedYear: currentYear,
    };
  },
  computed: {
    formattedSelectedDay(): string {
      return moment(this.selectedDate).format('L');
    },
    formattedSelectedWeek(): string {
      const beginingOfWeek = moment(this.selectedDate).startOf('isoWeek');
      const endOfWeek = beginingOfWeek.clone().add(6, 'days');
      return `${beginingOfWeek.format('L')} - ${endOfWeek.format('L')}`;
    },
    yearOptions(): ValueLabelArray {
      return Array
        .from({
          length: moment().year() - moment(this.minDate).year() + 1
        })
        .map((_, idx) => idx + moment(this.minDate).year()) // [ year of minDate, ..., currentYear ]
        .map(year => ({ value: year, label: `${year}` }));
    },
    minMonthOptionsIdx(): number {
      if (this.selectedYear !== moment(this.minDate).year()) {
        return 0;
      } else {
        return moment(this.minDate).month();
      }
    },
    // if minDate is for ex in june and selectedYear is the year of minDate, we should restrict months to [ june ... december ]
    monthOptions(): ValueLabelArray {
      return moment.months()
        .map((monthName, idx) => ({value: idx, label: monthName })) // [ { label: 'january', value: 0 }, ...]
        .slice(this.minMonthOptionsIdx);
    },
  },
  mounted() {
    // emit first value
    this.emitPeriod();
  },
  methods: {
    periodClass(periodType: PeriodType): any{
      return {
        'period--selected': this.selectedPeriodType === periodType,
      };
    },
    onSelectMonthInput() {
      this.emitPeriod();
    },
    onSelectYearInput() {
      if (this.minMonthOptionsIdx > this.selectedMonth) {
        this.selectedMonth = this.minMonthOptionsIdx;
      }
      this.emitPeriod();
    },
    // Emits the 'input' signal with the the currently selected period.
    emitPeriod() {
      const period: Period = {
        periodType: this.selectedPeriodType,
        from: '',
        to: '',
      };

      switch (this.selectedPeriodType) {
        case PeriodType.Day: {
          period.from = moment(this.selectedDate).startOf('day').format();
          period.to = moment(this.selectedDate).endOf('day').format();
          break;
        }
        case PeriodType.Week: {
          period.from = moment(this.selectedDate).startOf('isoWeek').format();
          period.to = moment(this.selectedDate).endOf('isoWeek').format();
          break;
        }
        case PeriodType.Month: {
          const beginingOfMonth = moment([this.selectedYear, this.selectedMonth]);
          period.from = beginingOfMonth.startOf('month').format();
          period.to = beginingOfMonth.endOf('month').format();
          break;
        }
        case PeriodType.Year: {
          const beginingOfYear = moment([this.selectedYear]);
          period.from = beginingOfYear.startOf('year').format();
          period.to = beginingOfYear.endOf('year').format();
          break;
        }
      }

      this.$emit('input', period);
    },
    // Called when the user clicks on a range with the button
    // Type of arg is the type of elts of this.periods defined in data().
    selectRange(period: { value: PeriodType ; label: string; }) {
      this.selectedPeriodType = period.value;

      // lastDay is the limit for calendar dates
      if (this.selectedPeriodType === PeriodType.Day) {
        this.lastDay = moment().subtract(1, 'days').utc().toDate(); // yesterday
      } else {
        this.lastDay = moment().subtract(1, 'weeks').endOf('isoWeek').toDate(); // last day of the previous week
      }
      this.emitPeriod();
    },
    onDatetimePickerInput(calendarDate: string) {
      this.emitPeriod();
      this.toggleShowCalendar();
    },
    toggleShowCalendar() {
      this.showCalendar = !this.showCalendar;
    },
  },
});
