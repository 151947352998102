const palmier = options => {
  const opt = {
    buildingId: 'buildingContainer',
    keys: {
      Control: false,
      Escape: false,
    },
    ...options,
  };
  document.addEventListener('keydown', e => (opt.keys[e.key] = Object.keys(opt.keys).includes(e.key) ? true : false));
  document.addEventListener('keyup', e => (opt.keys[e.key] = Object.keys(opt.keys).includes(e.key) ? false : true));
  return {
    getBuilding: () => document.getElementById(opt.buildingId),
    getFloor: idFloor => document.getElementById(`floor${idFloor}`),
    getSpace(idSVG) {
      return this.getBuilding().querySelector(`.space[id='${idSVG}']`);
    },
    getSpaces(spaces) {
      return spaces.reduce((acc, s) => {
        const space = this.getSpace(s.idIFC);
        if (space) acc[s.idIFC] = space;
        return acc;
      }, {});
    },
    getZone(spaceList) {
      return spaceList.reduce((acc, idSVG) => {
        const space = this.getSpace(idSVG);
        if (space) acc[idSVG] = space;
        return acc;
      }, {});
    },
    building(options) {
      const building = this.getBuilding();
      const startEvents = () => {
        if ('events' in options)
          Object.keys(options.events).forEach(k => {
            building.addEventListener(
              k,
              evt => {
                const space = this.getSpace(evt.target.id);
                options.events[k](space, evt, opt.keys);
              },
              { passive: true }
            );
          });
      };
      const removeEvents = () => {
        if ('events' in options)
          Object.keys(options.events).forEach(evtName => building.removeEventListener(evtName, options.events[evtName]));
      };
      const resetEvents = () => {
        removeEvents();
        startEvents();
      };
      return { building, startEvents, removeEvents, resetEvents };
    },
    zone(spaceList) {
      const spaces = this.getZone(spaceList);
      function apply(options) {
        if (!this.spaces) return;
        Object.keys(this.spaces).forEach(idSVG => {
          const s = this.spaces[idSVG];
          //style
          if ('style' in options) options.style(s.style);
          //DOM events
          if ('events' in options) Object.keys(options.events).forEach(k => s.addEventListener(k, () => options.events[k](s, opt.keys)));
        });
      }
      return { spaces, apply };
    },
    propertySpaces(spaceList) {
      const spaces = this.getSpaces(spaceList);
      function apply(options) {
        if (!this.spaces) return;
        Object.keys(this.spaces).forEach(idIFC => {
          const s = this.spaces[idIFC];
          //style
          if ('style' in options) options.style(s.style);
          //DOM events
          if ('events' in options) Object.keys(options.events).forEach(k => s.addEventListener(k, () => options.events[k](s, opt.keys)));
        });
      }
      return { spaces, apply };
    },
    spaces(options) {
      let spaces = [...this.getBuilding().querySelectorAll(`.space`)];
      if (options) {
        if (options.blacklist) {
          spaces = spaces.filter(space => !options.blacklist.includes(space.id));
        }
        else if (options.whitelist) {
          spaces = spaces.filter(space => options.whitelist.includes(space.id));
        }
      }
      function apply(options) {
        if (!this.spaces) return;
        Object.keys(this.spaces).forEach(idSVG => {
          const s = this.spaces[idSVG];
          //style
          if ('style' in options) options.style(s.style);
          //DOM events
          if ('events' in options) Object.keys(options.events).forEach(k => s.addEventListener(k, () => options.events[k](s, opt.keys)));
        });
      }
      return { spaces, apply };
    },
    nameSpaces(options) {
      let nameSpaces = [...this.getBuilding().querySelectorAll(`.space-name`)];
      if (options) {
        if (options.blacklist) {
          nameSpaces = nameSpaces.filter(nameSpace => !options.blacklist.includes(nameSpace.id));
        }
        else if (options.whitelist) {
          nameSpaces = nameSpaces.filter(nameSpace => options.whitelist.includes(nameSpace.id));
        }
      }
      function apply(options) {
        if (!this.nameSpaces) return;
        Object.keys(this.nameSpaces).forEach(idSVG => {
          const s = this.nameSpaces[idSVG];
          //style
          if ('style' in options) options.style(s.style);
          //DOM events
          if ('events' in options) Object.keys(options.events).forEach(k => s.addEventListener(k, () => options.events[k](s, opt.keys)));
        });
      }
      return { nameSpaces, apply };
    },
  };
};

export default {
  install: (Vue, options) => {
    Vue.prototype.$palmier = palmier(options);
  },
};
