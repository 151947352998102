import _ from 'lodash';
import notifications from './notification-locale-object.json';
const notificationEN = _.cloneDeep(notifications);

// pop-up
notificationEN.popUp.noUnread = 'You have no unread notifications';
notificationEN.popUp.noNotification = 'You have no notifications';
notificationEN.popUp.center = 'Notification center';
notificationEN.popUp.seeMore = 'See more';
// actionLink
notificationEN.actionLink.view = 'view';
notificationEN.actionLink.error = 'link not found';

export default notificationEN;