import securedAxios from '../securedAxios';

const endpoint =  '/api/space/user-topology';
const endpointSchema = '/api/schema';
const node = {
  fetch() {
    return securedAxios.get(`/api/space/topology`);
  },
  create(node) {
    return securedAxios.post(`${endpoint}/node`, node);
  },
  delete(nodeUuid, force = false) {
    return securedAxios.delete(`${endpoint}/node/${nodeUuid}${force ? '/force' : ''}`);
  },
  update(nodeUuid, nodeFields) {
    return securedAxios.patch(`/api/space/node/${nodeUuid}`, nodeFields);
  },
  global: {
    getNodeSchema(nodeType) {
      return securedAxios.get(`${endpointSchema}/${nodeType}`);
    },
    getChildNodeTypes(nodeType) {
      return securedAxios.get(`${endpointSchema}/${nodeType}/can-contain`);
    },
    fetchProperties() {
      return securedAxios.get(`/api/space/space/properties`);
    },
  }
};

export default node;