import _ from 'lodash';
import oapps from './oapps-locale-object.json';
const oappsFR = _.cloneDeep(oapps);

// Commons
oappsFR.commons.noData = 'aucune donnée';
oappsFR.commons.close = 'fermer';
oappsFR.commons.more = 'davantage';
oappsFR.commons.dataRepresentationLabel = 'type de représentation';
oappsFR.commons.rainbow = 'aplat';
oappsFR.commons['heatmap-rainbow'] = 'carte de chaleur';
oappsFR.commons.binary = 'gradient';
oappsFR.commons.greyToPrimary = 'gradient unicolore';
// DATA
oappsFR.data.temperature = 'température';
oappsFR.data.co2 = 'CO2';
oappsFR.data.presence = 'occupation';
// MEAN
oappsFR.mean.temperature = 'température moyenne';
oappsFR.mean.co2 = 'CO2 moyen';
oappsFR.mean.presence = 'occupation moyenne';
// Temperature
oappsFR.temperature.name = 'température';

// Co2
oappsFR.co2.name = 'CO2';

// Presence
oappsFR.presence.name = 'Présence';

// Parking
oappsFR.parking.name = 'Parking';

// Sound
oappsFR.sound.name = 'Son';

// Philips Hue Lights
oappsFR['philips hue lights'].name = 'Philips Hue Lights';

// Air
oappsFR.air.name = 'Air';

// Carriers
oappsFR.carriers.name = 'Carriers';

// Wifi
oappsFR.wifi.name = 'Wifi';

// COV
oappsFR.cov.name = 'COV';

// Humidity
oappsFR.humidity.name = 'Humidité';

// Electricity
oappsFR.electricity.name = 'Électricité';

// Generic
oappsFR.generic.name = 'Générique';

// UTL
oappsFR.utl.name = 'UTL';

// Hager
oappsFR.hager.name = 'Hager';

export default oappsFR;
