export default class Oapp {
  id;
  color = {};
  description;
  icon = {};
  lastInterval;
  limits = {};
  name;
  profiles = [];
  type;
  types = [];
  data = {};
  component = {};
  installed = false;
  hidden = false;
  widgets = [];
  label;
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.color = rawData.color;
    this.description = rawData.description;
    this.icon = rawData.icon;
    this.lastInterval = rawData.lastInterval;
    this.limits = rawData.limits;
    this.name = rawData.name;
    this.profiles = rawData.profiles;
    this.type = rawData.types[0];
    this.types = rawData.types;
    this.data = rawData.data;
    this.installed = rawData.installed;
    this.hidden = rawData.hidden;
    this.icon = () => import(`@/oapps/commons/pictos/${rawData.icon}`);
    this.component = () => import(`@/oapps/${rawData.id}/components/dashboard.vue`);
    this.widgets = rawData.widgets;
    this.label = rawData.label;
  }
}
