import Node from 'models/topology/node';
import Building from 'models/topology/building';

export default class City extends Node {
  zipCode;
  metadata = {};
  constructor(rawData = {}, parent = null, options = null) {
    super(rawData, parent);
    this.zipCode = rawData.zipCode;
    this.metadata = rawData.metadata;
    if (rawData.next) this.next = rawData.next.map(building => new Building(building, this, options));
  }
}
