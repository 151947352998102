import api from 'api';
import Vue from 'vue';
import builder from '@/services/utils/builder';
import moment from 'moment';
import axios from 'axios';
const getDefaultState = () => ({
  query: '',
  oqlData: null,
  oqlError: '',
  loading: false,
  devices: [],
  builder: {
    columns: {},
    node: '',
    names: [],
    functions: [],
    limit: 100,
  },
  queryMode: false,
  topology: null,
});
const state = getDefaultState();

const getters = {
  formattedData: state => {
    const cards = [];
    if (!state.oqlData) return;
    state.oqlData.forEach(c => {
      const columns = [];
      if (c.result && c.result.length > 0) {
        for (const r of Object.keys(c.result[0])) {
          columns.push({
            name: r,
            label: r,
            field: r,
            align: 'left',
            sortable: true,
          });
        }
      }
      cards.push({
        name: c.name,
        label: c.name,
        columns: columns,
        data: c.result,
      });
    });
    return cards;
  },
};

const mutations = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  ['SET_LOADING'](state, loading) {
    state.loading = loading;
  },
  ['SET_OQL_DATA'](state, data) {
    state.oqlData = data;
  },
  ['SET_DEVICES'](state, data) {
    state.devices = data;
  },
  ['SET_BUILDER_COLUMNS'](state, cols) {
    Vue.set(state.builder, 'columns', cols);
  },
  ['SET_BUILDER_NODE'](state, node) {
    Vue.set(state.builder, 'node', node);
  },
  ['SET_BUILDER_NAMES'](state, names) {
    Vue.set(state.builder, 'names', names);
  },
  ['SET_BUILDER_FUNCTIONS'](state, functions) {
    Vue.set(state.builder, 'functions', functions);
  },
  ['SET_QUERY'](state, query) {
    state.query = query;
  },
  ['SET_QUERY_MODE'](state, mode) {
    if (state.queryMode) state.oqlData = null;
    state.queryMode = mode;
  },
  ['SET_BUILDER_LIMIT'](state, limit) {
    Vue.set(state.builder, 'limit', limit);
  },
  ['SET_TOPOLOGY'](state, data) {
    Vue.set(state, 'topology', data);
  },
  ['SET_OQL_ERROR'](state, error) {
    state.oqlError = error;
  },
};

const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  buildQuery({ state, rootState, commit }) {
    const b = state.builder;
    const date = {
      from: moment(rootState.selections.dateFrom)
        .utc()
        .format(),
      to: moment(rootState.selections.dateTo)
        .utc()
        .format(),
    };
    if (builder.disableSend(b.columns, date, b.names, b.functions)) return;
    const query = builder.buildQuery(b.columns, b.node, date, b.names, b.functions, b.limit);
    commit('SET_QUERY', query || '');
  },
  async sendQuery({ state, rootState, commit, dispatch }) {
    commit('SET_OQL_ERROR', '');
    commit('SET_OQL_DATA', null);
    if (!state.queryMode) dispatch('buildQuery');
    if (!state.query) return;
    commit('SET_LOADING', true);
    try {
      const { data } = await api.oql.query({ request: state.query });
      if (!data.error) {
        if (data.length > 0 && data[0].result) commit('SET_OQL_DATA', data);
      } else {
        commit('SET_OQL_ERROR', data.error);
        commit('SET_LOADING', false);
        commit('SET_OQL_DATA', null);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        commit('SET_OQL_ERROR', err.response.data);
        commit('SET_LOADING', false);
        commit('SET_OQL_DATA', null);
      }
    }
    commit('SET_LOADING', false);
  },
  async fetchDevices({ commit }) {
    try {
      const { data } = await api.devices.fetch();
      const devices = [];
      for (const q of data) {
        devices.push({
          label: q.device,
          value: q.device,
          sublabel: q.name,
        });
      }
      commit('SET_DEVICES', devices);
    } catch {
      commit('SET_DEVICES', []);
    }
  },
  async fetchTopology({ commit }) {
    try {
      const { data } = await api.node.fetch();
      commit('SET_TOPOLOGY', data);
    } catch {
      commit('SET_TOPOLOGY', null);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
