import _ from 'lodash';
import data from './data-locale-object.json';
const dataEN = _.cloneDeep(data);

// TITLE
dataEN.tabTitle = 'data';
// SIDEBAR
dataEN.sidebarIn.time = 'Time';
dataEN.sidebarIn.buildings = 'Buildings';
dataEN.sidebarIn.filters = 'Filters';
dataEN.sidebarIn.parcels = 'Parcels';
// NAV INFO
dataEN.navInfo.fullscreen = 'FULLSCREEN';
dataEN.navInfo.graph = 'GRAPH';
// SidebarOut
dataEN.sidebarOut.tabs.oapps = 'oapps';
dataEN.sidebarOut.tabs.node = 'node';
dataEN.sidebarOut.tabs.calc = 'calc';
dataEN.sidebarOut.tabs.data = 'data';
dataEN.sidebarOut.others.getNewApps = 'get new oapps';

export default dataEN;
