<template>
  <div id="buildingContainer"
    class="floor-container"
    v-html="svg" />
</template>

<script>
import ColorsMixin from '@/app/mixins/colors';
import DeviceActionsMixins from '@/app/mixins/building-viewer/actions/device';

/**
 * Only one of the props 'floorUuid' or 'floorBase64Svg' should be provided.
 * If providing floorUuid, the store should be filled with the topology.
 */
export default {
  name: 'Floor2DMap',
  mixins: [ ColorsMixin, DeviceActionsMixins ],
  props: {
    floorUuid: {
      type: String,
      default: null,
    },
    floorBase64Svg: {
      type: String,
      default: null,
    },
    highlightRooms: {
      type: Array,
      default: null,
    },
    highlightPoints: {
      type: Array,
      default: null,
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    svg() {
      if (this.floorBase64Svg) {
        return this.b64DecodeUnicode(this.floorBase64Svg);
      } else if (this.floorUuid) {
        const floorData = this.$store.state.building.floors[this.floorUuid];
        if (!floorData)
        {
          return '';
        }
        const floorBase64Svg = floorData.metadata && floorData.metadata.svg;
        if (!floorBase64Svg) {
          return '';
        }
        return this.b64DecodeUnicode(floorBase64Svg);
      }
      return '';
    }
  },
  watch: {
    svg: {
      immediate: true,
      handler(val) {
        if (!val) {
          return ;
        }
        setTimeout(this.highlightPointsAndRooms, 500);
      }
    },
  },
  mounted() {
    if (this.floorUuid) {
      // use topology name in floor svg space names
      const building = this.$store.state.building.collection
        .find(b => b.next.find(f => f.uuid === this.floorUuid));
      if (building) {
        const floor = building.next.find(f => f.uuid === this.floorUuid);
        if (floor) {
          const spaceNamesElts = document.querySelectorAll('text[class=space-name]');
          Object.values(spaceNamesElts).forEach(spaceNameElt => {
            const spaceTopo = floor.next.find(space => space.uuid === spaceNameElt.id);
            if (spaceTopo) {
              spaceNameElt.innerHTML = spaceTopo.name;
            }
          });
        }
      }
    }
  },
  methods: {
    b64DecodeUnicode(str) {
      return decodeURIComponent(
        atob(str)
          .split('')
          .map(function(c) {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
          })
          .join('')
      );
    },
    highlightPointsAndRooms() {
      if (this.highlightPoints && this.highlightPoints.length > 0) {
        const floor = document.querySelector('.floor-container');
        this.highlightPoints.forEach(points => {
          const dot = this.createDeviceDot(this.floorUuid, points.x, points.y, null, 10, this.color);
          floor.firstElementChild.appendChild(dot.$el);
        });
      }

      if (this.highlightRooms && this.highlightRooms.length > 0) {
        this.highlightRooms.forEach(roomUuid => {
          const space = this.$palmier.getSpace(roomUuid);
          space.style.fill = this.colors.primary;
        });
      }
    }
  }
};

</script>

<style lang="stylus" scoped>
@import '~variables'

.floor-container
  width 100%
  height 100%
  text-align center
  transition:
    top 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    left 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    z-index 0.2s cubic-bezier(0.02, 0.63, 0.31, 1.04)
  &.active
    svg
      pointer-events all
  &.drag
    svg
      cursor move
  &--zoomed
    svg
      .space-name
        display initial !important
        transition all 100ms ease
        user-select none
  & >>> svg
    overflow visible !important
    margin auto
    transition transform 0.2s ease
    @media screen and (max-width: 500px)
      transition transform 0.1s linear
    .space
      transition fill 0.2s ease
      fill $silver
      stroke $dark
      stroke-width 0.2
      &.selected
        opacity 1
        fill white !important
    .wall
      pointer-events none
      fill #333
      stroke-width 0
    .door
      fill $silver
      stroke black
      stroke-width 0.5px
    .window
      fill darken($light, 5%)
      stroke @fill
</style>