import i18n from '@services/i18n';
import errorsLocaleObject from '@services/i18n/locales/errors/errors-locale-object.json';

// The keys contained in errors.api localisation keys
const apiErrorMessageKeysSet = new Set(Object.keys(errorsLocaleObject.api));

/**
 * Transforms an error thrown by the API into a localized error message.
 * TO BE DONE BETTER to take into account more cases.
 * @param {Error} error the error returned by the api (error thrown by axios)
 * @returns {String} a localized error message
 */
function errorMessageFromApiError(error) {
  if (error && error.response && error.response.data && error.response.data.errorCode) {
    if (apiErrorMessageKeysSet.has(error.response.data.errorCode))
    {
      return i18n.t(`errors.api.${error.response.data.errorCode}`);
    }
  }
  return i18n.t('errors.generic');
}

export {
  errorMessageFromApiError,
};
