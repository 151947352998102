





































































import Vue from 'vue';

enum InlineEditorState {
  Viewing,
  Editing,
  Saving,
}

export default Vue.extend({
  name: 'InlineEditor',
  props: {
    value: {
      type: String,
      required: true,
    },
    // async function to save value
    saveFn: {
      type: Function,
      required: true,
    },
    // vuelidate validation rules
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    isWriteable: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    workingValue: null as any,
    state: InlineEditorState.Viewing,
    saveError: null,
  }),
  computed: {
    disabled(): boolean {
      return this.state !== InlineEditorState.Editing;
    },
    isViewing(): boolean {
      return this.state === InlineEditorState.Viewing;
    },
    isSaving(): boolean {
      return this.state === InlineEditorState.Saving;
    },
    isEditing(): boolean {
      return (
        this.state === InlineEditorState.Editing ||
        this.state === InlineEditorState.Saving
      );
    },
  },
  validations() {
    return {
      workingValue: {
        ...this.validationRules,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.workingValue = this.value;
      },
    },
    workingValue() {
      this.saveError = null;
    },
  },
  methods: {
    update(value: any) {
      this.$v.workingValue.$model = value;
    },
    beginEditing() {
      this.state = InlineEditorState.Editing;
    },
    async save() {
      this.state = InlineEditorState.Saving;
      this.saveError = null;
      try {
        await this.saveFn(this.workingValue);
        this.$emit('input', this.workingValue);
        this.state = InlineEditorState.Viewing;
      } catch (e) {
        this.saveError = e.message || e;
        this.$emit('error', e);
        this.state = InlineEditorState.Editing;
      }
    },
    cancel() {
      this.workingValue = this.value;
      this.state = InlineEditorState.Viewing;
      this.saveError = null;
    },
  },
});
