import Node from 'models/topology/node';
import Device from 'models/device';

export default class Plug extends Node {
  description;
  virtual;
  x;
  y;
  z;
  metadata = {};
  constructor(rawData = {}, parent = null) {
    super(rawData, parent);
    this.description = rawData.description;
    this.virtual = rawData.virtual;
    this.x = rawData.x;
    this.y = rawData.y;
    this.z = rawData.z;
    if (rawData.next) this.next = rawData.next.map(device => new Device(device));
  }
}
