<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="stylus">
html, body.global
  font-size 12px
  font-family 'Lato'
</style>
