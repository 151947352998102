/**
 * Types exported by rest-api
 */

export interface ApiPaginationQuery {
  offset: number;
  limit: number;
}

export interface ApiPaginationData {
  total: number;
  offset: number;
  limit: number;
  next: string;
  prev: string;
}

export interface ApiPaginatedResponse<T> extends ApiPaginationData {
  elts: Array<T>;
}


export interface ApiAuthenticatedRequestHeaders {
  userUuid: string;
}

export interface ApiErrorReponse {
  error: true,
  errorCode: string;
  errorMsg: string;
}

export interface ApiSuccesResponse {
  success: true;
}

export type ApiStatusResponse = ApiSuccesResponse | ApiErrorReponse;

/**
 * Fields common to all values returned by the API.
 */
interface ApiCommon {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
}

// errors thrown by the API
export interface ApiErrorResponse {
  error: boolean;
  errorCode: string;
  errorMsg: string;
}

export interface CommonModelAttributes {
  readonly id: number;
}

// https://sequelize.org/v5/manual/models-definition.html#timestamps
export interface TimestampsAttributes {
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

// see https://sequelize.org/master/manual/paranoid.html
export interface ParanoidAttributes {
  readonly deletedAt: Date | null;
}

export interface ParanoidTimeStampsAttributes extends TimestampsAttributes, ParanoidAttributes {}



/**
 * All fields that can be included in a localized data.
 */
interface Localization {
  rootUuid?: string  | null;
  rootName?: string  | null;
  countryUuid?: string  | null;
  countryName?: string  | null;
  cityUuid?: string  | null;
  cityName?: string  | null;
  buildingUuid?: string  | null;
  buildingName?: string  | null;
  floorUuid?: string  | null;
  floorName?: string  | null;
  roomUuid?: string  | null;
  roomName?: string  | null;
  deviceUuid?: string  | null;
  deviceName?: string  | null;
  nodeUuid?: string  | null;
  nodeName?: string  | null;
  position?: {
    x: number;
    y: number;
  };
}

export interface DataProfile extends ApiCommon {
  name: string;
  measure: string;
  value: string;
  unit: string;
  type?: string;
}

export interface Alert extends ApiCommon, Localization {
  title: string; // comes from alertRule
  message: string;
  details: string;
  dateResolved: string;
  ticketId: number | null;
  localisation: string | null;
  urgence: 'low' | 'medium' | 'high';
  alertRuleId: number;
  description: string;
  profile: DataProfile;
  threshold: number;
}

export interface NodesGroup {
  id?: number,
  authorUuid: string;
  name: string;
  nodes: string[];
  dataProfileId: number;
  description?: string;
}

export interface Report extends ApiCommon {
  filePath: string;
  dateExecuted: Date;
  groupBy: string;
  startDateTime: string;
  endDateTime: string;
  // if we get report with option `include: models.reportConfig`, sequelize will add reportConfig field to result
  reportConfigId: number;
  reportConfig?: ReportConfig;
}

// TODO later
export type ReportRecipient = any;


export interface ReportConfig extends ApiCommon {
  authorUuid: string;
  title: string;
  description?: string;
  enabled: boolean;
  daily: boolean;
  weekly: boolean;
  monthly: boolean;
  yearly: boolean;
  reportRecipients?: Array<ReportRecipient | number>;
  nodesGroups?: Array<NodesGroup | number>;
  reports?: Report[];
}



// TODO we'll have to tidy the types and find a way to have a file (or a folder) common to rest-oasis and www-oasis to share the API payloads and types
// ApiDevice types are copy/pasted from rest-api's types/api.ts
export interface ApiBase {
  id: number;
}

export interface ApiDevice {
  name: string;
  type: string;
  deviceUuid: string;
  description: string | null;
  properties: Array<Omit<ApiDeviceProperty, 'deviceId'>>;
}

export enum ApiDevicePropertyConstraintsType {
  Number = 'number',
  Boolean = 'boolean',
  Action = 'action',
}

interface ApiDevicePropertyConstraintsTypeNumber {
  type: ApiDevicePropertyConstraintsType.Number;
  min?: number;
  max?: number;
}

interface ApiDevicePropertyConstraintsTypeBoolean {
  type: ApiDevicePropertyConstraintsType.Boolean;
}

interface ApiDevicePropertyConstraintsTypeAction {
  type: ApiDevicePropertyConstraintsType.Action;
  triggerValue: string;
  actionLabel: string | null;
}

export type ApiDevicePropertyConstraints =
  ApiDevicePropertyConstraintsTypeNumber |
  ApiDevicePropertyConstraintsTypeBoolean |
  ApiDevicePropertyConstraintsTypeAction ;

export interface ApiDataProfile extends ApiBase {
  name: string;
  measure: string;
  value: string;
  unit: string;
  type: string;
}

export interface ApiDeviceProperty {
  name: string;
  description: string | null;
  read: boolean;
  write: boolean;
  dataProfile: ApiDataProfile;
  constraints: ApiDevicePropertyConstraints;
  value: number | boolean | null;
}


// from rest-oasis validators/devices.ts in SetDeviceProperty body
export interface ApiDevicePropertyPatchPayload {
  value: any;
}
// create = no id
export type ReportConfigCreateData = Omit<ReportConfig, 'id' | 'authorUuid' | 'reports'>;

// id and authorUuid are set by the backend. reportRecipients and nodeGroups are arrays of ids instead of objects.
export type ReportConfigCreatePayload = Pick<ReportConfig, 'title' | 'description' | 'enabled' | 'daily' | 'weekly' | 'monthly' | 'yearly'> & {
    reportRecipientsUuids: string[];
    nodesGroupsIds: number[];
  };

export type ReportConfigUpdatePayload = Partial<ReportConfigCreatePayload>;

export type ReportConfigCreateResult = Omit<ReportConfig, 'reportRecipients' | 'nodesGroups' | 'reports'> & TimestampsAttributes;

export interface ApiNotification extends ApiBase {
  title: string;
  body: string | null;
  dateCreated: string;
  dateRead: string | null;
  authorUuid: string;
  actionLink: string | null;
}

export interface ApiPatchNotificationsPayload {
  read?: boolean;
}

export type ApiPatchNotificationsResponse = ApiSuccesResponse & { data: { updatedCount?: number } };

export interface ApiPatchNotificationByIdPayload {
  read?: boolean;
}
