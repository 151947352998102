const getDefaultState = () => ({
  visualisationMode: '2d',
  home: {
    fullscreen: false,
    sidebarIn: true,
    sidebarOut: 'small',
  },
  floors: {
    currentOrientationDegree: -45,
    minZoom: 1,
    displayMode: 'iso',
  },
  colorScales: {
    'good-bad': ['#8ED35A', '#F8C917', '#F14734'],
    greyToPrimary: ['#59C033', '#F8C917', '#F03B67'],
    binary: ['#59C033', '#F8C917', '#F03B67'],
    rainbow: ['#3C60B6', '#5FCBEE', '#59C033', '#F4Ec1D', '#EE3128'],
    heatmap: ['#3C60B6', '#5FCBEE', '#59C033', '#F4Ec1D', '#EE3128'],
    'heatmap-rainbow': ['#3C60B6', '#5FCBEE', '#59C033', '#F4Ec1D', '#EE3128'],
  },
  colorLabels: {
    'good-bad': ['GOOD', '', 'BAD'],
    binary: ['0', '1'],
  },
  colorPicker: [
    '#414B40',
    '#407B66',
    '#6DCB8A',
    '#847C5F',
    '#E2B565',
    '#E3D471',
    '#E3874C',
    '#F68161',
    '#E5554B',
    '#CC3A54',
    '#BD3B6A',
    '#9D377A',
    '#952769',
    '#63308B',
    '#442A7E',
    '#8051AE',
    '#E658AF',
    '#E73995',
    '#719AAD',
    '#5A93CB',
    '#87C47D',
  ],
  viewerMode: 'navigation',
  navTabsOpened: false,
  largeView: false,
});

const state = getDefaultState();

const mutations = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  SET_HOME_FULLSCREEN_STATE(state, fullscreenState) {
    state.home.fullscreen = fullscreenState;
  },
  SET_VISUALISATION_MODE(state, mode) {
    if (!['graph', '2d', '3d'].includes(mode)) return;
    state.visualisationMode = mode;
  },
  SET_SIDEBARIN_STATE(state, sidebarState) {
    state.home.sidebarIn = sidebarState;
  },
  SET_SIDEBAROUT_STATE(state, size) {
    state.home.sidebarOut = size;
  },
  ['SET_CURRENT_FLOORS_ORIENTATION_DEGREE'](state, degree) {
    state.floors.currentOrientationDegree = degree;
  },
  ['SET_FLOORS_MIN_ZOOM'](state, zoom) {
    state.floors.minZoom = zoom;
  },
  ['SET_DISPLAY_MODE'](state, mode) {
    if (!['iso', 'flat'].includes(mode)) return;
    state.floors.displayMode = mode;
  },
  ['SET_VIEWER_MODE'](state, mode) {
    if (!['navigation', 'simple', 'deviceEdition', 'zoneEdition', 'propertiesEdition', 'tickets'].includes(mode)) return;
    state.viewerMode = mode;
  },
  ['SET_NAV_TABS_OPENED'](state, opened) {
    state.navTabsOpened = opened;
  },
  ['SET_LARGE_VIEW'](state, large) {
    state.largeView = large;
    // to remove
  },
};

const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  toggleDisplayMode: ({ commit, state }) => {
    if (state.floors.displayMode === 'iso') commit('SET_DISPLAY_MODE', 'flat');
    else commit('SET_DISPLAY_MODE', 'iso');
  },
  enableFlatDisplayMode: ({ commit, state }) => {
    commit('SET_DISPLAY_MODE', 'flat');
  },
  toggleNavTabs: ({ commit, state }) => {
    commit('SET_NAV_TABS_OPENED', !state.navTabsOpened);
  },
  toggleHomeFullscreen: ({ commit, state }) => {
    commit('SET_SIDEBARIN_STATE', !state.home.sidebarIn);
    commit('SET_HOME_FULLSCREEN_STATE', !state.home.fullscreen);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
