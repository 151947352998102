// Endpoints
import user from './endpoints/user';
import country from './endpoints/country';
import oapp from './endpoints/oapp';
import oql from './endpoints/oql';
import tickets from './endpoints/tickets';
import dashboard from './endpoints/dashboard';
import topology from './endpoints/topology';
import profiles from './endpoints/profiles.js';
import sums from './endpoints/sums';
import devices from './endpoints/devices';
import node from './endpoints/node';
import building from './endpoints/building';
import userNodes from './endpoints/userNodes';
import zones from './endpoints/zones';
import widget from './endpoints/widget';
import admin from './endpoints/admin';
import notification from './endpoints/notification';
import alert from './endpoints/alert';
import alertRule from './endpoints/alertRule';
import qpark from './endpoints/qpark';
import nodesGroup from './endpoints/nodesGroup';
import report from './endpoints/report';
import oasisDevices from './endpoints/oasisDevices';
import reportConfig from './endpoints/reportConfig';

export default {
  user,
  country,
  oapp,
  oql,
  tickets,
  dashboard,
  topology,
  profiles,
  sums,
  devices,
  building,
  node,
  userNodes,
  zones,
  widget,
  admin,
  notification,
  alert,
  alertRule,
  qpark,
  nodesGroup,
  report,
  oasisDevices,
  reportConfig,
};
