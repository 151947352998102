
















































import Vue from 'vue';

import api from '@/services/api';

import { errorMessageFromApiError } from '@/services/api/utils';
import { ApiPaginatedResponse } from '@/types/api';
import { ApiDevice } from '@/types/api';
import { HttpMetadata, HttpMetadataDefaults } from '@/types/http';
import FetchableData from '@/app/components/ui/FetchableData.vue';

export default Vue.extend({
  name: 'DevicesList',
  components: {
    FetchableData
  },
  data: () => ({
    devices: {
      data: null as ApiPaginatedResponse<ApiDevice> | null,
      meta: { ...HttpMetadataDefaults } as HttpMetadata,
    },
    columns: [
      {
        name: 'name',
        required: true,
        label: 'Nom',
        align: 'left',
        field: 'name',
        sortable: true
      },
      {
        name: 'type',
        required: true,
        label: 'Type',
        align: 'left',
        field: 'type',
        sortable: true
      },
      {
        name: 'description',
        required: true,
        label: 'Description',
        align: 'left',
        field: 'description',
        sortable: false
      },
      {
        name: 'properties',
        required: true,
        label: 'Propriétés',
        align: 'left',
        field: 'properties',
        sortable: false
      },
    ],
  }),
  computed: {
    tableData(): { deviceUuid: string, name: string, description: string, properties: string }[] {
      return this.devices?.data?.elts?.map(d => ({
        deviceUuid: d.deviceUuid,
        name: d.name,
        type: d.type,
        description: d.description || '',
        properties: d.properties.map(p => p.name).join(', '),
      })) || [];
    }
  },
  async created() {
    await this.fetchDevices();
  },
  methods: {
    async fetchDevices() {
      if (this.devices.meta.isLoading) {
        return;
      }
      this.devices.meta.isLoading = true;
      this.devices.meta.error = null;

      try {
        this.devices.data = (await api.oasisDevices.getList()).data;
        this.devices.meta.lastFetch = new Date();
      } catch (err) {
        this.devices.meta.error = errorMessageFromApiError(err as Error);
      }

      this.devices.meta.isLoading = false;
    },
    navigateToDevice(deviceUuid: string) {
      this.$router.push({
        name: 'devicesDevice',
        params: {
          deviceUuid
        }
      });
    }
  }
});
