import _ from 'lodash';
import graph from './graph-locale.json';
const graphFR = _.cloneDeep(graph);

// navbar
graphFR.navBar.back = 'retour';
graphFR.navBar.space = 'Spatial';
graphFR.navBar.energy = 'Énergies';
// tool
graphFR.tools.graphSize = 'taille';
graphFR.tools.drag = 'glisser';
graphFR.tools.center = 'centrer';
// sidebar node
graphFR.sidebarNode.emptyDescription = `aucun description`;

graphFR.sidebarNode.nodeTypes.root = `racine`;
graphFR.sidebarNode.nodeTypes.country = `pays`;
graphFR.sidebarNode.nodeTypes.city = `ville`;
graphFR.sidebarNode.nodeTypes.building = `bâtiment`;
graphFR.sidebarNode.nodeTypes.floor = `étage`;
graphFR.sidebarNode.nodeTypes.premise = `lot`;
graphFR.sidebarNode.nodeTypes.room = `pièce`;
graphFR.sidebarNode.nodeTypes.plug = `prise électrique`;
graphFR.sidebarNode.nodeTypes.device = `appareil`;
graphFR.sidebarNode.nodeTypes.air = `air`;
graphFR.sidebarNode.nodeTypes.alarm = `alarmes`;
graphFR.sidebarNode.nodeTypes.water = `eau`;
graphFR.sidebarNode.nodeTypes.water_hot = `eau chaude`;
graphFR.sidebarNode.nodeTypes.water_cold = `eau froide`;
graphFR.sidebarNode.nodeTypes.electricity = `électricité`;
graphFR.sidebarNode.nodeTypes.electricity_aux = `auxiliaire`;
graphFR.sidebarNode.nodeTypes.electricity_heating = `chauffage`;
graphFR.sidebarNode.nodeTypes.electricity_ecs = `ECS`;
graphFR.sidebarNode.nodeTypes.electricity_light = `éclairage`;
graphFR.sidebarNode.nodeTypes.electricity_process = `process`;
graphFR.sidebarNode.nodeTypes.electricity_other = `autre`;
graphFR.sidebarNode.nodeTypes.states = `états`;
graphFR.sidebarNode.nodeTypes.qrad = `qrad`;

graphFR.sidebarNode.nodeActions.addNode = `ajouter un nœud`;
graphFR.sidebarNode.nodeActions.editNode = `modifier un nœud`;
graphFR.sidebarNode.nodeActions.deleteNode = `supprimer un nœud`;
graphFR.sidebarNode.nodeActions.addDevice = `ajouter un appareil`;
graphFR.sidebarNode.nodeActions.deleteDevice = `supprimer un appareil`;
// sidebar data
graphFR.sidebarData.profile = `profil`;
graphFR.sidebarData.fetching = 'récupération des données';
graphFR.sidebarData.noData = 'aucune donnée pour ce profil';

graphFR.sidebarData.profiles['motion left'] = 'mouvement gauche';
graphFR.sidebarData.profiles['motion right'] = `mouvement droite`;
graphFR.sidebarData.profiles['motion center'] = `mouvement centre`;
graphFR.sidebarData.profiles['temperature target'] = `température cible`;
graphFR.sidebarData.profiles['temperature heatsink'] = `température dissipateur`;
graphFR.sidebarData.profiles['temperature air'] = `température`;
graphFR.sidebarData.profiles.sound = `son`;
graphFR.sidebarData.profiles.humidity = `humidité`;
graphFR.sidebarData.profiles.co2 = `CO2`;
graphFR.sidebarData.profiles.cov = `COV`;
graphFR.sidebarData.profiles.consumed = ``;
graphFR.sidebarData.profiles['power all'] = `energy all`;
graphFR.sidebarData.profiles['power low'] = `power low`;
graphFR.sidebarData.profiles['power current all'] = `power current all`;
graphFR.sidebarData.profiles['power current low'] = `power current low`;
graphFR.sidebarData.profiles['power factor all'] = `power factor all`;
graphFR.sidebarData.profiles['power factor low'] = `power factor low`;
graphFR.sidebarData.profiles['power frequency all'] = `power frequency all`;
graphFR.sidebarData.profiles['power voltage all'] = `power voltage all`;
graphFR.sidebarData.profiles['consumed energy all'] = `consommation énergétique totale`;
graphFR.sidebarData.profiles['consumed energy low'] = `consommation énergétique carte mères`;
graphFR.sidebarData.profiles['power target'] = `power target`;
graphFR.sidebarData.profiles['sauter alarms'] = `alarme sauter`;
graphFR.sidebarData.profiles['sauter state'] = `état sauter`;
graphFR.sidebarData.profiles['sauter counter elec'] = `compteur électrique sauter`;
graphFR.sidebarData.profiles['sauter counter water'] = `compteur à eau sauter`;
graphFR.sidebarData.profiles['hager covl'] = `COVL`;
graphFR.sidebarData.profiles['hager covg'] = `COVG`;
graphFR.sidebarData.profiles['hager aerosol'] = `aérosols`;
graphFR.sidebarData.profiles['flow to inside'] = `débit soufflage intérieur`;
graphFR.sidebarData.profiles['flow to outside'] = `débit soufflage extérieur`;
graphFR.sidebarData.profiles['flow target'] = `consigne de débit`;
graphFR.sidebarData.profiles['temperature to inside'] = `température soufflage intérieur`;
graphFR.sidebarData.profiles['temperature to outside'] = `température soufflage extérieur`;
graphFR.sidebarData.profiles['temperature from inside'] = `température de reprise`;
graphFR.sidebarData.profiles['temperature from outside'] = `température extérieur`;
graphFR.sidebarData.profiles['temperature target'] = `consigne de température`;

graphFR.sidebarSum.edit = 'Editer';
graphFR.sidebarSum.create = 'Créer';
graphFR.sidebarSum.delete = 'Supprimer';
graphFR.sidebarSum.createSum = 'Créer une somme';
graphFR.sidebarSum.editSum = 'Editer cette somme';
graphFR.sidebarSum.nameLabel = 'Nom';
graphFR.sidebarSum.nameHint = 'Choisissez un nom pour identifier cette somme';
graphFR.sidebarSum.labelProfile = 'Quel profile voulez-vous utiliser ?';
graphFR.sidebarSum.deviceLabel = 'Appareil';
graphFR.sidebarSum.deviceHint = 'Choisissez de quelle façon les appareils sont utilisés';
graphFR.sidebarSum.add = 'Additioner';
graphFR.sidebarSum.subtract = 'Soustraire';
graphFR.sidebarSum.donotuse = 'Ne pas utiliser';
graphFR.sidebarSum.value = 'Valeur';
graphFR.sidebarSum.deleteSum.dialogTitle = 'Supprimer cette somme ?';
graphFR.sidebarSum.deleteSum.success = 'Suppression réussie';
graphFR.sidebarSum.deleteSum.error = 'Échec de la suppression';

export default graphFR;
