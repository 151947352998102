













import Vue from 'vue';

import { ReportConfig } from '@/types/api';
import CreateEditReportConfig from './components/CreateEditReportConfig.vue';

export default Vue.extend({
  name: 'ReportConfigNew',
  components: {
    CreateEditReportConfig,
  },
  props: {
    // no props
  },
  methods: {
    onSaved(newReportConfig: ReportConfig) {
      this.$router.push({
        name: 'reportConfigView',
        params: {
          reportConfigId: `${newReportConfig.id}`,
        }
      });
    },
    onError() {
      // TODO error toast ?
    },
  }
});
