



































import Vue, { PropType } from 'vue';

import { ApiDevice } from '@/types/api';
import DevicePropertyEditor from './DevicePropertyEditor.vue';

export default Vue.extend({
  name: 'Device',
  components: {
    DevicePropertyEditor,
  },
  props: {
    device: {
      type: Object as PropType<ApiDevice>,
      required: true,
    }
  }
});
