<!--eslint-disable vue/attribute-hyphenation -->
<template>
  <q-page class="notifications">
    <div class="title">
      Centre d'alertes
    </div>
    <div class="content-container">

      <q-table
        class="table"
        title="Centre d'alertes"
        :data="tableData"
        :columns="columns"
        separator="horizontal"
        row-key="name"
        no-data-label="Tout va bien, vous n'avez aucune alerte"
        no-results-label="Aucune alerte ne correspond à ce filtre"
        loading-label="Récupération des alertes en cours..."
        :visible-columns="visibleColumns"
        :loading="loading"
        :pagination.sync="pagination"
        :filter="filter"
        @row-click="onRowClick"
      >
        <template slot="top-right">
          <q-search
            v-model="filter"
            hide-underline
            color="tertiary"
            class="table-control-right"
          />

        </template>
        <template slot="top-left">

          <div class="list-filters table-control-left">
            <button :class="filterIsSelected('all')"
                    class="filter-item t_filter-item--all"
                    @click="levelFilter = 'all'">{{$t('pages.alert.priorities.all')}}</button>
            <button :class="filterIsSelected('high')"
                    class="filter-item t_filter-item--high"
                    @click="levelFilter = 'high'">{{$t('pages.alert.priorities.high')}}</button>
            <button :class="filterIsSelected('normal')"
                    class="filter-item t_filter-item--normal"
                    @click="levelFilter = 'normal'">{{$t('pages.alert.priorities.normal')}}</button>
            <button :class="filterIsSelected('low')"
                    class="filter-item t_filter-item--low"
                    @click="levelFilter = 'low'">{{$t('pages.alert.priorities.low')}}</button>
          </div>

          <q-toggle v-model="includeHistory"
                    class="table-control-left"
                    label="Historique"
                    color="blue-grey-3"
                    keep-color
                    left-label />
        </template>

        <!-- slot name syntax: body-cell-<column_name> -->
        <!-- message -->
        <q-td slot="body-cell-message"
              slot-scope="props"
              class="pointer"
              :class="props.row.dateResolved ? 'resolved': ''"
              :props="props"
              @click.native="navigate(props.row.alertId)">
          <div>
            <strong>{{props.value.deviceUuid}} <i class="fas fa-link"></i></strong>
            <q-tooltip anchor="bottom middle"
                      class="tooltip"
                      self="top middle"
                      :offset="[10, 10]"
                      max-width="100px">
              {{props.value.message}}
            </q-tooltip>
          </div>
        </q-td>
        <!-- topology -->
        <q-td slot="body-cell-topology"
              slot-scope="props"
              :props="props">
          <div>
            <q-breadcrumbs separator=">"
                          active-color="secondary"
                          color="secondary">
              <q-breadcrumbs-el :label="props.value.building" />
              <q-breadcrumbs-el :label="props.value.floor" />
              <q-breadcrumbs-el :label="props.value.room" />
            </q-breadcrumbs>
          </div>
        </q-td>
        <!-- emergency -->
        <q-td slot="body-cell-emergency"
              slot-scope="props"
              :props="props">
          <q-item v-if="props.value === 0"
                  class="emergency emergency-low fas fa-chevron-down notification-icon"
                  color="green"
                  title="Basse"></q-item>
          <q-item v-if="props.value === 1"
                  class="emergency emergency-normal fas fa-chevron-up notification-icon"
                  color="orange"
                  title="Moyenne"></q-item>
          <q-item v-if="props.value === 2"
                  class="emergency emergency-high fas fa-chevron-up notification-icon"
                  color="red"
                  title="Haute"></q-item>

        </q-td>
      </q-table>
    </div>
  </q-page>
</template>

<script>

import moment from 'moment';

export default {
  name: 'Alerts',
  data() {
    return {
      emergencies: {'low': 0, 'normal': 1, 'high': 2},
      includeHistory: false,
      pagination: {
        sortBy: 'datetime',
        descending: true,
        page: 1,
        rowsPerPage: 10
      },
      filter: '',
      levelFilter: 'all',
      selectedType: 'all',
      selectedTime: 'all',
      columns: [
        {
          name: 'message',
          required: true,
          label: 'Équipement',
          align: 'left',
          field: 'message',
          sortable: true,
        },
        {
          name: 'dataProfile',
          required: true,
          label: 'Profile de donnée',
          align: 'left',
          field: 'dataProfile',
          sortable: true,
        },

        {
          name: 'topology',
          required: true,
          label: 'Emplacement',
          align: 'left',
          field: 'topology',
          sortable: true,
        },
        {
          name: 'emergency',
          required: true,
          label: 'Urgence',
          align: 'left',
          field: 'emergency',
          sortable: true,
        },
        {
          name:'dateCreated',
          label:'Active depuis',
          field: 'dateCreated',
          align: 'left',
          required: true,
          sortable: true,
          format: val => this.formatTime(val),
        },
        {
          name:'dateResolved',
          label:'Résolue depuis',
          field: 'dateResolved',
          align: 'left',
          required: false,
          sortable: true,
          format: val => this.formatTime(val),
        },
      ],
    };
  },
  computed: {
    visibleColumns() {
      if (this.includeHistory)
        return this.columns.map(c => c.name);
      return this.columns.map(c => c.name).filter(c => c !== 'dateResolved');
    },
    loading() {
      return this.$store.state.alert.meta.isLoading;
    },
    tableData() {
      let filteredAlerts = [...this.$store.state.alert.collection];
      if (!this.includeHistory) {
        filteredAlerts = filteredAlerts.filter(alert => alert.dateResolved === null);
      }
      if (['high', 'normal', 'low'].includes(this.levelFilter))
        filteredAlerts = filteredAlerts.filter(alert => alert.urgence === this.levelFilter);
      return filteredAlerts.map(alert => {
        return {
          message: { deviceUuid: alert.deviceUuid, message: alert.message },
          deviceUuid: alert.deviceUuid,
          topology: {
            building: alert.buildingName,
            floor: alert.floorName,
            room: alert.roomName
          },
          emergency: this.emergencies[alert.urgence],
          dateCreated: alert.dateCreated,
          dateResolved: alert.dateResolved,
          dataProfile: `${alert.profile.name} (${alert.profile.unit})`,
          alertId: alert.id,
        };
      });
    },
  },
  methods: {
    formatTime(date) {
      if (!date) return null;
      const now = moment().locale(this.$i18n.locale);
      const from = moment(date);

      const differenceInSeconds = now.diff(from, 'seconds');
      if (differenceInSeconds < 60 * 60 * 24)
        return from.fromNow();
      if (differenceInSeconds < 60 * 60 * 24 * 7)
        return `${from.fromNow()} (${from.format('DD-MM-YYYY - HH:mm')})`;
      return `${from.fromNow()} (${from.format('DD-MM-YYYY')})`;
    },
    filterIsSelected(state) {
      return {
        isSelected: state === this.levelFilter,
      };
    },
    navigate(alertId) {
      // go to alert details page
      this.$router.push({
        name: 'alertDetails',
        params: { alertId },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.alert-icon
  font-size 16px
.pointer
  cursor pointer


.list-filters
  .filter-item
    text-transform uppercase
  button
    outline none
    border none
    background none
    letter-spacing 0.5px
    font-weight 400
    font-size $fs-body
    cursor pointer
    &:hover
      text-decoration underline
  .isSelected
    text-decoration underline
    font-weight 900

.emergency
  font-size 18px
.emergency-low
  color green
.emergency-normal
  color orange
.emergency-high
  color red

.title
  font-weight 400
  margin $space-2
  width 100%
  font-size $fs-h1

.table
  height fit-content
  background-color $dash-light
  border-radius 3px
  box-shadow none

.table-control-left
  margin-right 20px
.table-control-right
  margin-left 20px
.resolved
  background-color #dfdfdf
</style>
