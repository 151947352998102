import securedAxios from '../securedAxios';

const AlertRule = {
  endpoint: '/api/alert-rules',
  fetch() {
    return securedAxios.get(`${this.endpoint}`);
  },
  getAlertRule(alertRuleId) {
    return securedAxios.get(`${this.endpoint}/${alertRuleId}`);
  },
  listAlertsOfAlertRule(alertRuleId) {
    return securedAxios.get(`${this.endpoint}/${alertRuleId}/alerts`);
  },
  listReceiversOfAlertRule(alertRuleId) {
    return securedAxios.get(`${this.endpoint}/${alertRuleId}/receivers`);
  },
  save(alertRule) {
    // if update alertRule existing
    if (alertRule.id) {
      return securedAxios.patch(`${this.endpoint}/${alertRule.id}`, alertRule);
    }
    // create new alertRule
    return securedAxios.post(`${this.endpoint}`, alertRule);
  },
};

export default AlertRule;