<template>
  <div :class="drawerClass"
       class="drawer">
    <slot />
    <span :class="arrowClass"
          :style="arrowStyle"
          class="drawer__arrow" />
  </div>
</template>

<script>
export default {
  name: 'Drawer',
  props: {
    side: {
      type: String,
      default: 'left',
      validator: value => ['left', 'right'].includes(value),
    },
    posY: {
      type: Number,
      default: 0,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    arrowClass() {
      return {
        'drawer__arrow--left': this.side === 'left',
        'drawer__arrow--right': this.side === 'right',
        'drawer__arrow--right-white': this.inverted && this.side === 'right',
        'drawer__arrow--left-white': this.inverted && this.side === 'left',
      };
    },
    arrowStyle() {
      return {
        top: this.posY,
      };
    },
    drawerClass() {
      return {
        'drawer--white': this.inverted,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.drawer
  background-color black
  box-shadow $shadow-elevation-3
  color white
  &--white
    background-color white
    color black
  .drawer__arrow
    position absolute
    width 0px
    height 0px
    &--left
      left -15px
      border-width 15px 15px 15px 0
      border-style solid
      border-color transparent black transparent transparent
    &--left-white
      border-color transparent white transparent transparent
    &--right
      right -10px
      border-width 15px 0 15px 15px
      border-style solid
      border-color transparent transparent transparent black
    &--right-white
      border-color transparent transparent transparent white
</style>
