import securedAxios from '../securedAxios';

const country = {
  endpoint: '/api/space',
  fetch() {
    return securedAxios.get(`${this.endpoint}/countries`);
  },
};

export default country;
