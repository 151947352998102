<template>
  <Field :label="label"
         :hint="hint"
         :errorMessage="errorMessage"
         :required="required"
         class="text-field">
    <div class="input-container">
    <input ref="nameValue"
           v-model="value"
           class="o-input"
           :class="oInputClass"
           :type="type"
           :placeholder="placeholder"
           :autocomplete="autocomplete"/>
    <span class="input-container__suffix">{{suffix}}</span>
    </div>
  </Field>
</template>

<script>
import Field from '@/app/components/inputs/field.vue';

export default {
  name: 'TextField',
  components: {
    Field,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'password', 'number'].includes(value);
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    oInputClass() {
      return {
        'o-input--negative': this.errorMessage,
      };
    },
  },
  watch: {
    value(value) {
      if (this.type === 'number') this.$emit('input', parseInt(value));
      else this.$emit('input', value);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.text-field
  display flex
  flex-direction column
  .o-input
    width 100%
  .input-container
    display flex
    &__suffix
      margin-left 10px
      font-weight bold
      align-self center
</style>