import securedAxios from '../securedAxios';

const AlertRule = {
  endpoint: '/api/qpark',
  listUsersAboveNodes(nodes) {
    const queryParams = nodes.map(uuid => `nodes[]=${uuid}`).join('&');
    return securedAxios.get(`${this.endpoint}/users/above-nodes?${queryParams}`);
  },
};

export default AlertRule;