<template>
  <svg :style="svgStyle"
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 65.43 37.16">
    <g>
      <g>
        <polygon :style="polygonStyle"
                 points="32.72 28.69 4.03 0 0 4.44 32.72 37.16 65.43 4.46 61.42 0 32.72 28.69" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ChevronPicto',
  props: {
    size: {
      type: String,
      default: '100%',
    },
    color: {
      type: String,
      default: 'black',
    },
  },
  computed: {
    svgStyle() {
      return {
        width: this.size,
        height: this.size,
      };
    },
    polygonStyle() {
      return {
        fill: this.color,
      };
    },
  },
};
</script>

<style lang='stylus' scoped>
</style>
