import axios from 'axios';
import securedAxios from '../securedAxios';

const endpoint =  '/api/oapps';
const Oapp = {
  sourceCancel: null,
  fetch() {
    return securedAxios.get(`${endpoint}`);
  },
  fetchInstalled() {
    return securedAxios.get(`${endpoint}/installed`);
  },
  install(oappId) {
    return securedAxios.post(`${endpoint}/install/${oappId}`);
  },
  uninstall(oappId) {
    return securedAxios.delete(`${endpoint}/install/${oappId}`);
  },
  conf: {
    read(oappId, oappType) {
      if (this.sourceCancel) this.sourceCancel.cancel();
      this.sourceCancel = axios.CancelToken.source();
      return securedAxios.get(`${endpoint}/${oappId}/${oappType}`);
    },
  },
  data: {
    read(oappId, oappType, options) {
      if (this.sourceCancel) this.sourceCancel.cancel();
      this.sourceCancel = axios.CancelToken.source();
      return securedAxios.post(`${endpoint}/${oappId}/${oappType}`, options, {
        cancelToken: this.sourceCancel.token,
      });
    },
    update(oappId, oappType, options) {
      if (this.sourceCancel) this.sourceCancel.cancel();
      this.sourceCancel = axios.CancelToken.source();
      return securedAxios.put(`${endpoint}/${oappId}/${oappType}`, options, {
        cancelToken: this.sourceCancel.token,
      });
    },
  },
  last: {
    read(oappId, oappType, options) {
      if (this.sourceCancel) this.sourceCancel.cancel();
      this.sourceCancel = axios.CancelToken.source();
      return securedAxios.post(`${endpoint}/${oappId}/${oappType}/last`, options, {
        cancelToken: this.sourceCancel.token,
      });
    }
  },
};

export default Oapp;
