import Vue from 'vue';
import api from '@/services/api';
import { errorMessageFromApiError } from '@/services/api/utils';

import { Alert } from '@/types/api';
import { HttpMetadata } from '@/types/http';
import { Module } from 'vuex/types';
import { RootStoreState } from '..';

// TODO should probably be an extension of CollectionStoreState<Alerts> or something like that
export interface AlertsStoreState {
  collection: Alert[],
  meta: HttpMetadata,
}

const getDefaultState = (): AlertsStoreState => ({
  collection: [] as any[],
  meta: {
    lastFetch: null,
    isLoading: false,
    error: null,
  } as HttpMetadata,
});

const module: Module<AlertsStoreState, RootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState: (state) => Object.assign(state, getDefaultState()),
    set: (state, { collection, meta }: Partial<AlertsStoreState>) => {
      if (collection !== undefined) {
        Vue.set(state, 'collection', collection);
      }

      if (meta !== undefined) {
        Vue.set(state, 'meta', { ...state.meta, ...meta });
      }
    },
    createTicket(state, alert: Alert) {
      const alertIndex = state.collection.findIndex(a => a.id === alert.id);
      if (alertIndex < 0) return;
      Vue.set(state.collection, alertIndex, alert);
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async fetch({ commit, state }) {
      // avoid fetching if we're already doing it
      if (state.meta.isLoading) {
        return;
      }

      // avoid re-loading if data is fresh (loaded less than 5 secs ago)
      if (
        state.meta.lastFetch !== null &&
        (new Date().getTime() - state.meta.lastFetch.getTime() < 5000) &&
        state.meta.error === null
      ) {
        return;
      }

      commit('set', {
        meta: {
          isLoading: true,
          error: null,
        }
      });

      try {
        const fetchResult = await api.alert.fetch();
        commit('set', {
          collection: fetchResult.data,
          meta: {
            lastFetch: new Date(),
            isLoading: false,
            error: null,
          }
        });
      } catch (err) {
        commit('set', {
          meta: {
            isLoading: false,
            error: errorMessageFromApiError(err as Error),
          }
        });
      }
    },
    async createTicket({commit}, { ticket: newTicket, files, id }) {
      const { updatedAlert: alert, ticket } = (await api.alert.createTicket(id, newTicket)).data;
      commit('createTicket', alert);
      if (files) {
        await api.tickets.public.file.create(ticket.shortId, files);
      }
      return ticket;
    },
  },
};

export default module;
