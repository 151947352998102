import _ from 'lodash';
import globalBase from './global-locale-object.json';
const globalFR = _.cloneDeep(globalBase);

globalFR.load = 'Charger';
globalFR.loading = 'Chargement...';
globalFR.retry = 'Essayer à nouveau';
globalFR.noElts = 'Aucun élément';
globalFR.reload = 'Recharger';

export default globalFR;
