






































import Vue, { PropType } from 'vue';
import { HttpMetadata, HttpMetadataDefaults } from '@/types/http';
import OasisLoader from '@/app/components/ui/oasis-loader.vue';

export default Vue.extend({
  name: 'FetchableData',
  components: {
    OasisLoader,
  },
  props: {
    // the metadata
    meta: {
      type: Object as PropType<HttpMetadata>,
      required: true,
    },
  }
});
