import Node from 'models/topology/node';
import City from 'models/topology/city';

export default class Country extends Node {
  alpha2;
  metadata = {};
  constructor(rawData = {}, parent = null, options = null) {
    super(rawData, parent);
    this.alpha2 = rawData.alpha2;
    this.metadata = rawData.metadata;
    if (rawData.next) this.next = rawData.next.map(city => new City(city, this, options));
  }
}
