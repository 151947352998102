import Vue from 'vue';
import api from 'api';
import _ from 'lodash';
import { normalize, schema } from 'normalizr';

// definition of each entity for the normalization
const schemaSpace = new schema.Entity('spaces', {}, { idAttribute: 'uuid' });
const schemaFloor = new schema.Entity('floors', { next: [schemaSpace] }, { idAttribute: 'uuid' });
const schemaBuilding = new schema.Entity('buildings', { next: [schemaFloor] }, { idAttribute: 'uuid' });
const schemaCollection = new schema.Array(schemaBuilding);

import Building from 'models/topology/building';

const getDefaultState = () => ({
  collection: [],
  buildings: {},
  floors: {},
  spaces: {},
  fetching: false,
  selectedBuilding: null,
  selectedFloor: 0,
  selectedSpace: null,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    collection: state => state.collection,
    selectedBuilding: state => state.selectedBuilding,
    selectedSpace: (state, getters) => {
      if (!state.selectedSpace) return null;
      return getters.selectedBuilding.next.reduce((acc, f) => {
        const currentSpace = f.next.find(s => s.idIFC === state.selectedSpace.id);
        if (currentSpace) return currentSpace;
        else return acc;
      }, null);
    },
    read: state => uuid => state.collection.find(b => b.uuid === uuid),
    currentWhitelistedSpaces: state => {
      const currentFloors = _.get(state.selectedBuilding, 'next', []);
      const spacesToWhiteList = [];
      currentFloors.forEach(floor => {
        spacesToWhiteList.push(...floor.whitelist);
      });
      return spacesToWhiteList;
    },
  },
  mutations: {
    resetState: state => Object.assign(state, getDefaultState()),
    setCollection: (state, buildings) => Vue.set(state, 'collection', buildings),
    setBuildings: (state, buildings) => Vue.set(state, 'buildings', buildings),
    setFloors: (state, floors) => Vue.set(state, 'floors', floors),
    setSpaces: (state, spaces) => Vue.set(state, 'spaces', spaces),
    setFetchState: (state, fetchState) => (state.fetching = fetchState),
    setSelectedBuilding: (state, building) => Vue.set(state, 'selectedBuilding', building),
    setSelectedFloor: (state, floor) => {
      if (floor >= state.selectedBuilding.next.length || floor < 0) return;
      Vue.set(state, 'selectedFloor', floor);
    },
    setSelectedSpace: (state, space) => Vue.set(state, 'selectedSpace', space),
    update: (state, building) => {
      const index = state.collection.findIndex(b => b.uuid === building.uuid);
      if (index === -1) return;
      Vue.set(state.collection, index, building);
    },
    updateSpaceProperties: (state, space) => {
      state.collection.some(b => {
        return b.next.some(f => {
          return f.next.some(s => {
            if (s.uuid === space.uuid) {
              Vue.set(s, 'properties', space.properties);
              return true;
            }
          });
        });
      });
    },
    delete: (state, uuid) => {
      const buildingIdx = state.collection.findIndex(building => building.uuid === uuid);
      if (buildingIdx > -1) state.collection.splice(buildingIdx, 1);
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    fetch: async ({ commit, dispatch }) => {
      commit('setFetchState', true);
      const { data: buildings } = await api.building.fetch();
      const newCollection = buildings.map(b => new Building(b));
      const { entities: normalizedCollection } = normalize(buildings, schemaCollection);
      commit('setCollection', newCollection);
      commit('setBuildings', normalizedCollection.buildings);
      commit('setFloors', normalizedCollection.floors);
      commit('setSpaces', normalizedCollection.spaces);
      commit('setFetchState', false);
    },
    // create: ({commit}, buildingData) => {

    // },
    // read: ({commit}, uuid) => {

    // },
    // update: ({commit}, buildingData) => {

    // },
    updatePreferences: async ({ commit, state }, { uuid, preferences }) => {
      const { data: updatedBuilding } = await api.building.preferences.update(uuid, preferences);
      const building = state.collection.find(building => building.uuid === updatedBuilding.uuid);
      if (building) building.metadata.preferences = updatedBuilding.metadata.preferences;
    },
    delete: async ({ commit, state }, uuid) => {
      try {
        const building = state.collection.find(building => building.uuid === uuid);
        if (!building) return;
        const deviceUuidList = building.devices.map(device => device.uuid);
        await api.devices.removeAllDevices(deviceUuidList);
        await api.building.delete(uuid);
        commit('delete', uuid);
      } catch (error) {
        throw error;
      }
    },
  },
};
