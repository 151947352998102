import securedAxios from '../securedAxios';
const admin = {
  users: {
    fetch() {
      return securedAxios.get(`/api/admin/users`);
    },
  },
  user: {
    endpoint: '/api/admin',
    userEndpoint: '/api/admin/user',
    getFeatures(userUuid) {
      return securedAxios.get(`${this.userEndpoint}/${userUuid}/features`);
    },
    getNodes(userUuid) {
      return securedAxios.get(`${this.userEndpoint}/${userUuid}/nodes`);
    },
    getOapps(userUuid) {
      return securedAxios.get(`${this.userEndpoint}/${userUuid}/oapps`);
    },
    deleteOapp(userUuid, oappId) {
      return securedAxios.delete(`${this.userEndpoint}/${userUuid}/oapp/${oappId}`);
    },
    addOapp(data) {
      return securedAxios.post(`${this.userEndpoint}/oapp/`, data);
    },
    getDashboards(userUuid) {
      return securedAxios.get(`${this.userEndpoint}/${userUuid}/dashboards`);
    },
    copyDashboard(userUuid, dashboardId) {
      return securedAxios.put(`${this.endpoint}/copy-dashboard`, { dashboardId: dashboardId, userUuid: userUuid });
    },
    shareDashboard(userUuid, dashboardId) {
      return securedAxios.put(`${this.endpoint}/share-dashboard`, { dashboardId: dashboardId, userUuid: userUuid });
    },
  },
  features: {
    featuresEndpoint: '/api/features',
    update(data) {
      return securedAxios.post(`${this.featuresEndpoint}/bulk`, data);
    },
    deleteFeature({ featureId }) {
      return securedAxios.delete(`${this.featuresEndpoint}/${featureId}`);
    },
    createFeature(data) {
      return securedAxios.post(`${this.featuresEndpoint}`, data);
    },
  },
};

export default admin;
