import _ from 'lodash';
import widgets from './widgets-locale.json';
const widgetsFR = _.cloneDeep(widgets);

// Base
widgetsFR.base.fetching = 'récupération';
widgetsFR.base.edit = 'éditer';
widgetsFR.base.delete = 'supprimer';
widgetsFR.base.to = 'au';
// PresenceRate
widgetsFR.presenceRate.occupied = 'occupée';
widgetsFR.presenceRate.free = 'libre';
widgetsFR.presenceRate.realTime = 'temps réel';
widgetsFR.presenceRate.occupationRate = `taux d'occupation`;
// HagerQai
widgetsFR.hagerQai.quality = `qualité`;
widgetsFR.hagerQai.threshold = `seuil`;

export default widgetsFR;
