import _ from 'lodash';
import login from './login-locale-object.json';
const loginFR = _.cloneDeep(login);

loginFR.leftPart.europeanText = `Qarnot computing est cofinancé par l'Union européenne et la région Île de France par le biais du Fonds européen de développement régional.`;
loginFR.rightPart.title = 'Accédez à vos bâtiments';
loginFR.rightPart.email.label = 'Email';
loginFR.rightPart.email.subLabel = 'Exemple: xyz@domaine.xy';
loginFR.rightPart.password.label = 'Mot de passe';
loginFR.rightPart.password.subLabel = 'il doit contenir au moins 8 caractères';
loginFR.rightPart.buttons.connection = 'connexion';
loginFR.rightPart.formErrors.unknownEmail = 'Email inconnu';
loginFR.rightPart.formErrors.wrongPassword = 'Mauvais mot de passe';
loginFR.rightPart.formErrors.requiredEmail = `L'email est requis`;
loginFR.rightPart.formErrors.requiredPassword = `Le mot de passe est requis`;
loginFR.rightPart.formErrors.invalidEmail = 'Email invalide';
loginFR.rightPart.forgotPassword = 'mot de passe oublié ?';
loginFR.rightPart.error.noFeatures = "Votre compte n'a pas été configuré pour accéder à Oasis";

export default loginFR;
